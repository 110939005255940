
@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
}
:root {
  --main: #1a1a18;
  --yellow: #e3b343;
}
body {
  font-family: "Chakra Petch", sans-serif;
  scroll-behavior: smooth;
}
.dir {
  direction: ltr !important;
}
html {
  font-family: "Chakra Petch", sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #2a2a2a;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
ul {
  padding: 0;
  margin: 0;
}
.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 546px;
  }
}

@media (min-width: 791px) {
  .container {
    max-width: 770px;
  }
}

@media (min-width: 1025px) {
  .container {
    max-width: 990px;
  }
}

@media (min-width: 1230px) {
  .container {
    max-width: 1200px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 791px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1025px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1230px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.input-group-sm > .form-control-plaintext.form-control,
.input-group-sm
  > .input-group-prepend
  > .form-control-plaintext.input-group-text,
.input-group-sm
  > .input-group-append
  > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm > .input-group-append > .form-control-plaintext.btn,
.form-control-plaintext.form-control-lg,
.input-group-lg > .form-control-plaintext.form-control,
.input-group-lg
  > .input-group-prepend
  > .form-control-plaintext.input-group-text,
.input-group-lg
  > .input-group-append
  > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg > .input-group-append > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control-sm:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm
  > .input-group-prepend
  > select.input-group-text:not([size]):not([multiple]),
.input-group-sm
  > .input-group-append
  > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control-lg:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg
  > .input-group-prepend
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg
  > .input-group-append
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before,
.custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: var(--yellow);
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before,
.custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 6px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover,
.btn:focus {
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
  z-index: 3;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 6px;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.modal-open {
  overflow-x: hidden;
  overflow-y: auto;
}

.quick-modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
@keyframes slideIn {
  from {
    top: -100%;
  }
  to {
    top: 0%;
  }
}
.modal-open .quick-modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  top: -100%;
  margin: 10px;
  animation: slideIn 0.7s ease-in-out forwards;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (10px * 2));
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 24;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-header .close {
  padding: 0;
  margin: 0 0 0 auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (30px * 2));
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 1025px) {
  .modal-lg {
    max-width: 800px;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 6px !important;
}

.rounded-top {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.rounded-right {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.rounded-left {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 791px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1025px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1230px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 791px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1025px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1230px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 791px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 1025px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1230px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 23;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 23;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 22;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 791px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1025px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1230px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 791px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 1025px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1230px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #1d2124 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-hide {
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body {
    min-width: 1025px !important;
  }

  .container {
    min-width: 1025px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

.datepicker {
  padding: 0px;
  margin-top: 0px;
  direction: ltr;
}

.datepicker-inline {
  width: 100%;
}

.datepicker.datepicker-rtl {
  direction: rtl;
}

.datepicker.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
}

.datepicker-dropdown:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 6px;
}

.datepicker-dropdown:after {
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  position: absolute;
  top: -6px;
  left: 7px;
}

.datepicker > div {
  display: none;
}

.datepicker.days div.datepicker-days {
  display: block;
}

.datepicker.months div.datepicker-months {
  display: block;
}

.datepicker.years div.datepicker-years {
  display: block;
}

.datepicker table {
  margin: 0;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}

.datepicker table {
  width: 100%;
}

.datepicker table tr td.day:hover {
  color: var(--yellow);
  cursor: pointer;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #777777;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #777777;
  cursor: default;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #fde19a;
  border-color: #fdf59a #fdf59a #fbed50;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  color: var(--yellow);
}

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
  color: var(--yellow) \9;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  color: var(--yellow);
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  color: var(--yellow);
}

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  color: var(--yellow) \9;
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
}

.datepicker table tr td span:hover {
  color: var(--yellow);
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #777777;
  cursor: default;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-color: var(--yellow);
  color: #fff;
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: var(--yellow);
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  background-color: #003399 \9;
}

.datepicker table tr td span.old {
  color: #777777;
}

.datepicker th.switch {
  width: 145px;
}

.datepicker thead tr:first-child th,
.datepicker tfoot tr:first-child th {
  cursor: pointer;
}

.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr:first-child th:hover {
  color: #fff;
  background: var(--yellow);
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  display: block;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.datepicker td,
.datepicker th {
  text-align: center;
  width: 14%;
  height: 20px;
  border: none;
  padding: 4px 3px 2px;
}

.datepicker thead tr:first-child th {
  padding: 9px 3px 7px;
}

.datepicker thead tr:first-child {
  background: #191919;
}

.datepicker thead tr:first-child th {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

.datepicker thead th.dow {
  font-weight: 700;
  color: #191919;
  font-family: "Hind", sans-serif;
  text-transform: uppercase;
}

.datepicker thead tr:last-child th:not(.switch):not(.prev):not(.next) {
  padding-top: 14px;
}

.datepicker thead .icon-arrow-right,
.datepicker thead .icon-arrow-left {
  display: block;

  font-size: 14px;
  line-height: 21px;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.datepicker thead th.next:hover i,
.datepicker thead th.prev:hover i {
  color: #fff;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: -43px;
  top: -44px;
  text-decoration: none;
  text-align: center;
  padding: 0 0 18px 10px;
  color: #ffffff;
  font-size: 0px;
  line-height: 0;

  transition: all 0.3s linear;
}

.mfp-close:before {
  font-size: 20px;
  line-height: 1;
}

.mfp-close:hover {
  color: var(--yellow);
}

.mfp-counter {
  top: 0;
  right: 0;
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  font-weight: lighter;
  white-space: nowrap;
}

button.mfp-arrow {
  position: absolute;
  margin: 0;
  top: 50%;
  margin-top: -25px;
  padding: 0;
  width: 50px;
  height: 50px;
  transition: all 0.3s linear;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
  filter: alpha(opacity=100);
}

.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  border: medium inset transparent;
}

.mfp-arrow {
  display: block;

  font-size: 30px;
  line-height: 45px;
  color: #ffffff;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mfp-arrow:hover,
button.mfp-arrow:hover {
  color: var(--yellow);
}

.mfp-arrow:before {
  display: block;
}

.mfp-arrow-right {
  right: 10px;
}

.mfp-arrow-right:before {
  left: 10px;
}

.mfp-arrow-left:before {
  left: 7px;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 0px 0 40px;
  margin: 0 auto;
}

.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  background: #444;
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -21px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
  text-align: center;
  font-family: "Chakra Petch", sans-serif;
}

.mfp-title {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  word-wrap: break-word;
}

.mfp-image-holder .mfp-content {
  max-width: 72%;
}

.mfp-gallery img.mfp-img {
  max-height: 80vh !important;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
  screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.mfp-ie7 .mfp-img {
  padding: 0;
}

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px;
}

.mfp-ie7 .mfp-container {
  padding: 0;
}

.mfp-ie7 .mfp-content {
  padding-top: 44px;
}

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0;
}

.ps-container {
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

@supports (-ms-overflow-style: none) {
  .ps-container {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps-container {
    overflow: auto !important;
  }
}

.ps-container.ps-active-x > .ps-scrollbar-x-rail,
.ps-container.ps-active-y > .ps-scrollbar-y-rail {
  display: block;
  background-color: transparent;
}

.ps-container.ps-in-scrolling {
  pointer-events: none;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
}

.ps-container > .ps-scrollbar-x-rail {
  display: none;
  position: absolute;

  border-radius: 4px;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  bottom: 3px;

  height: 8px;
}

.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  position: absolute;

  background-color: #aaa;
  border-radius: 4px;
  transition: background-color 0.2s linear;
  bottom: 0;

  height: 8px;
}

.ps-container > .ps-scrollbar-y-rail {
  display: none;
  position: absolute;
  z-index: 21;

  border-radius: 4px;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  right: 3px;

  width: 8px;
}

.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position: absolute;
  /* please don't change 'position' */
  background-color: #aaa;
  border-radius: 4px;
  transition: background-color 0.2s linear;
  right: 0;
  /* there must be 'right' for ps-scrollbar-y */
  width: 5px;
}

.ps-container:hover.ps-in-scrolling {
  pointer-events: none;
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover.ps-in-scrolling.ps-x
  > .ps-scrollbar-x-rail
  > .ps-scrollbar-x {
  background-color: #999;
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover.ps-in-scrolling.ps-y
  > .ps-scrollbar-y-rail
  > .ps-scrollbar-y {
  background-color: #999;
}

.ps-container:hover > .ps-scrollbar-x-rail,
.ps-container:hover > .ps-scrollbar-y-rail {
  opacity: 0.6;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
  background-color: #999;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
  background-color: #999;
}

/*---------------------------------------*/
/*--------     3. Header         --------*/
/*---------------------------------------*/
/*
	*Header have 3 color schemes:
		 tt-color-scheme-01
		 tt-color-scheme-02
		 tt-color-scheme-03
*/
/* tt-top-panel */
.tt-top-panel {
  background: var(--yellow);
}

.tt-top-panel .tt-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  padding: 24px 15px 15px;
  font-size: 13px;
  line-height: 23px;
  color: #ffffff;
  font-family: "Chakra Petch", sans-serif;
  letter-spacing: 0.06em;
  word-spacing: 0.03em;
  position: relative;
}

.tt-top-panel .tt-row a:not([class]) {
  display: inline-block;
  color: #ffffff;
  position: relative;
}

.tt-top-panel .tt-row a:not([class]):before {
  content: "";
  display: block;
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  transition: background-color 0.2s linear;
}

.tt-top-panel .tt-row a:not([class]):hover:before {
  background-color: transparent;
}

.tt-top-panel .tt-row .tt-description {
  text-align: center;
}

.tt-top-panel .tt-row .tt-btn-close {
  text-decoration: none;
  margin-left: 27px;
  padding: 2px;
  font-weight: normal;
  cursor: pointer;
  background: none;
  border: none;
  font-weight: 500;
  position: relative;
  top: -1px;
}

.tt-top-panel .tt-row .tt-btn-close:before {
  color: #ffffff;
  font-size: 20px;
  line-height: 1;
  transition: color 0.2s linear;
}

.tt-top-panel .tt-row .tt-btn-close:hover:before {
  color: #ffffff;
}

.tt-top-panel.tt-color-dark {
  background: #191919;
}

.tt-top-panel.tt-color-dark a:not([class]) {
  color: var(--yellow);
}

.tt-top-panel.tt-color-dark a:not([class]):before {
  background-color: var(--yellow);
}

.tt-top-panel.tt-color-dark .tt-btn-close:before {
  color: #777777;
}

.tt-top-panel.tt-color-dark .tt-btn-close:hover:before {
  color: #ffffff;
}

.tt-top-panel.tt-color-02 {
  background-color: #373e48;
  color: #ffffff;
}

.tt-top-panel.tt-top-panel-large .tt-row {
  font-size: 15px;
  font-weight: 500;
}

.tt-top-panel.tt-top-panel-largexl .tt-text-large {
  font-size: 28px;
}

.tt-top-panel.tt-top-panel-largexl .tt-row {
  padding: 26px 15px 18px;
  font-size: 18px;
  font-weight: 500;
}

.header-popup-bg {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 21;
  display: none;
}

@media (max-width: 1024px) {
  body.tt-popup-dropdown .header-popup-bg {
    display: block;
  }

  body.tt-popup-dropdown .tt-stuck-nav.stuck {
    z-index: 22;
  }

  body.tt-popup-dropdown .tt-stuck-nav.stuck:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
}

header .tt-logo {
  position: relative;
  display: inline-flex;
}

header .tt-logo .tt-title {
  padding: 0;
  display: inline-block;
  font-size: 19px;
  line-height: 1.1;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  color: var(--yellow);
  text-transform: uppercase;
}

header .tt-logo:hover .tt-title {
  color: #191919;
}

header .tt-logo-alignment {
  position: relative;
}

header .tt-desktop-header .tt-logo {
  margin-top: 13px;
  margin-bottom: 17px;
}

header .tt-desktop-header .tt-logo .tt-title {
  margin-top: 6px;
  transition: color 0.2s linear;
}

@media (min-width: 1230px) {
  header .tt-desktop-header .dropdown-menu {
    max-width: 1200px;
  }
}

header .tt-dropdown-obj {
  display: inline-flex;
}

header .tt-dropdown-obj .tt-dropdown-toggle {
  position: relative;
  z-index: 1;
  cursor: pointer;
  padding: 5px 8px;
  font-size: 20px;
  line-height: 1;
  color: #191919;
  background: none;
  border: none;
  display: inline-block;
  transition: color 0.2s linear;
}

header .tt-dropdown-obj .tt-dropdown-toggle i {
  pointer-events: none;
}

header .tt-dropdown-obj .tt-dropdown-toggle:hover {
  color: var(--yellow);
}

header .tt-dropdown-obj .tt-dropdown-menu {
  display: none;
  background: #ffffff;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 22;
}

@media (max-width: 1024px) {
  header .tt-dropdown-obj:not(.tt-search) .tt-dropdown-menu {
    display: block;
    right: auto;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    position: fixed;
  }

  header .container {
    max-width: inherit;
  }
}

header .tt-dropdown-obj.active .tt-dropdown-toggle {
  z-index: 2;
  color: var(--yellow);
}

header .tt-cart .tt-dropdown-menu,
header .tt-account .tt-dropdown-menu,
header .tt-multi-obj .tt-dropdown-menu {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
}

@media (min-width: 1025px) {
  header .tt-cart .tt-dropdown-menu,
  header .tt-account .tt-dropdown-menu,
  header .tt-multi-obj .tt-dropdown-menu {
    width: 280px;
  }
}

@media (max-width: 1024px) {
  header .tt-cart .tt-dropdown-menu,
  header .tt-account .tt-dropdown-menu,
  header .tt-multi-obj .tt-dropdown-menu {
    width: 270px;
  }
}

@media (max-width: 1024px) {
  header .tt-cart .tt-dropdown-menu {
    width: 320px;
  }

  header .tt-cart .tt-dropdown-menu .tt-cart-layout .tt-cart-content {
    padding: 26px 30px 23px;
  }

  header
    .tt-cart
    .tt-dropdown-menu
    .tt-cart-layout
    .tt-cart-content
    .tt-cart-total-row {
    margin-top: 30px;
    padding-top: 24px;
  }

  header
    .tt-cart
    .tt-dropdown-menu
    .tt-cart-layout
    .tt-cart-content
    .tt-cart-btn {
    margin-top: 4px;
  }

  header
    .tt-cart
    .tt-dropdown-menu
    .tt-cart-layout
    .tt-cart-content
    .tt-cart-btn
    .tt-item {
    margin-top: 20px;
  }

  header
    .tt-cart
    .tt-dropdown-menu
    .tt-cart-layout
    .tt-cart-content
    .tt-cart-btn
    .btn {
    font-weight: 500;
  }

  header
    .tt-cart
    .tt-dropdown-menu
    .tt-cart-layout
    .tt-cart-content
    .tt-cart-btn
    .btn:not(.btn-border) {
    font-size: 12px;
  }

  header
    .tt-cart
    .tt-dropdown-menu
    .tt-cart-layout
    .tt-cart-content
    .tt-cart-total-title {
    letter-spacing: 0.02em;
  }
}

@media (min-width: 1025px) {
  header .tt-cart.active .tt-dropdown-menu,
  header .tt-account.active .tt-dropdown-menu,
  header .tt-multi-obj.active .tt-dropdown-menu {
    display: none;
  }
}

@media (max-width: 1024px) {
  header .tt-cart.active .tt-dropdown-menu,
  header .tt-account.active .tt-dropdown-menu,
  header .tt-multi-obj.active .tt-dropdown-menu {
    display: block;
    right: auto;
    top: 0;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

header .tt-parent-box:first-child .tt-dropdown-toggle {
  padding-left: 0;
}

header .tt-parent-box:last-child .tt-dropdown-toggle {
  padding-right: 0;
}

header .tt-parent-box {
  display: inline-flex;
  vertical-align: top;
}

header .tt-box-info ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

header .tt-box-info ul li {
  padding-top: 8px;
  padding-bottom: 8px;
  letter-spacing: 0.01em;
  font-size: 14px;
}

header .tt-box-info ul li a {
  transition: color 0.2s linear;
}

header .tt-box-info ul li a.tt-link-underline {
  display: inline-block;
  position: relative;
}

header .tt-box-info ul li a.tt-link-underline:before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 4px;
  width: 100%;
  height: 1px;
  transition: background-color 0.1s linear;
}

header .tt-box-info ul li a.tt-link-underline:hover:before {
  background-color: transparent;
}

header .tt-box-info ul li [class^="icon-"] {
  margin-right: 4px;
  font-size: 18px;
  position: relative;
  top: 2px;
}

header .tt-box-info ul li:not(:first-child) {
  margin-left: 15px;
}

@media (min-width: 1025px) {
  header .tt-account .tt-dropdown-menu .tt-dropdown-inner {
    padding: 33px 20px 37px 39px;
  }
}

@media (max-width: 1024px) {
  header .tt-account .tt-dropdown-menu .tt-dropdown-inner {
    padding: 15px 20px 37px 29px;
  }

  header .tt-account .tt-dropdown-menu .tt-dropdown-inner ul li {
    letter-spacing: 0.01em;
  }

  header .tt-account .tt-dropdown-menu .tt-dropdown-inner ul li a {
    display: inline-block;
    padding: 7px 0;
    color: #191919;
  }

  header
    .tt-account
    .tt-dropdown-menu
    .tt-dropdown-inner
    ul
    li
    a
    [class^="icon-"] {
    top: 3px;
    margin-right: 7px;
    color: #191919;
  }

  header .tt-account .tt-dropdown-menu .tt-dropdown-inner ul li a .icon-n-072,
  header .tt-account .tt-dropdown-menu .tt-dropdown-inner ul li a .icon-n-08 {
    margin-right: 10px;
  }

  header .tt-account .tt-dropdown-menu .tt-dropdown-inner ul li a:hover {
    color: var(--yellow);
  }

  header
    .tt-account
    .tt-dropdown-menu
    .tt-dropdown-inner
    ul
    li
    a:hover
    [class^="icon-"] {
    color: var(--yellow);
  }
}

header .tt-account ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-top: -1px;
}

header .tt-account ul li {
  font-size: 14px;
}

header .tt-account ul li a {
  color: #191919;
  padding: 1px 0;
  display: block;
  transition: color 0.2s linear;
}

header .tt-account ul li a [class^="icon-"] {
  font-size: 20px;
  line-height: 1;
  margin-right: 8px;
  position: relative;
  top: 4px;
  color: #191919;
  transition: color 0.2s linear;
}

header .tt-account ul li a .icon-n-072,
header .tt-account ul li a .icon-n-08 {
  font-size: 16px;
  margin-right: 11px;
}

header .tt-account ul li a:hover {
  color: var(--yellow);
}

header .tt-account ul li a:hover [class^="icon-"] {
  color: var(--yellow);
}

@media (min-width: 1025px) {
  header .tt-multi-obj .tt-dropdown-menu .tt-dropdown-inner {
    padding: 35px 20px 30px 41px;
  }
}

@media (max-width: 1024px) {
  header .tt-multi-obj .tt-dropdown-menu .tt-dropdown-inner ul {
    padding: 16px 30px 16px 31px;
    position: relative;
    margin-top: 0;
  }

  header .tt-multi-obj .tt-dropdown-menu .tt-dropdown-inner ul li {
    letter-spacing: 0.02em;
  }

  header .tt-multi-obj .tt-dropdown-menu .tt-dropdown-inner ul li a {
    padding: 9px 0;
    color: #191919;
  }

  header
    .tt-multi-obj
    .tt-dropdown-menu
    .tt-dropdown-inner
    ul
    li
    a
    [class^="icon-"] {
    font-size: 20px;
    color: #191919;
  }

  header .tt-multi-obj .tt-dropdown-menu .tt-dropdown-inner ul li a:hover {
    color: var(--yellow);
  }

  header
    .tt-multi-obj
    .tt-dropdown-menu
    .tt-dropdown-inner
    ul
    li
    a:hover
    [class^="icon-"] {
    color: var(--yellow);
  }

  header .tt-multi-obj .tt-dropdown-menu .tt-dropdown-inner ul li.active a {
    color: var(--yellow);
  }

  header
    .tt-multi-obj
    .tt-dropdown-menu
    .tt-dropdown-inner
    ul
    li.active
    a
    [class^="icon-"] {
    color: var(--yellow);
  }

  header
    .tt-multi-obj
    .tt-dropdown-menu
    .tt-dropdown-inner
    ul:not(:first-child) {
    border-top: 1px solid #e9e7e7;
  }
}

header .tt-multi-obj ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-top: -4px;
}

header .tt-multi-obj ul li {
  font-size: 14px;
}

header .tt-multi-obj ul li a {
  color: #191919;
  padding: 3px 0;
  display: block;
  transition: color 0.2s linear;
}

header .tt-multi-obj ul li a [class^="icon-"] {
  font-size: 15px;
  line-height: 1;
  margin-right: 8px;
  margin-left: -3px;
  position: relative;
  top: 1px;
  color: #191919;
  transition: color 0.2s linear;
}

header .tt-multi-obj ul li a:hover {
  color: var(--yellow);
}

header .tt-multi-obj ul li a:hover [class^="icon-"] {
  color: var(--yellow);
}

header .tt-multi-obj ul li.active a {
  color: var(--yellow);
}

header .tt-multi-obj ul li.active a [class^="icon-"] {
  color: var(--yellow);
}

header .tt-multi-obj ul:not(:first-child) {
  margin-top: 22px;
}

header .tt-multi-obj .tt-mobile-add + ul {
  margin-top: 0;
}

header .tt-dropdown-obj:not(.tt-search) .tt-mobile-add {
  border-bottom: 1px solid #e9e7e7;
}

@media (min-width: 1025px) {
  header .tt-dropdown-obj:not(.tt-search) .tt-mobile-add {
    display: none;
  }
}

header .tt-dropdown-obj:not(.tt-search) .tt-mobile-add .tt-title {
  color: #191919;
  font-size: 14px;
  line-height: 1.1;
  pointer-events: none;
}

header .tt-dropdown-obj:not(.tt-search) .tt-mobile-add .tt-close {
  font-size: 14px;
  padding: 13px 12px 13px 29px;
  display: block;
  color: #777777;
  transition: color 0.2s linear;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

header .tt-dropdown-obj:not(.tt-search) .tt-mobile-add .tt-close:before {
  padding-right: 6px;
  font-size: 14px;
  position: relative;
  top: 1px;
  color: #777777;
  transition: color 0.2s linear;
}

header .tt-dropdown-obj:not(.tt-search) .tt-mobile-add .tt-close:hover {
  color: var(--yellow);
}

header .tt-dropdown-obj:not(.tt-search) .tt-mobile-add .tt-close:hover:before {
  color: var(--yellow);
}

header .tt-dropdown-obj:not(.tt-search) .tt-mobile-add .tt-title {
  padding: 18px 0 17px 30px;
}

header .tt-dropdown-obj:not(.tt-search) .tt-mobile-add .tt-title + .tt-close {
  position: absolute;
  right: 9px;
  top: 0;
  font-size: 0;
  text-align: right;
}

header .tt-cart .tt-dropdown-toggle .tt-badge-cart {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background: var(--yellow);
  color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -5px;
  font-size: 12px;
  line-height: 1.7;
  pointer-events: none;
}

header .tt-cart .tt-cart-layout .tt-cart-empty {
  padding: 21px 20px 19px;
  text-align: center;
  display: block;
}

header .tt-cart .tt-cart-layout .tt-cart-empty [class^="icon-"] {
  font-size: 40px;
  line-height: 1;
  color: #cdcdcd;
}

header .tt-cart .tt-cart-layout .tt-cart-empty p {
  margin-top: 7px;
  letter-spacing: 0.01em;
  color: #777777;
}

header .tt-cart .tt-cart-layout .tt-cart-content {
  padding: 26px 20px 23px;
}

header .tt-cart .tt-cart-layout .tt-cart-content .tt-cart-list .tt-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item
  .tt-item-img {
  margin-top: 4px;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item
  .tt-item-img
  img {
  width: 67px;
  height: auto;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item
  a:not([class]) {
  display: flex;
  width: 100%;
  transition: color 0.2s linear;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item
  a:not([class])
  .tt-title {
  color: #191919;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item
  a:not([class]):hover
  .tt-title {
  color: var(--yellow);
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item
  .tt-item-img
  + .tt-item-descriptions {
  margin-left: 20px;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item
  .tt-item-descriptions {
  flex: 2 1 auto;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item
  .tt-item-descriptions
  .tt-title {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  transition: color 0.2s linear;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item
  .tt-item-descriptions
  .tt-add-info {
  list-style: none;
  margin: 4px 0 0 0;
  padding: 0 0 5px 0;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item
  .tt-item-descriptions
  .tt-add-info
  li {
  color: #999999;
  font-size: 11px;
  line-height: 16px;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item
  .tt-item-descriptions
  .tt-quantity {
  display: inline-flex;
  font-size: 14px;
  color: #777777;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item
  .tt-item-descriptions
  .tt-price {
  display: inline-flex;
  font-size: 16px;
  color: #191919;
  font-weight: 500;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item
  .tt-item-descriptions
  > *:nth-child(1) {
  margin-top: 0;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item:not(:first-child) {
  margin-top: 16px;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item-close
  .tt-btn-close {
  padding: 4px;
  margin-right: -6px;
  position: relative;
  top: -1px;
  background: none;
  border: none;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item-close
  .tt-btn-close:before {
  color: #191919;
  font-size: 15px;
  line-height: 22px;

  transition: color 0.2s linear;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-list
  .tt-item-close
  .tt-btn-close:hover:before {
  color: var(--yellow);
}

header .tt-cart .tt-cart-layout .tt-cart-content .tt-cart-total-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;
  border-top: 1px solid #e9e7e7;
  margin-top: 20px;
  padding-top: 14px;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-total-row
  .tt-cart-total-title {
  font-weight: 500;
  color: #191919;
  font-size: 14px;
  font-family: "Chakra Petch", sans-serif;
}

header
  .tt-cart
  .tt-cart-layout
  .tt-cart-content
  .tt-cart-total-row
  .tt-cart-total-price {
  flex: 2 1 auto;
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  color: #191919;
}

header .tt-cart .tt-cart-layout .tt-cart-content .tt-cart-btn {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: center;
}

header .tt-cart .tt-cart-layout .tt-cart-content .tt-cart-btn .tt-item {
  margin-top: 11px;
  width: 100%;
}

header .tt-cart .tt-cart-layout .tt-cart-content .tt-cart-btn .btn {
  padding-left: 13px;
  padding-right: 13px;
  width: 100%;
}

header .tt-dropdown-obj .tt-dropdown-toggle:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

@media (min-width: 1025px) {
  header .tt-search .tt-dropdown-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    display: block;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    -webkit-transform: translate(0, -100%);
    transform: translate(0, -100%);
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: hidden;
    transition: all 0.4s linear;
  }

  header .tt-search .tt-dropdown-menu .container {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  header .tt-search .tt-dropdown-menu form {
    position: relative;
    display: block;
  }

  header .tt-search .tt-dropdown-menu .tt-search-input {
    background: transparent;
    font-size: 24px;
    font-weight: 500;
    border: none;
    border-bottom: 1px solid #eceaea;
    padding: 11px 12px 10px 0px;
    font-family: "Chakra Petch", sans-serif;
    box-shadow: none;
    outline: none;
    width: 100%;
    text-transform: uppercase;
    color: #191919;
    margin-top: 28px;
    border-right: 0;
    transition: all 0.2s linear;
  }

  header
    .tt-search
    .tt-dropdown-menu
    .tt-search-input:not(focus)::-webkit-input-placeholder {
    color: #191919;
    text-transform: uppercase;
  }

  header
    .tt-search
    .tt-dropdown-menu
    .tt-search-input:not(focus)::-moz-placeholder {
    color: #191919;
    text-transform: uppercase;
  }

  header
    .tt-search
    .tt-dropdown-menu
    .tt-search-input:not(focus):-moz-placeholder {
    color: #191919;
    text-transform: uppercase;
  }

  header
    .tt-search
    .tt-dropdown-menu
    .tt-search-input:not(focus):-ms-input-placeholder {
    color: #191919;
    text-transform: uppercase;
  }

  header .tt-search .tt-dropdown-menu .tt-btn-search {
    color: #333333;
    font-size: 30px;
    line-height: 1;

    position: absolute;
    cursor: pointer;
    top: 41px;
    padding: 4px 0 4px 4px;
    margin-top: -4px;
    right: 0px;
    z-index: 3;
    display: inline-block;
    background: none;
    border: none;
    transition: color 0.2s linear;
  }

  header .tt-search .tt-dropdown-menu .tt-btn-search:hover {
    color: var(--yellow);
  }

  header .tt-search .tt-dropdown-menu .tt-info-text {
    position: absolute;
    top: 0;
    left: 0;
  }

  header .tt-search .tt-dropdown-menu .tt-btn-close {
    display: inline-flex;
    position: absolute;
    top: -10px;
    right: 0;
    margin-top: -1px;
    color: #000;
    font-size: 20px;
    line-height: 1;

    padding: 5px 0px 5px 5px;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.2s linear;
  }

  header .tt-search .tt-dropdown-menu .tt-btn-close:before {
    line-height: 1;
  }

  header .tt-search .tt-dropdown-menu .tt-btn-close:hover {
    color: var(--yellow);
  }

  header .tt-search .tt-dropdown-menu .tt-description {
    text-align: center;
  }

  header .tt-search .tt-dropdown-menu .tt-price {
    justify-content: center;
  }

  header .tt-search .tt-dropdown-menu > .container {
    display: none;
  }
}

@media (max-width: 1024px) {
  header .tt-search .tt-dropdown-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 70px; */
    padding: 2rem;
    background: #ffffff;
    display: block;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    -webkit-transform: translate(0, -100%);
    transform: translate(0, -100%);
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: hidden;
    transition: all 0.4s linear;
  }

  header .tt-search .tt-dropdown-menu:before {
    content: "";
    display: block;
    width: calc(100% + 40px);
    z-index: 0;
    position: absolute;
    top: -60px;
    left: 0;
    height: 100%;
    background: #ffffff;
    margin-left: -20px;
    margin-right: -20px;
  }

  header .tt-search .tt-dropdown-menu .container {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
  }

  header
    .tt-search
    .tt-dropdown-menu
    .container
    [class^="tt-col"]:first-child:first-child {
    padding-left: 0;
  }

  header .tt-search .tt-dropdown-menu .tt-info-text {
    display: none;
  }

  header .tt-search .tt-dropdown-menu form {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-end;
    align-items: center;
  }

  header .tt-search .tt-dropdown-menu form .tt-col:first-child {
    flex: 2 1 auto;
  }

  header .tt-search .tt-dropdown-menu form .tt-col:last-child {
    margin-left: 20px;
  }

  header .tt-search .tt-dropdown-menu form .tt-search-input {
    background: transparent;
    font-size: 16px;
    border: 1px solid transparent;
    padding: 14px 12px 8px 0px;
    font-family: "Chakra Petch", sans-serif;
    box-shadow: none;
    outline: none;
    width: calc(100% - 40px);
    color: #777777;
    border-right: 0;
    transition: all 0.2s linear;
  }

  header
    .tt-search
    .tt-dropdown-menu
    form
    .tt-search-input:not(focus)::-webkit-input-placeholder {
    color: #191919;
    text-transform: uppercase;
  }

  header
    .tt-search
    .tt-dropdown-menu
    form
    .tt-search-input:not(focus)::-moz-placeholder {
    color: #191919;
    text-transform: uppercase;
  }

  header
    .tt-search
    .tt-dropdown-menu
    form
    .tt-search-input:not(focus):-moz-placeholder {
    color: #191919;
    text-transform: uppercase;
  }

  header
    .tt-search
    .tt-dropdown-menu
    form
    .tt-search-input:not(focus):-ms-input-placeholder {
    color: #191919;
    text-transform: uppercase;
  }

  header .tt-search .tt-dropdown-menu .tt-btn-close {
    display: inline-flex;
    color: #777777;
    font-size: 20px;
    line-height: 1;

    padding: 3px 0px 5px 5px;
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
    top: 3px;
    transition: color 0.2s linear;
  }

  header .tt-search .tt-dropdown-menu .tt-btn-close:before {
    font-size: 20px;
    line-height: 1;
  }

  header .tt-search .tt-dropdown-menu .tt-btn-close:hover {
    color: var(--yellow);
  }

  header .tt-search .tt-dropdown-menu .tt-btn-search {
    color: var(--yellow);
    font-size: 20px;
    line-height: 1;

    position: absolute;
    cursor: pointer;
    top: 50%;
    margin-top: -10px;
    right: 40px;
    z-index: 3;
    display: inline-block;
    background: none;
    border: none;
    transition: color 0.2s linear;
  }

  header .tt-search .tt-dropdown-menu .search-results {
    position: absolute;
    top: 38px;
    left: 0;
    padding: 11px 11px 19px;
  }
}

@media (max-width: 1024px) and (min-width: 481px) {
  header .tt-search .tt-dropdown-menu .search-results {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 1024px) and (max-width: 481px) {
  header .tt-search .tt-dropdown-menu .search-results {
    width: calc(100% + 20px);
    /* margin-left: -10px;
    margin-right: -10px; */
  }
}

header .tt-search .search-results {
  position: relative;
  padding: 0;
  width: 100%;
  display: none;
}

@media (max-width: 1024px) {
  header .tt-search .search-results {
    background: #ffffff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.04);
    margin-top: 22px;
  }
}

@media (max-width: 1024px) and (max-width: 1024px) and (min-width: 481px) {
  header .tt-search .search-results {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 1024px) and (max-width: 481px) {
  header .tt-search .search-results {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (max-width: 1024px) {
  header .tt-search .search-results ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  header .tt-search .search-results ul > li {
    display: none;
    width: 100%;
    margin: 0;
    overflow: hidden;
  }

  header .tt-search .search-results ul > li a {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    color: #777777;
    padding: 0;
    position: relative;
    background-color: transparent;
    transition: background-color 0.2s linear;
  }

  header .tt-search .search-results ul > li a .thumbnail {
    width: 50px;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    border-right: 2px solid #ffffff;
  }

  header .tt-search .search-results ul > li a .thumbnail img {
    width: 100%;
    height: auto;
  }

  header .tt-search .search-results ul > li a .tt-description {
    padding: 5px 15px 5px 16px;
    white-space: normal;
    font-size: 14px;
    line-height: 24px;
  }

  header .tt-search .search-results ul > li a .tt-description .tt-title {
    font-size: 14px;
    line-height: 24px;
    color: #333333;
  }

  header .tt-search .search-results ul > li a .tt-description .tt-price {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #191919;
  }

  header
    .tt-search
    .search-results
    ul
    > li
    a
    .tt-description
    .tt-price
    .new-price {
    color: #f8353e;
    margin-right: 7px;
  }

  header
    .tt-search
    .search-results
    ul
    > li
    a
    .tt-description
    .tt-price
    .old-price {
    color: #191919;
    text-decoration: line-through;
  }

  header
    .tt-search
    .search-results
    ul
    > li
    a
    .tt-description
    .tt-price
    .old-price
    .money {
    text-decoration: line-through;
  }

  header .tt-search .search-results ul > li a .tt-description .tt-price span {
    display: inline-block;
  }

  header .tt-search .search-results ul > li a:hover {
    background-color: #f7f8fa;
  }

  header .tt-search .search-results ul > li:not(:first-child) {
    margin-top: 10px;
  }

  header .tt-search .search-results ul li:nth-child(-n + 3) {
    display: block;
  }
}

@media (min-width: 1025px) {
  header .tt-search .search-results {
    margin-top: 27px;
    background: #ffffff;
  }

  header .tt-search .search-results ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    list-style: none;
    padding: 0;
    overflow: hidden;
    margin-right: -10px;
    margin-left: -10px;
  }

  header .tt-search .search-results ul > li {
    display: block;
    width: 16.666%;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
  }

  header .tt-search .search-results ul > li a {
    display: block;
    color: #777777;
  }

  header .tt-search .search-results ul > li a .thumbnail img {
    width: 100%;
    height: auto;
  }

  header .tt-search .search-results ul > li a .tt-description {
    padding: 14px 0px 5px 0px;
    white-space: normal;
    font-size: 14px;
    line-height: 24px;
  }

  header .tt-search .search-results ul > li a .tt-description .tt-title {
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    transition: color 0.2s linear;
  }

  header .tt-search .search-results ul > li a .tt-description .tt-price {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #191919;
  }

  header
    .tt-search
    .search-results
    ul
    > li
    a
    .tt-description
    .tt-price
    .new-price {
    color: #f8353e;
    margin-right: 7px;
  }

  header
    .tt-search
    .search-results
    ul
    > li
    a
    .tt-description
    .tt-price
    .old-price {
    color: #191919;
    text-decoration: line-through;
  }

  header
    .tt-search
    .search-results
    ul
    > li
    a
    .tt-description
    .tt-price
    .old-price
    .money {
    text-decoration: line-through;
  }

  header .tt-search .search-results ul > li a .tt-description .tt-price span {
    display: inline-block;
  }

  header .tt-search .search-results ul > li a:hover .tt-title {
    color: var(--yellow);
  }
}

header .tt-search .tt-view-all {
  background: none;
  border: none;
  padding: 0;
  margin-top: 15px;
  color: var(--yellow);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s linear;
}

header .tt-search .tt-view-all:hover {
  text-decoration: none;
  color: #191919;
}

@media (min-width: 1025px) {
  header .tt-search.active .tt-dropdown-menu {
    visibility: visible;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  header .tt-search.active .tt-dropdown-menu > .container {
    display: block;
  }
}

@media (max-width: 1024px) {
  header .tt-search.active .tt-dropdown-menu {
    z-index: 3;
    opacity: 1;
  }
}

header .tt-social-icon {
  margin-top: 7px;
  padding-bottom: 5px;
}

header .tt-social-icon li:first-child a {
  padding-left: 0;
}

header .tt-social-icon li:last-child a {
  padding-right: 0;
}

header ul.tt-listing-01 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-decoration: none;
  position: relative;
  text-align: center;
  transition: border 0.2s linear;
  margin-top: -4px;
  margin-left: -8px;
}

header ul.tt-listing-01 li {
  margin-top: 8px;
  margin-left: 8px;
  width: calc(50% - 8px);
}

header ul.tt-listing-01 li a {
  display: block;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

header ul.tt-listing-01 li a img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

header ul.tt-listing-01 li a:before {
  content: "";
  width: 100%;
  height: 100%;
  outline: 1px solid #e9e7e7;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: outline-color 0.2s linear;
}

@media (min-width: 1230px) {
  header ul.tt-listing-01 li a {
    height: 78px;
    padding: 9px;
  }
}

@media (max-width: 1229px) {
  header ul.tt-listing-01 li a {
    height: 65px;
    padding: 5px;
  }
}

header ul.tt-listing-01 li a:hover:before {
  outline-color: #191919;
}

@media (max-width: 480px) {
  header .tt-mobile-header .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

header .tt-color-scheme-01 {
  background: #303030;
  color: #888888;
}

header .tt-color-scheme-01 .tt-box-info ul li a {
  color: #888888;
}

header .tt-color-scheme-01 .tt-box-info ul li a:hover {
  color: #888888;
}

header .tt-color-scheme-01 .tt-box-info ul li a.tt-link-underline {
  color: var(--yellow);
}

header .tt-color-scheme-01 .tt-box-info ul li a.tt-link-underline:before {
  background-color: var(--yellow);
}

header .tt-color-scheme-01 .tt-box-info ul li a.tt-link-underline:hover:before {
  background-color: transparent;
}

header .tt-color-scheme-01 .tt-box-info ul li [class^="icon-"] {
  color: #ffffff;
}

header .tt-color-scheme-01 .tt-social-icon li a {
  color: #777777;
}

header .tt-color-scheme-01 .tt-social-icon li a:hover {
  color: #ffffff;
}

header
  .tt-color-scheme-01
  .tt-desctop-menu.tt-hover-02
  nav
  > ul
  li.dropdown
  > a {
  color: #ffffff;
  background: transparent;
}

header
  .tt-color-scheme-01
  .tt-desctop-menu.tt-hover-02
  nav
  > ul
  li.dropdown
  > a:hover {
  color: #ffffff;
  background: var(--yellow);
}

header
  .tt-color-scheme-01
  .tt-desctop-menu.tt-hover-02
  nav
  > ul
  li.dropdown.active
  > a {
  color: #ffffff;
  background: var(--yellow);
}

header
  .tt-color-scheme-01
  .tt-desctop-menu.tt-hover-02
  nav
  > ul
  li.dropdown.selected
  > a {
  color: #ffffff;
  background: var(--yellow);
}

header
  .tt-color-scheme-01
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown
  > a {
  color: #ffffff;
  background: transparent;
}

header
  .tt-color-scheme-01
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown
  > a:hover {
  color: var(--yellow);
  background: transparent;
}

header
  .tt-color-scheme-01
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown.active
  > a {
  color: var(--yellow);
  background: transparent;
}

header
  .tt-color-scheme-01
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown.selected
  > a {
  color: var(--yellow);
  background: transparent;
}

header .tt-color-scheme-01 .tt-dropdown-obj .tt-dropdown-toggle {
  color: #ffffff;
}

header .tt-color-scheme-01 .tt-dropdown-obj .tt-dropdown-toggle:hover {
  color: var(--yellow);
}

header .tt-color-scheme-01 .tt-menu-toggle:not(:hover) i {
  color: #ffffff;
}

header .tt-color-scheme-02 {
  background: #f7f8fa;
  color: #888888;
}

header .tt-color-scheme-02 .tt-box-info ul li a {
  color: #888888;
}

header .tt-color-scheme-02 .tt-box-info ul li a:hover {
  color: #888888;
}

header .tt-color-scheme-02 .tt-box-info ul li [class^="icon-"] {
  color: var(--yellow);
}

header .tt-color-scheme-02 .tt-social-icon li a {
  color: #cdcdcd;
}

header .tt-color-scheme-02 .tt-social-icon li a:hover {
  color: #191919;
}

header
  .tt-color-scheme-02
  .tt-desctop-menu.tt-hover-02
  nav
  > ul
  li.dropdown
  > a {
  color: #191919;
  background: transparent;
}

header
  .tt-color-scheme-02
  .tt-desctop-menu.tt-hover-02
  nav
  > ul
  li.dropdown
  > a:hover {
  color: #ffffff;
  background: var(--yellow);
}

header
  .tt-color-scheme-02
  .tt-desctop-menu.tt-hover-02
  nav
  > ul
  li.dropdown.active
  > a {
  color: #ffffff;
  background: var(--yellow);
}

header
  .tt-color-scheme-02
  .tt-desctop-menu.tt-hover-02
  nav
  > ul
  li.dropdown.selected
  > a {
  color: #ffffff;
  background: var(--yellow);
}

header
  .tt-color-scheme-02
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown
  > a {
  color: #191919;
  background: transparent;
}

header
  .tt-color-scheme-02
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown
  > a:hover {
  color: var(--yellow);
  background: transparent;
}

header
  .tt-color-scheme-02
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown.active
  > a {
  color: var(--yellow);
  background: transparent;
}

header
  .tt-color-scheme-02
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown.selected
  > a {
  color: var(--yellow);
  background: transparent;
}

header .tt-color-scheme-02 .tt-dropdown-obj .tt-dropdown-toggle {
  color: #191919;
}

header .tt-color-scheme-02 .tt-dropdown-obj .tt-dropdown-toggle:hover {
  color: var(--yellow);
}

header .tt-color-scheme-03 {
  background: transparent;
  color: #888888;
}

header
  .tt-color-scheme-03
  .tt-desctop-menu.tt-hover-02
  nav
  > ul
  li.dropdown
  > a {
  color: #ffffff;
  background: transparent;
}

header
  .tt-color-scheme-03
  .tt-desctop-menu.tt-hover-02
  nav
  > ul
  li.dropdown
  > a:hover {
  color: #ffffff;
  background: var(--yellow);
}

header
  .tt-color-scheme-03
  .tt-desctop-menu.tt-hover-02
  nav
  > ul
  li.dropdown.active
  > a {
  color: #ffffff;
  background: var(--yellow);
}

header
  .tt-color-scheme-03
  .tt-desctop-menu.tt-hover-02
  nav
  > ul
  li.dropdown.selected
  > a {
  color: #ffffff;
  background: var(--yellow);
}

header
  .tt-color-scheme-03
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown
  > a {
  color: #ffffff;
  background: transparent;
}

header
  .tt-color-scheme-03
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown
  > a:hover {
  color: var(--yellow);
  background: transparent;
}

header
  .tt-color-scheme-03
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown.active
  > a {
  color: var(--yellow);
  background: transparent;
}

header
  .tt-color-scheme-03
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown.selected
  > a {
  color: var(--yellow);
  background: transparent;
}

header .tt-color-scheme-03 .tt-dropdown-obj .tt-dropdown-toggle {
  color: #ffffff;
}

header .tt-color-scheme-03 .tt-dropdown-obj .tt-dropdown-toggle:hover {
  color: var(--yellow);
}

header [class^="tt-color-scheme-"].tt-search-container {
  background: #ffffff;
}

html.ie .tt-desctop-parent-menu {
  line-height: 0;
}

html.ie .tt-desctop-parent-menu .dropdown-menu {
  line-height: 22px;
}

/*
	desktop header
*/
.tt-desktop-header {
  position: relative;
  z-index: 8;
}

@media (max-width: 1024px) {
  .tt-desktop-header {
    display: none;
  }
}

.tt-desktop-header .tt-dropdown-obj {
  margin-top: 9px;
  padding-bottom: 7px;
}

@media (min-width: 1025px) {
  .tt-obj-desktop-hidden {
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
  }
}

.tt-desktop-header .tt-obj-desktop-hidden02 {
  display: none;
}

.tt-header-static {
  position: absolute;
  z-index: 9;
  width: 100%;
  background: transparent;
}

header .container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}

.tt-desktop-header .container-fluid .container {
  position: relative;
}

.headerunderline {
  border-bottom: 1px solid #e9e7e7;
  box-shadow: 0 1px 3px #0000001a;
}

.tt-col-obj__large {
  flex: 2 1 auto;
}
/*
	header layout
*/
.tt-header-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: center;
  position: relative;
  min-height: 40px;
}

.tt-header-row .tt-col-right {
  vertical-align: top;
  align-items: flex-end;
}

.tt-header-row .tt-col-right div.tt-desctop-parent-menu:not(:last-child) {
  margin-right: 15px;
}

.tt-header-row .tt-row-left {
  vertical-align: top;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.tt-header-row .tt-row-left .tt-logo:not(:last-child) {
  margin-bottom: 0;
}

.tt-header-row
  .tt-row-left
  .tt-logo
  + .tt-desctop-parent-menu
  .tt-desctop-menu
  nav
  > ul
  li.dropdown
  > a {
  padding-top: 13px;
  padding-bottom: 22px;
}

.tt-header-row
  .tt-row-left
  .tt-logo
  + .tt-desctop-parent-menu
  .tt-desctop-menu
  nav
  > ul
  li.dropdown
  > a
  .tt-badge {
  top: -6px;
}

.tt-header-row .tt-row-left > *:nth-child(1):not(.tt-logo) {
  margin-top: 0;
}

.tt-header-row .tt-col-left {
  display: flex;
  vertical-align: top;
}

.tt-header-row
  .tt-col-left:last-child
  .tt-desctop-menu:last-child:first-child
  nav
  > ul
  li.dropdown
  > a {
  padding-top: 18px;
  padding-bottom: 17px;
}

.tt-header-row
  .tt-col-left:last-child
  .tt-desctop-menu:last-child:first-child
  nav
  > ul
  li.dropdown
  > a
  .tt-badge {
  top: 2px;
}

.tt-header-row .tt-col-left:not(.tt-row-center):not(.tt-row-left) .tt-logo {
  margin-right: 30px;
}

.tt-header-row .tt-position-absolute:not(:first-child) {
  position: absolute;
}

.tt-header-row .tt-position-absolute.tt-search-active {
  left: 0;
}

.tt-header-row .tt-col-right.tt-position-absolute:not(:first-child) {
  right: 0;
}

.tt-header-row .tt-col-left.tt-position-absolute {
  left: 0;
  position: absolute;
}

.tt-header-row .tt-row-center {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  vertical-align: top;
}

.tt-header-row .tt-row-center .tt-logo:not(:last-child) {
  margin-bottom: 0;
}

.tt-header-row
  .tt-row-center
  .tt-logo
  + .tt-desctop-parent-menu
  .tt-desctop-menu
  nav
  > ul
  li.dropdown
  > a {
  padding-top: 13px;
  padding-bottom: 22px;
}

.tt-header-row
  .tt-row-center
  .tt-logo
  + .tt-desctop-parent-menu
  .tt-desctop-menu
  nav
  > ul
  li.dropdown
  > a
  .tt-badge {
  top: -6px;
}

.tt-header-row .tt-row-center > *:nth-child(1):not(.tt-logo) {
  margin-top: 0;
}

.tt-header-row .tt-col-large {
  flex: 2 1 auto;
}

.tt-header-row .tt-col-center {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.tt-header-row .tt-col-center .container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

@media (max-width: 870px) {
  .tt-header-row.tt-top-row {
    flex-direction: column;
    flex-wrap: wrap;
    padding: 12px 0 7px 0;
  }

  .tt-header-row.tt-top-row > div {
    max-width: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .tt-header-row.tt-top-row .tt-box-info {
    width: 100%;
    margin-top: 6px;
  }

  .tt-header-row.tt-top-row .tt-box-info ul {
    flex-direction: column;
    align-items: center;
  }

  .tt-header-row.tt-top-row .tt-box-info ul li {
    margin-left: 0;
    margin-right: 0;
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: center;
  }

  .tt-header-row.tt-top-row .tt-social-icon {
    margin-top: 6px;
  }

  .tt-header-row.tt-top-row .tt-social-icon li:first-child {
    margin-right: 10px;
  }

  .tt-header-row.tt-top-row
    .tt-social-icon
    li:not(:last-child):not(:first-child) {
    margin: 0 10px;
  }

  .tt-header-row.tt-top-row .tt-social-icon li:last-child {
    margin-left: 10px;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .tt-header-row.tt-top-row {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.container-fluid.tt-search-container {
  padding-left: 0;
  padding-right: 0;
}

.container-fluid.tt-search-container .tt-search .tt-dropdown-menu form {
  padding-left: 10px;
  padding-right: 10px;
}

.container-fluid.tt-search-container
  .tt-search
  .tt-dropdown-menu
  .tt-btn-search {
  right: 60px;
}

.tt-desctop-menu img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.tt-desctop-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tt-desctop-menu ul li {
  display: inline-block;
}

.tt-desctop-menu > ul > li > a {
  transition: color 0.2s linear, background-color 0.2s linear;
  display: inline-block;
  position: relative;
}

.tt-desctop-menu ul.popup-left {
  left: auto;
  right: 100%;
}

.tt-desctop-menu .dropdown {
  position: inherit;
}

.tt-desctop-menu .dropdown > a {
  transition: color 0.2s linear, background-color 0.2s linear;
}

.tt-desctop-menu .dropdown .dropdown-menu {
  display: none;
  top: -10000px;
  background: #ffffff;
}

.tt-desctop-menu .dropdown:hover {
  top: auto;
}

.tt-desctop-menu .dropdown:hover .dropdown-menu {
  top: 80px !important;
}

.tt-desctop-menu .dropdown-menu {
  padding: 33px 5px 27px 39px;
  margin-top: 0px;
  border: none;
  display: block;
  position: absolute;
}

.tt-desctop-menu .megamenu .dropdown-menu {
  left: 0;
}

.tt-desctop-menu .dropdown-menu.one-col {
  min-width: 202px;
  margin-left: 0;
  width: auto;
}

.tt-desctop-menu .dropdown-menu.large-width {
  width: 290px;
}

.tt-desctop-menu .dropdown-menu.large-width > li > a {
  white-space: normal;
}

.tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown:first-child
  .dropdown-menu {
  margin-left: 0;
}

.tt-desctop-menu .dropdown > a {
  position: relative;
}

.tt-desctop-menu .dropdown > a .tt-badge {
  position: absolute;
  top: 9px;
  right: 0;
}

.tt-desctop-menu .tt-badge {
  display: inline-block;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 400;
  color: #ffffff;
  background-color: transparent;
  font-size: 12px;
  line-height: 1;
  padding: 3px 4px 1px;
  position: relative;
  top: -1px;
  border-right: 2px;
}

.tt-desctop-menu .tt-new {
  color: #ffffff;
  background-color: #1393f5;
}

.tt-desctop-menu .tt-fatured {
  color: #ffffff;
  background-color: #fdbc20;
}

.tt-desctop-menu .tt-sale {
  color: #ffffff;
  background-color: #f8353e;
}

.tt-desctop-menu .tt-custom-color01 {
  color: #ffffff;
  background-color: #3db073;
}

.tt-desctop-menu .header-menu-product a {
  display: block;
}

.tt-desctop-menu .header-menu-product a .tt-title {
  color: #191919;
  transition: color 0.2s linear;
}

.tt-desctop-menu .header-menu-product a:hover .tt-title {
  color: var(--yellow);
}

.tt-desctop-menu .header-menu-product .tt-product .tt-description {
  padding-top: 6px;
}

.tt-desctop-menu nav > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.tt-desctop-menu nav > ul > li.dropdown > a {
  font-family: "Chakra Petch", sans-serif;
  font-size: 17px;
  line-height: 1.1;
  font-weight: 400;
  letter-spacing: 0.04em;
  padding: 17px 12px 16px;
  display: inline-flex;
}

.tt-desctop-menu .tt-title-submenu {
  font-size: 14px;
  display: block;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 8px;
  font-weight: 500;
  font-family: "Chakra Petch", sans-serif;
  color: #191919;
  transition: all 0.2s linear;
}

.tt-desctop-menu .tt-title-submenu img {
  width: 100%;
  height: auto;
  margin-top: 13px;
  margin-bottom: 7px;
}

.tt-desctop-menu .tt-title-submenu a {
  color: #191919;
  transition: all 0.2s linear;
}

.tt-desctop-menu .tt-title-submenu:hover {
  color: var(--yellow);
}

.tt-desctop-menu .tt-title-submenu:hover a {
  color: var(--yellow);
}

.tt-desctop-menu .tt-title-submenu.active {
  color: var(--yellow);
}

.tt-desctop-menu .tt-title-submenu.active a {
  color: var(--yellow);
}

.tt-desctop-menu .tt-title-submenu + .slick-slider,
.tt-desctop-menu .tt-title-submenu + .tt-menu-slider {
  margin-top: 4px;
}

.tt-desctop-menu .tt-megamenu-submenu {
  margin-top: -3px;
  display: inline-block;
}

.tt-desctop-menu .tt-megamenu-submenu > li {
  display: block;
  position: relative;
}

.tt-desctop-menu .tt-megamenu-submenu > li > a {
  color: #777777;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  position: relative;
  padding-top: 1px;
  padding-right: 23px;
  padding-bottom: 1px;
  transition: all 0.2s linear;
}

.tt-desctop-menu .tt-megamenu-submenu > li > a img {
  width: auto;
  position: absolute;
  right: -8px;
  top: 0px;
  visibility: hidden;
  z-index: 3;
  opacity: 0;
  transition: all 0.2s linear;
}

.tt-desctop-menu .tt-megamenu-submenu > li > a:hover {
  color: var(--yellow);
}

.tt-desctop-menu .tt-megamenu-submenu > li > a:hover img {
  visibility: visible;
  right: -34px;
  opacity: 1;
}

.tt-desctop-menu .tt-megamenu-submenu > li > a:hover:before {
  left: 0;
  opacity: 1;
}

.tt-desctop-menu .tt-megamenu-submenu > li.active > a {
  color: var(--yellow);
}

.tt-desctop-menu .tt-megamenu-submenu li a:not(:only-child):after {
  content: "";

  right: 13px;
  margin-top: 6px;
  position: absolute;
  border-top: 4px solid transparent;
  border-left: 4px solid #191919;
  border-bottom: 4px solid transparent;
  position: relative;
  display: inline-block;
  float: right;
  -webkit-font-smoothing: antialiased;
  pointer-events: none;
  transition: border 0.2s linear;
}

.tt-desctop-menu .tt-megamenu-submenu li a:not(:only-child):after .tt-badge {
  position: relative;
  top: 1px;
}

.tt-desctop-menu .tt-megamenu-submenu > li > a:not(:only-child):after {
  right: -13px;
}

.tt-desctop-menu .tt-megamenu-submenu > li ul {
  display: none;
  margin-top: 0px;
  background: #ffffff;
  position: absolute;
  z-index: 13;
  left: 100%;
  top: -13px;
  padding: 13px 0px 10px 0px;
  min-width: 210px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
}

.tt-desctop-menu .tt-megamenu-submenu > li ul ul.right,
.tt-desctop-menu .tt-megamenu-submenu > li ul ul.right ul {
  left: auto;
  right: 100%;
}

.tt-desctop-menu .tt-megamenu-submenu > li ul ul.right .left,
.tt-desctop-menu .tt-megamenu-submenu > li ul ul.right .left ul {
  left: 100%;
  right: auto;
}

.tt-desctop-menu .tt-megamenu-submenu > li ul li {
  margin-bottom: 0;
  display: block;
  position: relative;
}

.tt-desctop-menu .tt-megamenu-submenu > li ul li a {
  padding: 1px 7px 1px 20px;
  font-size: 14px;
  display: block;
  color: #777777;
  transition: all 0.2s linear;
}

.tt-desctop-menu .tt-megamenu-submenu > li ul li a:hover {
  color: var(--yellow);
}

.tt-desctop-menu .tt-megamenu-submenu > li ul li.active > a {
  color: var(--yellow);
}

.tt-desctop-menu ul.tt-sub-img {
  display: block;
}

.tt-desctop-menu ul.tt-sub-img li {
  display: block;
}

.tt-desctop-menu ul.tt-sub-img li a {
  display: block;
}

.tt-desctop-menu ul.tt-sub-img li a img {
  width: auto;
  position: absolute;
  right: -34px;
  top: -5px;
  visibility: hidden;
  z-index: 3;
  max-width: 141px;
  opacity: 0;
  transition: all 0.2s linear;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
}

.tt-desctop-menu ul.tt-sub-img li a:hover img {
  visibility: visible;
  top: 0px;
  opacity: 1;
}

.tt-desctop-menu ul.tt-sub-img li.tt-sub-menu > a {
  display: inline-block;
}

.tt-desctop-menu .dropdown-menu {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
}

.tt-desctop-menu .dropdown-menu > .row:not(:first-child) {
  margin-top: 26px;
}

.tt-desctop-menu .megamenu .dropdown-menu {
  width: 100%;
  padding: 33px 39px 39px 39px;
}

.tt-desctop-menu .megamenu .col {
  max-width: 281px;
}

@media (max-width: 1229px) {
  .tt-desctop-menu .megamenu .col {
    max-width: 228px;
  }
}

.tt-desctop-menu .tt-megamenu-col-01 .dropdown-menu {
  width: 280px;
  margin-left: -40px;
}

.tt-desctop-menu .tt-megamenu-col-01 .col {
  width: 100%;
  display: block;
}

.tt-desctop-menu .tt-megamenu-col-01 .tt-col-list [class^="col"] {
  flex-basis: inherit;
}

.tt-desctop-menu .tt-megamenu-col-01 .row.tt-col-list {
  margin-left: 0;
  margin-right: 0;
}

.tt-desctop-menu .tt-megamenu-col-02 .dropdown-menu {
  width: 630px;
  margin-left: -40px;
}

.tt-desctop-menu .tt-megamenu-col-02 .col {
  max-width: 281px;
  min-width: 281px;
}

.tt-desctop-menu .tt-col-list {
  margin-top: -23px;
}

.tt-desctop-menu .tt-col-list [class^="col"] {
  margin-top: 23px;
}

.tt-desctop-menu:not(.tt-hover-02) li.dropdown:first-child > a {
  padding-left: 0;
}

.tt-desctop-menu:not(.tt-hover-02) li.dropdown:last-child > a {
  padding-right: 0;
}

.tt-desctop-menu:not(.tt-hover-02) li.dropdown > a {
  color: #191919;
  background: transparent;
}

.tt-desctop-menu:not(.tt-hover-02) li.dropdown > a:hover {
  color: var(--yellow);
  background: transparent;
}

.tt-desctop-menu:not(.tt-hover-02) li.dropdown.active > a {
  color: var(--yellow);
  background: transparent;
}

.tt-desctop-menu:not(.tt-hover-02) li.dropdown.selected > a {
  color: var(--yellow);
  background: transparent;
}

.tt-desctop-menu.tt-hover-02 nav > ul li.dropdown > a {
  padding-left: 13px;
  padding-right: 13px;
}

.tt-desctop-menu.tt-hover-02 nav > ul li.dropdown > a {
  color: #191919;
  background: transparent;
}

.tt-desctop-menu.tt-hover-02 nav > ul li.dropdown > a:hover {
  color: #ffffff;
  background: var(--yellow);
}

.tt-desctop-menu.tt-hover-02 nav > ul li.dropdown.active > a {
  color: #ffffff;
  background: var(--yellow);
}

.tt-desctop-menu.tt-hover-02 nav > ul li.dropdown.selected > a {
  color: #ffffff;
  background: var(--yellow);
}

/*
	tt-mobile-header
*/
.tt-mobile-header .tt-top-line {
  border-top: 1px solid #e9e7e7;
}

.tt-mobile-header .tt-dropdown-obj {
  margin-top: 11px;
  padding-bottom: 7px;
}

.tt-mobile-header .tt-parent-box {
  margin-left: 20px;
}

.tt-mobile-header .tt-search .tt-dropdown-menu form {
  height: 50px;
}

.tt-mobile-header .container-fluid {
  min-height: 20px;
}

@media (min-width: 481px) {
  .tt-mobile-header .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.tt-mobile-header .tt-logo-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  align-content: center;
  align-items: center;
  height: 55px;
  width: 100%;
}

.tt-mobile-header .tt-logo-container .tt-logo-alignment {
  top: -3px;
}

.tt-mobile-header .tt-logo-container .tt-logo-alignment .tt-title {
  margin-top: 11px;
}

.tt-mobile-header .tt-logo-container .tt-logo img {
  max-height: 60px;
}

@media (min-width: 481px) {
  .tt-mobile-header .tt-mobile-parent-menu {
    flex: 1 1;
  }
}

.back-to-top {
  position: fixed;
  border-radius: 45%;
  background-color: #e9e9e9;
  padding: 5px;
  box-shadow: 1px 4px 9px #0000001a;
  border: none;
  right: 40px;
  bottom: 60px;
  z-index: 11;
  animation: 1s infinite alternate action;
}
.back-to-top i {
  font-size: 3rem;
  color: #764bf9;
}

.tooltip-container {
  --background: linear-gradient(45deg, #22d3ee, #1f9df5);
  position: relative;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 17px;
  border-radius: 8px;
  color: #fff;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  transform-origin: bottom;
  padding: 0.3em 0.6em;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  background: black;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tooltip::before {
  position: absolute;
  content: "";
  height: 0.6em;
  width: 0.6em;
  bottom: -0.2em;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  background: black;
}

.tooltip-container:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transform: translateX(-50%) scale(1);
}
.tooltip-container:hover {
  transform: scale(0.9);
}

@media (max-width: 480px) {
  .tt-mobile-header .tt-header-row.tt-top-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
  }

  .tt-mobile-header .tt-header-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .tt-mobile-header .tt-header-row .tt-parent-box {
    margin-left: inherit;
  }

  .tt-mobile-header .tt-header-row .tt-menu-toggle {
    padding-left: 8px;
    padding-right: 8px;
  }

  .tt-mobile-header
    .tt-header-row
    .tt-parent-box:last-child
    .tt-dropdown-toggle {
    padding-right: 8px;
  }
}

/* tt-menu-toggle */
.tt-menu-toggle {
  position: relative;
  display: inline-block !important;
  padding: 12px 5px 5px 0px;
  cursor: pointer;
  margin-left: 1px;
}

.tt-menu-toggle i {
  color: #191919;
  font-size: 20px;
  line-height: 1;
  position: relative;
  display: inline-block;
}

.tt-menu-toggle.stylization-02 {
  background-color: var(--yellow);
  padding: 10px 11px 5px;
  transition: background-color 0.2s linear;
  border-radius: 6px;
}

.tt-menu-toggle.stylization-02 [class^="icon"] {
  color: #ffffff;
  font-size: 18px;
}

.tt-menu-toggle.stylization-02:hover {
  background-color: #191919;
}

.tt-menu-toggle.stylization-02:hover [class^="icon"] {
  color: #ffffff;
}

body:not(.touch-device) .tt-menu-toggle:not(.stylization-02):hover i {
  color: var(--yellow);
}

/* visibility desktop and mobile header */
@media (max-width: 1024px) {
  .tt-desktop-header {
    display: none;
  }

  .tt-mobile-header {
    display: block;
  }
}

@media (min-width: 1025px) {
  .tt-desktop-header {
    display: block;
  }

  .tt-mobile-header {
    display: none;
  }
}

/* tt-stuck-nav */
.tt-stuck-nav {
  width: 100%;
  position: fixed;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  top: -100%;
  z-index: 20;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.3);
  min-height: 45px;
}

.tt-stuck-nav .tt-dropdown-obj {
  margin-top: 8px;
  padding-bottom: 6px;
}

.tt-stuck-nav .tt-desctop-menu nav > ul li.dropdown > a {
  padding-top: 17px;
  padding-bottom: 16px;
}

.tt-stuck-nav .tt-desctop-menu nav > ul li.dropdown > a .tt-badge {
  top: 2px;
  padding: 1px 3px 1px;
}

.tt-stuck-nav
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown:first-child
  > a {
  padding-left: 0;
}

.tt-stuck-nav .tt-menu-toggle {
  top: -1px;
}

.tt-stuck-nav .tt-menu-toggle.stylization-02 {
  margin-top: 1px;
}

.tt-stuck-nav .tt-btn-close {
  position: relative;
  top: 1px;
}

@media (min-width: 1025px) {
  .tt-stuck-nav .tt-stuck-desctop-menu-categories + .tt-stuck-parent-menu {
    margin-left: 26px;
  }
}

.tt-stuck-nav .tt-menu-categories:not(.opened) .tt-dropdown-toggle {
  -webkit-border-bottom-right-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

@media (max-width: 1024px) {
  .tt-stuck-nav .container {
    max-width: 100%;
  }

  .tt-stuck-nav .tt-parent-box {
    margin-left: 20px;
  }

  .tt-stuck-nav .tt-stuck-desctop-menu-categories {
    display: none;
  }
}

@media (min-width: 1025px) {
  .tt-stuck-nav .tt-stuck-mobile-menu-categories {
    display: none;
  }
}

@media (min-width: 481px) {
  .tt-stuck-nav .tt-stuck-parent-menu {
    flex: 2 1 auto;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .tt-stuck-nav .container-fluid,
  .tt-stuck-nav .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 480px) {
  .tt-stuck-nav .tt-header-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
  }

  .tt-stuck-nav .tt-header-row .tt-parent-box {
    margin-left: inherit;
  }

  .tt-stuck-nav .tt-header-row .tt-menu-toggle {
    padding-left: 8px;
    padding-right: 8px;
  }

  .tt-stuck-nav .tt-header-row .tt-parent-box:last-child .tt-dropdown-toggle {
    padding-right: 8px;
  }
}

@media (min-width: 1025px) {
  .tt-stuck-nav .container-fluid .tt-desctop-menu .dropdown-menu {
    max-width: 990px;
  }
}

@media (min-width: 1230px) {
  .tt-stuck-nav .container-fluid .tt-desctop-menu .dropdown-menu {
    max-width: 1200px;
  }
}

.stuck.tt-stuck-nav {
  top: 0;
}

@media (max-width: 1024px) {
  .stuck.tt-stuck-nav {
    background: #ffffff;
  }

  .stuck.tt-stuck-nav .tt-search .tt-dropdown-menu .search-results {
    top: 23px;
    border-top: 1px solid #e9e7e7;
    padding-top: 20px;
  }

  .stuck.tt-stuck-nav .tt-search.active .tt-dropdown-menu::before {
    content: "";
    display: block;
    width: calc(100% + 40px);
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #ffffff;
    margin-left: -20px;
    margin-right: -20px;
  }
}

@media (min-width: 1025px) {
  .stuck.tt-stuck-nav {
    background: #ffffff;
  }
}

.tt-obj-menu.obj-aligment-center .tt-desctop-menu nav > ul,
.justify-content-md-center .tt-desctop-menu nav > ul {
  justify-content: center;
}

.tt-desctop-menu.tt-hover-03:not(.tt-hover-02):not(.tt-hover-01)
  nav
  > ul
  li.dropdown
  > a
  span {
  position: relative;
  display: inline-block;
}

.tt-desctop-menu.tt-hover-03:not(.tt-hover-02):not(.tt-hover-01)
  nav
  > ul
  li.dropdown
  > a
  span:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0%;
  height: 2px;
  background: #191919;
  transition: width 0.3s ease-out;
}

.tt-desctop-menu.tt-hover-03:not(.tt-hover-02):not(.tt-hover-01)
  nav
  > ul
  li.dropdown
  > a:hover
  span:before {
  width: 100%;
}

.tt-desctop-menu.tt-hover-03:not(.tt-hover-02):not(.tt-hover-01)
  nav
  > ul
  li.dropdown.active
  > a
  span:before {
  width: 100%;
}

.tt-desctop-menu.tt-hover-03:not(.tt-hover-02):not(.tt-hover-01)
  nav
  > ul
  li.dropdown.selected
  > a
  span:before {
  width: 100%;
}

/*
	mobile menu
*/

.panel-menu,
.mmpanels,
.mmpanels > .mmpanel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: inherit;
}

.panel-menu {
  width: 270px;
  background: #ffffff;
  position: fixed;
  z-index: 22;
}

.panel-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.panel-menu ul li {
  margin: 0;
  width: 100%;
}

.panel-menu ul li a {
  display: flex;
  padding: 9px 27px 9px 30px;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  color: #191919;
  background: transparent;
  position: relative;
  transition: all 0.2s linear;
}

.panel-menu ul li a:hover {
  color: var(--yellow);
  background: transparent;
}

.panel-menu #mm0.mmpanel a:not(.mm-close) {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  color: #191919;
  background-color: transparent;
}

.panel-menu #mm0.mmpanel a:not(.mm-close):after {
  color: #191919;
}

.panel-menu #mm0.mmpanel a:not(.mm-close):hover {
  color: var(--yellow);
  background-color: transparent;
}

.panel-menu #mm0.mmpanel a:not(.mm-close):hover:after {
  color: var(--yellow);
}

.panel-menu #mm0.mmpanel .mm-next-level:after {
  top: 12px;
}

.panel-menu .mmpanel:not(#mm0) a:not(.mm-original-link) {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 400;
}

.panel-menu .mmpanel .tt-badge {
  display: inline-block;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 12px;
  line-height: 1;
  padding: 3px 4px 1px;
  max-height: 16px;
  top: 2px;
  position: relative;
  margin-left: 7px;
  border-right: 2px;
}

.panel-menu .mmpanel .tt-new {
  color: #ffffff;
  background-color: #1393f5;
}

.panel-menu .mmpanel .tt-fatured {
  color: #ffffff;
  background-color: #fdbc20;
}

.panel-menu .mmpanel .tt-sale {
  color: #ffffff;
  background-color: #f8353e;
}

.panel-menu .mmpanel .mm-original-link .tt-badge {
  top: -1px;
}

.panel-menu .mm-close,
.panel-menu .mm-prev-level,
.panel-menu .mm-original-link {
  display: block;
  color: #777777;
  background-color: transparent;
  background-color: transparent;
}

.panel-menu .mm-close:before,
.panel-menu .mm-prev-level:before,
.panel-menu .mm-next-level:after {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  transition: all 0.2s linear;
  color: #777777;
}

.panel-menu .mm-close:hover,
.panel-menu .mm-prev-level:hover,
.panel-menu .mm-next-level:hover {
  color: var(--yellow);
  background-color: transparent;
}

.panel-menu .mm-close:hover:before .mm-prev-level:hover:before,
.panel-menu .mm-next-level:hover:after {
  color: var(--yellow);
}

.panel-menu li.mm-close-parent {
  margin-bottom: 15px;
}

.panel-menu li.mm-close-parent .mm-close {
  padding: 15px 16px 13px 29px;
  color: #777777;
  border-bottom: 1px solid #e9e7e7;
  background-color: transparent;
}

.panel-menu li.mm-close-parent .mm-close:before {
  padding-right: 6px;
  position: relative;
  top: 1px;
  color: #777777;
}

.panel-menu li.mm-close-parent .mm-close:hover {
  color: var(--yellow);
  background-color: transparent;
}

.panel-menu li.mm-close-parent .mm-close:hover:before {
  color: var(--yellow);
}

.panel-menu .mm-prev-level {
  padding: 15px 16px 13px 29px;
  color: #777777;
  background-color: transparent;
  border-bottom: 1px solid #e9e7e7;
  margin-bottom: 15px;
}

.panel-menu .mm-prev-level:before {
  padding-right: 10px;
  position: relative;
  top: 1px;
  color: #777777;
}

.panel-menu .mm-prev-level:hover {
  color: var(--yellow);
  background-color: transparent;
}

.panel-menu .mm-prev-level:hover:before {
  color: var(--yellow);
}

.panel-menu .mm-next-level {
  color: #191919;
  background-color: transparent;
}

.panel-menu .mm-next-level:after {
  padding-left: 10px;
  top: 12px;
  right: 30px;
  position: absolute;
  color: #777777;
}

.panel-menu .mm-next-level:hover {
  color: var(--yellow);
  background-color: transparent;
}

.panel-menu .mm-next-level:hover:after {
  color: var(--yellow);
}

.panel-menu .mm-original-link {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  color: #191919;
  background-color: transparent;
}

.panel-menu .mm-original-link:before {
  padding-right: 10px;
  color: #777777;
}

.panel-menu .mm-original-link:hover {
  color: var(--yellow);
  background-color: transparent;
}

.panel-menu .mm-original-link:hover:before {
  color: var(--yellow);
}

.mm-open {
  overflow: hidden;
}

.mm-open .mm-fullscreen-bg {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.55);
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 21;
  display: none;
}

.mmitemopen.panel-menu,
.mmitemopen.panel-menu.mm-right {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.panel-menu {
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.panel-menu.mm-right,
.mmitemopen.panel-menu.mm-right.mmhide {
  left: auto;
  right: 0;
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mmitemopen.panel-menu.mmhide {
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mmpanel {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mmpanels {
  overflow: hidden;
}

.mmpanel.mmopened {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mmpanel.mmsubopened {
  -webkit-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

.mmpanels > .mmpanel {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}

.mmpanels,
.mmpanels > .mmpanel {
  background: inherit;
  border-color: inherit;
}

.mmpanels > .mmpanel:not(.mmhidden) {
  display: block;
}

.mmpanels > .mmpanel:after {
  content: "";
  display: block;
  height: 20px;
}

.mmhidden,
.mm-nav-btn {
  display: none;
}

.mm-fullscreen-bg,
.mm-fullscreen-bg:focus,
.mm-fullscreen-bg:active,
.mm-fullscreen-bg:hover {
  outline: none;
}

.tt-menu-slider {
  max-height: 389px;
}

/*
	tt-header-holder
 */

.tt-search-container .tt-obj-options {
  width: 100%;
  position: absolute;
  top: 0;
}

header .tt-dropdown-obj.obj-move-left .tt-dropdown-menu {
  left: 0;
  right: auto;
}

.tt-header-holder {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

/* .tt-header-holder .tt-col-obj {
  order: 1;
}

.tt-header-holder .tt-obj-menu {
  flex: 2 1 auto;
}

.tt-header-holder .obj-move-left {
  order: 0;
  margin-right: auto !important;
}

.tt-header-holder .obj-move-right {
  order: 3;
  margin-left: auto !important;
} */

.tt-header-holder .tt-obj-options.obj-move-right {
  right: 0;
}

.tt-header-holder .obj-move-right.tt-position-absolute:not(:first-child) {
  right: 0;
}

.tt-header-holder .tt-search-active {
  width: 100%;
}

.tt-header-holder .obj-aligment-left {
  text-align: left;
}

.tt-header-holder .obj-aligment-center {
  flex: 2 1 auto;
  text-align: center;
}

.tt-header-holder .obj-aligment-center > * {
  text-align: left;
  margin-left: 0;
  margin-right: 0;
}

.tt-header-holder .obj-aligment-right {
  flex: 2 1 auto;
  text-align: right;
}

.tt-header-holder .obj-aligment-right > * {
  text-align: left;
}

.tt-header-holder .obj-aligment-right:not(:first-child):not(:last-child) > * {
  margin-right: 30px;
}

.tt-header-holder .tt-position-absolute {
  position: absolute;
  top: 0;
}

.tt-header-holder
  .tt-obj-logo:not(.obj-move-right):not(.obj-aligment-center)
  .tt-logo {
  margin-right: 33px;
}

.tt-header-holder .tt-col-obj.obj-move-right .tt-logo,
.tt-header-holder .tt-col-obj.obj-move-right .tt-desctop-menu {
  margin-left: 30px;
}

.tt-header-holder
  .tt-obj-menu:last-child:first-child
  .tt-desctop-menu:not(.tt-menu-small)
  nav
  > ul
  li.dropdown
  > a {
  padding-top: 18px;
  padding-bottom: 17px;
}

.tt-header-holder .tt-desctop-menu.tt-menu-small {
  margin-top: -10px;
}

.tt-header-holder .tt-desctop-menu.tt-menu-small nav > ul li.dropdown > a {
  padding-top: 10px;
  padding-bottom: 15px;
}

.tt-header-holder
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown:first-child
  > a {
  padding-left: 0;
}

.tt-header-holder
  .tt-desctop-menu:not(.tt-hover-02)
  nav
  > ul
  li.dropdown:last-child
  > a {
  padding-right: 0;
}

html.ie .tt-header-holder .tt-desctop-menu.tt-menu-small {
  margin-top: -7px;
}

.tt-obj-menu.obj-aligment-center .tt-desctop-menu .megamenu > .dropdown-menu {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.tt-obj-menu.obj-aligment-right .tt-desctop-menu .megamenu .dropdown-menu {
  left: auto;
  right: 0;
}

@media (min-width: 1230px) {
  .tt-header-static .tt-obj-menu.obj-aligment-left {
    position: relative;
  }
}

@media (max-width: 1229px) {
  .tt-header-static
    .tt-obj-menu.obj-aligment-left
    .tt-desctop-menu
    .megamenu
    > .dropdown-menu {
    left: 0;
    right: 0;
    max-width: 100%;
  }
}

@media (min-width: 1230px) {
  .tt-header-static .tt-obj-menu.obj-aligment-center {
    position: relative;
  }
}

@media (max-width: 1229px) {
  .tt-header-static
    .tt-obj-menu.obj-aligment-center
    .tt-desctop-menu
    .megamenu
    > .dropdown-menu {
    left: 0;
    right: 0;
    max-width: 100%;
  }
}

@media (min-width: 1230px) {
  .tt-header-static .tt-obj-menu.obj-aligment-right {
    position: relative;
  }
}

@media (max-width: 1229px) {
  .tt-header-static
    .tt-obj-menu.obj-aligment-right
    .tt-desctop-menu
    .megamenu
    > .dropdown-menu {
    left: 0;
    right: 0;
    max-width: 100%;
  }
}

@media (min-width: 1230px) {
  .tt-header-static .container-fluid .tt-header-holder .tt-obj-logo {
    width: 208px;
  }
}

@media (max-width: 1229px) {
  .tt-header-static .container-fluid .tt-header-holder .tt-obj-logo {
    width: 195px;
  }
}

@media (min-width: 1230px) {
  .tt-header-static
    .container-fluid
    .tt-header-holder
    .tt-obj-logo
    + .obj-aligment-center
    + .tt-obj-options.obj-move-right {
    width: 208px;
  }
}

@media (max-width: 1229px) {
  .tt-header-static
    .container-fluid
    .tt-header-holder
    .tt-obj-logo
    + .obj-aligment-center
    + .tt-obj-options.obj-move-right {
    width: 195px;
  }
}

.tt-header-static
  .container-fluid
  .tt-header-holder
  .tt-obj-logo
  + .obj-aligment-center
  + .obj-move-right {
  text-align: right;
}

/*
	header-tel-info
*/

.header-tel-info {
  font-size: 16px;
  color: #191919;
  display: inline-block;
}

.header-tel-info [class^="icon"] {
  color: var(--yellow);
  font-size: 18px;
  line-height: 1;
}

@media (max-width: 1024px) {
  .tt-mobile-header .header-tel-info {
    text-align: center;
    padding-top: 13px;
  }
}

/* small-header */
.small-header .tt-desctop-menu nav > ul > li.dropdown > a {
  padding-top: 13px;
  padding-bottom: 11px;
}

.small-header .tt-dropdown-obj {
  margin-top: 0px;
}

.panel-menu ul li a {
  position: relative;
}

.panel-menu ul li a svg {
  position: absolute;
  top: 10px;
  left: 30px;
  width: 13px;
  height: auto;
}

.panel-menu ul li a svg path {
  fill: #999999;
  transition: fill 0.2s linear;
}

.panel-menu ul li a [class^="icon-"] {
  position: absolute;
  top: 10px;
  color: #999999;
}

.panel-menu ul li a span {
  display: inline-block;
}

.panel-menu ul li a > svg + span,
.panel-menu ul li a > [class^="icon-"] + span {
  margin-left: 24px;
}

.panel-menu ul li a:hover svg path {
  fill: #191919;
}

.panel-menu ul li a:hover [class^="icon-"] {
  color: #191919;
}

.tt-desctop-menu .tt-megamenu-submenu > li ul li a svg {
  left: 20px;
}

/*
	desctop menu integration svg
*/

.tt-desctop-menu ul li a {
  position: relative;
}

.tt-desctop-menu > nav > ul > li > a .tt-icon {
  position: absolute;
  top: 17px;
  left: 16px;
}

.tt-desctop-menu > nav > ul > li > a .tt-icon svg {
  width: 13px;
  height: auto;
}

.tt-desctop-menu > nav > ul > li > a .tt-icon svg path {
  fill: #999999;
  transition: fill 0.2s linear;
}

.tt-desctop-menu > nav > ul > li > a .tt-icon [class^="icon-"] {
  color: #999999;
  transition: color 0.2s linear;
}

.tt-desctop-menu > nav > ul > li > a .tt-icon:hover {
  fill: #191919;
}

.tt-desctop-menu > nav > ul > li > a .tt-icon + span {
  margin-left: 37px;
}

.tt-desctop-menu > nav > ul > li.active > a .tt-icon [class^="icon-"] {
  color: #191919;
}

.tt-desctop-menu > nav > ul > li.active > a .tt-icon svg path {
  fill: #191919 !important;
}

.tt-desctop-menu .dropdown-menu ul li > a .tt-icon {
  position: absolute;
  top: 3px;
}

.tt-desctop-menu .dropdown-menu ul li > a .tt-icon [class^="icon-"] {
  transition: color 0.2s linear;
}

.tt-desctop-menu .dropdown-menu ul li > a .tt-icon svg {
  width: 13px;
  height: auto;
}

.tt-desctop-menu .dropdown-menu ul li > a .tt-icon svg path {
  fill: #999999;
  transition: fill 0.2s linear;
}

.tt-desctop-menu .dropdown-menu ul li > a .tt-icon + span {
  margin-left: 24px;
  display: inline-block;
}

.tt-desctop-menu .dropdown-menu ul li > a:hover .tt-icon svg path {
  fill: #191919;
}

.tt-desctop-menu .dropdown-menu ul li.active > a .tt-icon [class^="icon-"] {
  color: #191919;
}

.tt-menu-small > nav > ul > li > a .tt-icon {
  top: 9px;
}

.small-header .tt-desctop-menu > nav > ul > li > a .tt-icon {
  top: 12px;
}

.header-inline-options-box > * {
  display: inline-block;
  position: relative;
}

/* desktop-header */

.tt-desctop-menu .custom-width {
  width: 536px !important;
}

/*
	Embed Mobile Menu
*/

.panel-menu #mm0.mmpanel a:not(.mm-close) {
  font-weight: 400;
}

.panel-menu ul > li.item-categories.last-child {
  border-bottom: 1px solid #e9e7e7;
  padding-bottom: 28px;
  margin-bottom: 27px;
}
/*---------------------------------------*/
/*----  22. Wishlist page  ----*/
/*---------------------------------------*/
/* modal-wishlist */
.tt-wishlist-box .tt-wishlist-list .tt-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
}
.tt-wishlist-box .tt-wishlist-list .tt-item .tt-col-description {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  min-width: 500px;
}
.tt-wishlist-box .tt-wishlist-list .tt-item .tt-col-description .tt-img img {
  width: 100%;
  height: 100%;
  width: 180px;
  object-fit: cover;
}
.tt-wishlist-box
  .tt-wishlist-list
  .tt-item
  .tt-col-description
  .tt-img
  + .tt-description {
  margin-left: 19px;
  text-align: center;
}
.tt-wishlist-box
  .tt-wishlist-list
  .tt-item
  .tt-col-description
  .tt-description
  .tt-title {
  font-family: "Chakra Petch", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #191919;
}
.tt-wishlist-box
  .tt-wishlist-list
  .tt-item
  .tt-col-description
  .tt-description
  .tt-title
  a {
  color: #191919;
}
.tt-wishlist-box
  .tt-wishlist-list
  .tt-item
  .tt-col-description
  .tt-description
  .tt-title
  a:hover {
  color: #2879fe;
}
.tt-wishlist-box
  .tt-wishlist-list
  .tt-item
  .tt-col-description
  .tt-description
  .tt-price {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: "Chakra Petch", sans-serif;
  color: #191919;
}
.tt-wishlist-box
  .tt-wishlist-list
  .tt-item
  .tt-col-description
  .tt-description
  .tt-price
  .new-price {
  color: #f8353e;
  margin-right: 7px;
}
.tt-wishlist-box
  .tt-wishlist-list
  .tt-item
  .tt-col-description
  .tt-description
  .tt-price
  .old-price {
  color: #191919;
  text-decoration: line-through;
}
.tt-wishlist-box
  .tt-wishlist-list
  .tt-item
  .tt-col-description
  .tt-description
  .tt-price
  .old-price
  .money {
  text-decoration: line-through;
}
.tt-wishlist-box
  .tt-wishlist-list
  .tt-item
  .tt-col-description
  .tt-description
  .tt-price
  span {
  display: inline-block;
}
.tt-wishlist-box .tt-wishlist-list .tt-item .tt-col-btn .tt-btn-addtocart {
  background: var(--main);
  font-family: "Chakra Petch", sans-serif;
  border: none;
  color: #ffffff;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.03em;
  position: relative;
  outline: none;
  padding: 6px 31px 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid white;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 575px) {
  .tt-wishlist-box .tt-wishlist-list .tt-item .tt-col-btn .tt-btn-addtocart {
    font-size: 12px;
    padding-left: 25px;
    padding-right: 25px;
    height: 39px;
  }
}
.tt-wishlist-box .tt-wishlist-list .tt-item .tt-col-btn .tt-btn-addtocart i {
  color: #ffffff;
  font-size: 20px;
  margin-right: 6px;
  position: relative;
  top: -2px;
}
.tt-wishlist-box
  .tt-wishlist-list
  .tt-item
  .tt-col-btn
  .tt-btn-addtocart:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}
.tt-wishlist-box
  .tt-wishlist-list
  .tt-item
  .tt-col-btn
  .tt-btn-addtocart:hover
  i {
  color: black;
}
.tt-wishlist-box .tt-wishlist-list .tt-item .tt-col-btn > * {
  margin-left: 12px;
}
.tt-wishlist-box .tt-wishlist-list .tt-item .tt-col-btn .btn-link {
  top: -4px;
  position: relative;
}
.tt-wishlist-box .tt-wishlist-list .tt-item .tt-col-btn .btn-link .icon-f-73 {
  top: 4px;
}
@media (max-width: 1024px) {
  .tt-wishlist-box .tt-wishlist-list .tt-item .tt-col-btn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .tt-wishlist-box
    .tt-wishlist-list
    .tt-item
    .tt-col-btn
    > *:not(:first-child) {
    margin-top: 5px;
  }
  .tt-wishlist-box
    .tt-wishlist-list
    .tt-item
    .tt-col-btn
    .tt-btn-addtocart
    + * {
    margin-top: 14px;
  }
}
.tt-wishlist-box .tt-wishlist-list .tt-item:not(:last-child) {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.tt-wishlist-box .tt-wishlist-list .tt-item:not(:last-child) {
  border-bottom: 1px solid #e9e7e7;
}
@media (max-width: 790px) {
  .tt-wishlist-box .tt-wishlist-list .tt-item {
    flex-direction: column;
  }
  .tt-wishlist-box .tt-wishlist-list .tt-item:not(:last-child) {
    padding-bottom: 10px;
  }
  .tt-wishlist-box .tt-wishlist-list .tt-item .tt-col-description {
    flex-direction: row;
    min-width: inherit;
  }
  .tt-wishlist-box
    .tt-wishlist-list
    .tt-item
    .tt-col-description
    .tt-img
    + .tt-description {
    margin-left: 0;
    margin-right: 0;
  }
  .tt-wishlist-box
    .tt-wishlist-list
    .tt-item
    .tt-col-description
    .tt-description {
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }
  .tt-wishlist-box
    .tt-wishlist-list
    .tt-item
    .tt-col-description
    .tt-description
    .tt-price {
    text-align: center;
    display: block;
  }
  .tt-wishlist-box .tt-wishlist-list .tt-item .tt-col-btn {
    align-items: center;
    margin-top: 14px;
  }
  .tt-wishlist-box .tt-wishlist-list .tt-item .tt-col-btn > * {
    margin-left: 0;
  }
}

/*---------------------------------------*/
/*--------     4. Footer         --------*/
/*---------------------------------------*/
/*
	* footer mobile begin < 789px
*/

footer {
  margin-top: 0px;
  /*
		Color schemes desctope
	*/
}

footer .tt-logo .tt-title {
  padding: 0;
  display: inline-block;
  font-size: 17px;
  line-height: 1.1;
  position: relative;
  top: 5px;
  font-family: "Chakra Petch", sans-serif;
  color: var(--yellow);
  font-weight: 500;
  transition: color 0.2s linear;
}

footer .tt-logo .tt-title span {
  font-weight: normal;
}

@media (max-width: 789px) {
  footer .tt-logo .tt-title {
    top: 0px;
  }
}

footer .tt-logo img {
  height: auto;
  max-height: 20px;
}

footer .tt-logo-alignment {
  position: relative;
}

@media (min-width: 790px) {
  footer .tt-logo-alignment {
    top: 1px;
  }
}

@media (max-width: 789px) {
  footer .tt-logo-alignment {
    top: 0px;
  }
}

@media (min-width: 790px) {
  footer .tt-footer-col {
    padding-bottom: 49px;
  }

  footer .tt-footer-col div[class^="col-"] {
    margin-top: 55px;
  }

  footer .tt-footer-col .tt-social-icon {
    margin-left: -8px;
  }

  footer .tt-footer-col .tt-newsletter {
    padding-bottom: 0px;
  }

  footer
    .tt-footer-col
    .tt-newsletter:not(.tt-indent-item)
    .tt-collapse-content
    form {
    margin-top: 24px;
  }

  footer
    .tt-footer-col
    .tt-newsletter:not(.tt-indent-item)
    .tt-collapse-content
    .form-inline
    .form-group {
    width: 100%;
    flex-wrap: nowrap;
  }

  footer
    .tt-footer-col
    .tt-newsletter:not(.tt-indent-item)
    .tt-collapse-content
    .form-inline
    .form-group
    .form-control {
    width: 100%;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  footer
    .tt-footer-col
    .tt-newsletter:not(.tt-indent-item)
    .tt-collapse-content
    .form-inline
    .form-group
    .btn {
    -webkit-border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  footer
    .tt-footer-col
    .tt-newsletter:not(.tt-indent-item)
    .tt-collapse-content
    > *:nth-child(1) {
    margin-top: 0;
  }

  footer .tt-footer-col .tt-newsletter.tt-indent-item .form-group {
    margin-bottom: 0;
  }

  footer
    .tt-footer-col
    .tt-newsletter.tt-indent-item
    .tt-collapse-content
    form {
    margin-top: 24px;
  }

  footer
    .tt-footer-col
    .tt-newsletter.tt-indent-item
    .tt-collapse-content
    .form-inline
    .form-group {
    width: 100%;
    flex-wrap: nowrap;
  }

  footer
    .tt-footer-col
    .tt-newsletter.tt-indent-item
    .tt-collapse-content
    .form-inline
    .form-group
    .form-control {
    width: 100%;
    border: 1px solid #e9e7e7;
  }

  footer
    .tt-footer-col
    .tt-newsletter.tt-indent-item
    .tt-collapse-content
    .form-inline
    .form-group
    .btn {
    margin-left: 10px;
  }

  footer
    .tt-footer-col
    .tt-newsletter.tt-indent-item
    .tt-collapse-content
    > *:nth-child(1) {
    margin-top: 0;
  }

  footer
    .tt-footer-col
    .tt-newsletter.tt-indent-item
    .tt-collapse-content
    form {
    margin-top: 13px;
  }

  footer .tt-footer-col .tt-collapse-listing div[class^="col-"] {
    margin-top: 35px;
  }
}

@media (min-width: 790px) {
  footer .tt-footer-custom {
    padding: 10px 0;
  }

  footer .tt-footer-custom .tt-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

  footer .tt-footer-custom .tt-col-left {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin-left: -10px;
  }

  footer .tt-footer-custom .tt-col-left .tt-col-item {
    margin-left: 10px;
  }

  footer .tt-footer-custom .tt-col-center {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    flex: 1 1 auto;
  }

  footer .tt-footer-custom .tt-col-right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  footer .tt-footer-custom .tt-payment-list {
    margin-top: 7px;
    padding-bottom: 5px;
  }

  footer .tt-footer-custom .tt-box-copyright {
    padding: 19px 0 7px;
  }

  footer .tt-footer-custom .tt-logo-col + .tt-col-item .tt-box-copyright {
    padding-top: 19px;
    padding-bottom: 7px;
  }

  footer .tt-footer-custom .tt-newsletter .tt-collapse-title {
    margin-right: 106px;
  }

  footer .tt-footer-custom .tt-newsletter .tt-mobile-collapse {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  footer .tt-footer-custom .tt-newsletter .tt-collapse-content p {
    display: none;
  }
}

@media (min-width: 790px) and (min-width: 790px) {
  footer .tt-footer-custom .tt-newsletter .tt-collapse-title {
    padding-bottom: 0;
  }

  footer .tt-footer-custom .tt-newsletter .form-default .form-group {
    margin-bottom: 0;
  }
}

@media (min-width: 790px) and (min-width: 1230px) {
  footer .tt-footer-custom .tt-newsletter .tt-collapse-title {
    margin-right: 106px;
  }

  footer .tt-footer-custom .tt-newsletter .tt-collapse-content .form-control {
    width: 507px;
  }
}

@media (min-width: 790px) and (max-width: 1229px) and (min-width: 1025px) {
  footer .tt-footer-custom .tt-newsletter .tt-collapse-title {
    margin-right: 50px;
  }

  footer .tt-footer-custom .tt-newsletter .tt-collapse-content .form-control {
    width: 340px;
  }
}

@media (min-width: 790px) and (max-width: 1024px) {
  footer .tt-footer-custom .tt-newsletter .tt-collapse-title {
    margin-right: 50px;
  }

  footer .tt-footer-custom .tt-newsletter .tt-collapse-content .form-control {
    width: auto;
  }
}

@media (min-width: 790px) {
  footer .tt-footer-center {
    padding-top: 54px;
    padding-bottom: 44px;
  }

  footer .tt-footer-center .tt-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }
}

@media (min-width: 790px) and (min-width: 1025px) {
  footer .tt-footer-center .tt-row {
    max-width: 587px;
  }
}

@media (min-width: 790px) and (max-width: 1229px) {
  footer .tt-footer-center .tt-row {
    max-width: 500px;
  }
}

@media (min-width: 790px) {
  footer .tt-footer-center .tt-row > *:nth-child(1) {
    margin-top: 0;
  }

  footer .tt-footer-center .tt-newsletter {
    padding-bottom: 4px;
  }

  footer .tt-footer-center .tt-newsletter .tt-mobile-collapse {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  footer .tt-footer-center .tt-newsletter .tt-collapse-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  footer .tt-footer-center .tt-newsletter .tt-collapse-content form {
    margin-top: 24px;
  }

  footer .tt-footer-center .tt-newsletter .tt-collapse-content .form-inline {
    width: 100%;
  }

  footer
    .tt-footer-center
    .tt-newsletter
    .tt-collapse-content
    .form-inline
    .form-group {
    width: 100%;
    flex-wrap: nowrap;
  }

  footer
    .tt-footer-center
    .tt-newsletter
    .tt-collapse-content
    .form-inline
    .form-group
    .form-control {
    width: 100%;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  footer
    .tt-footer-center
    .tt-newsletter
    .tt-collapse-content
    .form-inline
    .form-group
    .btn {
    -webkit-border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  footer
    .tt-footer-center
    .tt-newsletter
    .tt-collapse-content
    > *:nth-child(1) {
    margin-top: 0;
  }
}

footer .tt-footer-box .tt-logo img {
  max-height: 27px;
}

footer .tt-footer-box > *:not(address) {
  margin-top: 28px;
}

footer .tt-footer-box address.tt-address01 {
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  margin-top: 32px;
  color: #191919;
}

footer .tt-footer-box address.tt-address01 .tt-text-large {
  font-size: 24px;
  line-height: 31px;
  display: block;
}

footer .tt-footer-box address.tt-address01 .tt-text-large .tt-icon {
  position: relative;
  top: 4px;
}

footer .tt-footer-box address.tt-address02 {
  margin-top: 22px;
  font-size: 14px;
  line-height: 22px;
}

footer .tt-footer-box address.tt-address02 p {
  margin-top: 30px;
}

footer .tt-footer-box .tt-link-icon .tt-icon {
  position: relative;
  top: 3px;
  color: var(--yellow);
}

footer .tt-footer-box .tt-link-icon .tt-icon + .tt-text {
  margin-left: 10px;
}

footer .tt-footer-box .tt-link-icon .tt-text {
  color: var(--yellow);
  text-decoration: underline;
  transition: color 0.2s linear;
}

footer .tt-footer-box .tt-link-icon:hover .tt-text {
  color: #191919;
  text-decoration: none;
}

footer .tt-footer-box .tt-social-icon {
  margin-top: 32px;
}

footer .tt-footer-box .tt-social-icon li a {
  font-size: 1.7rem;
}

footer .tt-footer-box > :nth-child(1) {
  margin-top: 0;
}

@media (max-width: 789px) {
  footer .tt-footer-box {
    margin-top: 35px;
  }
}

footer .tt-footer-default {
  width: 100%;
}

@media (min-width: 790px) {
  footer .tt-footer-default {
    padding: 10px 0;
  }

  footer .tt-footer-default .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

  footer .tt-footer-default .tt-payment-list {
    margin-top: 8px;
    padding-bottom: 14px;
  }

  footer .tt-footer-default .tt-box-copyright {
    padding: 19px 0 7px;
  }

  footer .tt-footer-default .tt-logo-col + .tt-col-item .tt-box-copyright {
    padding-top: 19px;
    padding-bottom: 7px;
  }

  footer .tt-footer-default .tt-newsletter .tt-collapse-title {
    margin-right: 106px;
    white-space: nowrap;
    padding-top: 3px;
  }

  footer .tt-footer-default .tt-newsletter .tt-mobile-collapse {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  footer .tt-footer-default .tt-newsletter .tt-collapse-content {
    width: 100%;
  }

  footer .tt-footer-default .tt-newsletter .tt-collapse-content p {
    display: none;
  }

  footer .tt-footer-default .tt-newsletter .tt-collapse-content .form-group {
    width: 100%;
  }
}

@media (min-width: 790px) and (min-width: 790px) {
  footer .tt-footer-default .tt-newsletter .tt-collapse-title {
    padding-bottom: 0;
  }

  footer .tt-footer-default .tt-newsletter .form-default .form-group {
    margin-bottom: 0;
  }

  footer
    .tt-footer-default
    .tt-newsletter
    .form-default
    .form-group
    .form-control {
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  footer .tt-footer-default .tt-newsletter .form-default .form-group .btn {
    -webkit-border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

@media (min-width: 790px) and (min-width: 1230px) {
  footer .tt-footer-default .tt-newsletter .tt-collapse-title {
    margin-right: 106px;
  }
}

@media (min-width: 790px) and (max-width: 1229px) and (min-width: 1025px) {
  footer .tt-footer-default .tt-newsletter .tt-collapse-title {
    margin-right: 50px;
  }
}

@media (min-width: 790px) and (max-width: 1024px) {
  footer .tt-footer-default .tt-newsletter .tt-collapse-title {
    margin-right: 50px;
  }

  footer .tt-footer-default .tt-newsletter .tt-collapse-content .form-control {
    width: auto;
  }
}

@media (min-width: 790px) {
  footer .tt-footer-default .tt-newsletter .form-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
  }

  footer .tt-footer-default .tt-newsletter .form-group .form-control {
    flex: 2 1 auto;
  }

  footer .tt-footer-default .tt-social-icon {
    justify-content: flex-end;
  }
}

footer .form-default [class^="btn"] {
  padding-left: 20px;
  padding-right: 20px;
}

footer .tt-box-copyright {
  font-size: 14px;
  letter-spacing: 0.01em;
}

footer .tt-payment-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -6px;
}

footer .tt-payment-list li {
  margin-top: 6px;
}

footer .tt-payment-list li a {
  transition: opacity 0.2s linear;
  padding: 0 5px;
}

footer .tt-payment-list li a > [class^="icon"] {
  font-size: 17px;
}

footer .tt-payment-list li a:hover {
  opacity: 0.7;
  color: inherit;
}

footer .tt-payment-list li:first-child a {
  padding-left: 0;
}

footer .tt-payment-list li:last-child a {
  padding-right: 0;
}

footer .tt-mobile-collapse .tt-collapse-title {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.04em;
  font-weight: 500;
}

@media (min-width: 790px) {
  footer .tt-mobile-collapse .tt-collapse-title {
    padding-bottom: 13px;
  }
}

footer .tt-mobile-collapse .tt-collapse-content {
  display: block;
}

footer .tt-mobile-collapse .tt-collapse-content > *:nth-child(1) {
  margin-top: 0;
}

footer .tt-mobile-collapse .tt-collapse-content > .tt-list:nth-child(1) {
  margin-top: -1px;
}

footer .tt-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-top: -3px;
}

footer .tt-list li a {
  display: inline-block;
  padding: 3px 0 3px;
  transition: color 0.2s linear;
}

footer address {
  margin-right: 9px;
}

footer address p:first-child {
  margin-top: 0;
}

footer address p:not(:first-child) {
  margin-top: 6px;
}

footer address a {
  transition: color 0.2s linear;
}

footer .tt-newsletter form {
  position: relative;
}

footer .tt-newsletter form .errors:not(label) {
  position: absolute;
  top: 56px;
  left: 0;
}

footer .tt-newsletter form .errors:not(label) ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
  background: #f8353e;
}

footer .tt-newsletter form .errors:not(label) ul li {
  position: relative;
  padding: 6px 19px 8px 19px;
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
}

footer .tt-newsletter form .errors:not(label) ul li:before {
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  position: relative;
  top: 4px;
  margin-right: 7px;
  color: #ffffff;
}

footer .tt-newsletter form .errors:not(label):before {
  content: "";
  position: absolute;
  top: -12px;
  left: 50%;
  border: 6px solid transparent;
  border-bottom: 6px solid #f8353e;
}

footer .tt-newsletter form label.error {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
  position: absolute;
  top: 56px;
  left: 0;
  padding: 8px 19px 6px 43px;
  background: #f8353e;
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
  z-index: 1;
}

footer .tt-newsletter form label.error:before {
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  position: absolute;
  top: 7px;
  left: 19px;
  color: #ffffff;
}

footer .tt-newsletter form label.error:after {
  content: "";
  position: absolute;
  top: -12px;
  left: 50%;
  border: 6px solid transparent;
  border-bottom: 6px solid #f8353e;
}

footer .tt-top-border {
  border-top: 1px solid #e9e7e7;
}

@media (min-width: 790px) {
  footer .f-visible-mobile {
    display: none !important;
  }
}

@media (max-width: 789px) {
  footer .f-visible-desctope {
    display: none !important;
  }
}

@media (min-width: 790px) {
  footer .tt-color-scheme-01 {
    background: var(--main);
    color: #aaa !important;
  }

  footer .tt-color-scheme-01 .tt-collapse-title {
    color: white;
  }

  footer .tt-color-scheme-01 .tt-collapse-title a {
    color: white;
  }

  footer .tt-color-scheme-01 .tt-collapse-title a:hover {
    color: var(--yellow);
  }

  footer .tt-color-scheme-01 .tt-collapse-content {
    color: #aaa;
  }

  footer .tt-color-scheme-01 .tt-collapse-content a {
    color: var(--yellow);
  }

  footer .tt-color-scheme-01 .tt-collapse-content a:hover {
    color: #aaa;
  }

  footer .tt-color-scheme-01 .tt-list li a,
  footer
    .tt-color-scheme-01
    .tt-mobile-collapse
    .tt-collapse-content
    .tt-list
    li
    a {
    color: #aaa;
  }

  footer .tt-color-scheme-01 .tt-list li a:hover,
  footer
    .tt-color-scheme-01
    .tt-mobile-collapse
    .tt-collapse-content
    .tt-list
    li
    a:hover {
    color: var(--yellow);
  }

  footer .tt-color-scheme-01 address span {
    color: white;
  }

  footer
    .tt-color-scheme-01
    .tt-newsletter
    .form-control:not(:active):not(:focus) {
    color: #777777;
    background: #ffffff;
    border-color: #ffffff;
  }

  footer .tt-color-scheme-01 .tt-newsletter .form-control:focus {
    color: #777777;
    border-color: var(--yellow);
    background: #ffffff;
  }

  footer .tt-color-scheme-01 .tt-newsletter ::-webkit-input-placeholder {
    color: #777777;
  }

  footer .tt-color-scheme-01 .tt-newsletter ::-moz-placeholder {
    color: #777777;
  }

  footer .tt-color-scheme-01 .tt-newsletter :-moz-placeholder {
    color: #777777;
  }

  footer .tt-color-scheme-01 .tt-newsletter :-ms-input-placeholder {
    color: #777777;
  }

  footer .tt-color-scheme-01 .tt-newsletter .btn {
    background: #292929;
    color: white;
    border: 1px solid white;
  }

  footer .tt-color-scheme-01 .tt-newsletter .btn:hover {
    background: #000000;
    color: #ffffff;
  }

  footer .tt-color-scheme-01 .tt-social-icon li a {
    color: #d8d8d8;
  }

  footer .tt-color-scheme-01 .tt-social-icon li a:hover {
    color: #595959;
  }

  footer .tt-color-scheme-01 .tt-footer-box .tt-social-icon li a {
    color: #595959;
  }

  footer .tt-color-scheme-01 .tt-footer-box .tt-social-icon li a:hover {
    color: var(--yellow);
  }

  footer
    .tt-color-scheme-01
    .tt-footer-box
    .tt-social-icon
    li:not(:last-child):not(:first-child) {
    margin: 0px 9px;
  }
}

@media (max-width: 789px) {
  footer {
    background: var(--main);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }

  footer .tt-footer-col,
  footer .tt-footer-center {
    background: var(--main);
    color: white;
    padding: 0px;
    width: 100%;
  }

  footer .tt-footer-col .container,
  footer .tt-footer-center .container,
  footer .tt-footer-custom .container,
  footer .tt-footer-default .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  footer .tt-footer-custom:not(:last-child) {
    order: 3;
    width: 100%;
    background: #f7f8fa;
    color: #888888;
  }

  footer .tt-footer-custom:not(:last-child) .tt-row,
  footer .tt-footer-custom:not(:last-child) .tt-col-left,
  footer .tt-footer-custom:not(:last-child) .tt-col-right {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  footer .tt-footer-custom:not(:last-child) .tt-col-item {
    width: 100%;
  }

  footer .tt-footer-default:not(:last-child) {
    order: 3;
  }

  footer .tt-footer-custom:last-child {
    background: #242424;
    padding-bottom: 30px;
    order: 4;
    width: 100%;
  }

  footer .tt-footer-custom:last-child .tt-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  footer .tt-footer-custom:last-child .tt-row .tt-col-left {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  footer .tt-footer-custom:last-child .tt-row .tt-col-right {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  footer .tt-footer-custom:last-child .tt-box-copyright {
    margin-top: 16px;
    text-align: center;
  }

  footer .tt-footer-custom:last-child .tt-payment-list {
    margin-top: 17px;
    margin-bottom: -15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  footer .tt-footer-custom:last-child .tt-payment-list li a > [class^="icon"] {
    font-size: 17px;
  }

  footer .tt-footer-custom:last-child > *:nth-child(1) {
    margin-top: 0;
  }

  footer .tt-footer-center {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  footer .tt-collapse-title {
    cursor: pointer;
    color: white;
    font-weight: bold;
    padding-bottom: 12px;
    padding-right: 20px;
    transition: color 0.2s linear;
    width: 100%;

    outline: none;
  }

  footer .tt-mobile-collapse .tt-collapse-title:hover:not(:focus) {
    color: var(--yellow);
  }

  footer .tt-mobile-collapse .tt-collapse-title:before {
    position: absolute;
    right: 0;
    top: 1px;
    font-size: 20px;
    line-height: 1;
    color: #777777;
  }

  footer .tt-mobile-collapse .tt-collapse-title.tt-open:before {
  }

  footer .tt-mobile-collapse .tt-collapse-content {
    margin-top: 16px;
    display: none;
    color: white;
  }
  footer .tt-collapse-content span {
    color: white;
  }

  footer .tt-mobile-collapse .tt-collapse-title.tt-open + .tt-collapse-content {
    display: block;
  }

  footer .tt-mobile-collapse {
    margin-top: 35px;
  }

  footer .tt-newsletter form {
    margin-top: 24px;
  }

  footer .tt-newsletter form .form-group {
    width: 100%;
    margin-bottom: 0;
  }

  footer .tt-newsletter form .form-group .form-control {
    width: 100%;
  }

  footer .tt-newsletter form .btn {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    display: block;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  footer .tt-newsletter .form-control:not(:active):not(:focus) {
    color: #777777;
    background: #ffffff;
    border-color: #ffffff;
  }

  footer .tt-newsletter .form-control:focus {
    color: #777777;
    border-color: var(--yellow);
    background: #ffffff;
  }

  footer .tt-newsletter ::-webkit-input-placeholder {
    color: #777777;
  }

  footer .tt-newsletter ::-moz-placeholder {
    color: #777777;
  }

  footer .tt-newsletter :-moz-placeholder {
    color: #777777;
  }

  footer .tt-newsletter :-ms-input-placeholder {
    color: #777777;
  }

  footer .tt-newsletter .btn {
    background: white;
    color: #000;
  }

  footer .tt-newsletter .btn:hover {
    background: #000000;
    color: #ffffff;
  }

  footer .tt-list li a {
    color: #888888;
  }

  footer .tt-list li a:hover {
    color: var(--yellow);
  }

  footer address a {
    color: var(--yellow);
  }

  footer address a:hover {
    color: #191919;
  }

  footer address span {
    color: #191919;
  }

  footer .tt-social-icon {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin-top: 26px;
    margin-left: -7px;
  }

  footer .tt-social-icon li a {
    color: #777777;
  }

  footer .tt-social-icon li a:hover {
    color: #595959;
  }

  footer .tt-logo .tt-title {
    color: var(--yellow);
  }
}

footer > *:nth-child(1) {
  margin-top: 0;
}

@media (min-width: 790px) {
  html.ie
    footer
    .tt-footer-col
    .tt-newsletter
    .tt-collapse-content
    .form-inline
    .form-group
    .form-control {
    width: auto;
  }

  html.ie footer .tt-footer-center .tt-newsletter .tt-collapse-content {
    width: 590px;
  }

  html.ie footer .tt-footer-center .tt-newsletter .tt-collapse-content p {
    width: 590px;
  }

  html.ie
    footer
    .tt-footer-center
    .tt-newsletter
    .tt-collapse-content
    .form-inline {
    width: auto;
  }

  html.ie
    footer
    .tt-footer-center
    .tt-newsletter
    .tt-collapse-content
    .form-inline
    .form-group
    .form-control {
    width: auto;
    min-width: 370px;
  }
}

/* tt-form-search */
.tt-form-search {
  position: relative;
  margin-top: 30px;
  padding-bottom: 9px;
  background-color: #ffffff;
}

.tt-form-search .tt-btn-icon {
  background: none;
  border: none;
  color: #191919;
  font-size: 20px;
  line-height: 1;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 7;
  padding: 9px 7px;
  cursor: pointer;
  transition: color 0.2s linear, background-color 0.2s linear;
}

.tt-form-search .tt-btn-icon:hover {
  color: var(--yellow);
}

/* tt-aside-info */
.tt-aside-info {
  position: relative;
}

.tt-aside-info .tt-aside-img {
  margin-top: 6px;
  display: inline-block;
}

.tt-aside-info .tt-aside-img img {
  width: 100%;
  height: auto;
}

.tt-aside-info p {
  margin-top: 12px;
}

.tt-aside-info .btn-top {
  margin-top: 18px;
}

/*---------------------------------------*/
/*------   7. Product single pages   ----*/
/*---------------------------------------*/
@media (max-width: 575px) {
  .tt-page-product-single
    .tt-breadcrumb
    + #tt-pageContent
    > :nth-child(1):not([class^="tt-offset-"]):not(.nomargin) {
    margin-top: 0 !important;
  }
}

.tt-product-single-img img {
  max-width: 100%;
  height: auto;
}

.tt-product-single-info {
  padding-left: 39px;
  margin: -5px 0 0 0;
}

.tt-product-single-info img {
  max-width: 100%;
  height: auto;
}

.tt-product-single-info .tt-add-info ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.tt-product-single-info .tt-add-info ul li {
  color: #191919;
}

.tt-product-single-info .tt-add-info ul li span:first-child {
  letter-spacing: 0.02em;
  color: #191919;
}

.tt-product-single-info .tt-add-info ul li span {
  letter-spacing: 0.02em;
}

.tt-product-single-info .tt-add-info ul li a {
  color: #191919;
  transition: all 0.2s linear;
}

.tt-product-single-info .tt-add-info ul li a:hover {
  color: var(--yellow);
}

.tt-product-single-info .tt-add-info ul li:not(:first-child) {
  margin-top: 6px;
}

.tt-product-single-info .tt-add-info span:fist-child {
  color: #191919;
  display: inline-block;
  padding-right: 4px;
}

.tt-product-single-info .tt-title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  margin-top: 19px;
  color: #191919;
}

.tt-product-single-info .tt-price {
  font-size: 30px;
  line-height: 40px;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  color: var(--yellow);
}

.tt-product-single-info .tt-price span {
  display: inline-block;
}

.tt-product-single-info .tt-price .sale-price {
  color: #f8353e;
  margin-right: 7px;
}

.tt-product-single-info .tt-price .old-price,
.tt-product-single-info .tt-price .old-price .money {
  color: red;
  text-decoration: line-through;
}

.tt-product-single-info .tt-review {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
}

.tt-product-single-info .tt-review .tt-rating {
  margin-right: 8px;
  margin-top: 3px;
}

.tt-product-single-info .tt-review .tt-rating .icon-star:before {
  color: #ffb503;
}

.tt-product-single-info .tt-review .tt-rating .icon-star-half:before {
  color: #ffb503;
}

.tt-product-single-info .tt-review .tt-rating .icon-star-empty:before {
  color: #d4d4d4;
}

.tt-product-single-info .tt-review a {
  color: var(--yellow);
  display: inline-block;
  transition: all 0.2s linear;
}

.tt-product-single-info .tt-review a:hover {
  color: #191919;
}

.tt-product-single-info .tt-review a:not(:last-child) {
  margin-right: 10px;
}

.tt-product-single-info .tt-row-custom-01 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.tt-product-single-info .tt-row-custom-01 .col-item:not(:last-child) {
  margin-right: 20px;
}

.tt-product-single-info .tt-row-custom-01 .col-item:nth-child(2) {
  flex: 1 1 auto;
}

.tt-product-single-info .tt-row-custom-01 .btn {
  width: 100%;
}

.tt-product-single-info .tt-row-custom-01 .btn.btn-lg {
  font-size: 14px;
}

.tt-product-single-info .tt-row-custom-01 .btn.btn-lg i {
  font-size: 20px;
  position: relative;
  top: -3px;
}

.tt-product-single-info .tt-wrapper {
  margin-top: 33px;
}

.tt-product-single-info .tt-wrapper + .tt-title,
.tt-product-single-info .tt-wrapper + .tt-price,
.tt-product-single-info .tt-wrapper + .tt-review,
.tt-product-single-info .tt-wrapper + .tt-add-info,
.tt-product-single-info .tt-swatches-container + .tt-title,
.tt-product-single-info .tt-swatches-container + .tt-price,
.tt-product-single-info .tt-swatches-container + .tt-review,
.tt-product-single-info .tt-swatches-container + .tt-add-info {
  margin-top: 33px;
}

.tt-product-single-info .tt-swatches-container .tt-wrapper:not(:first-child) {
  margin-top: 12px;
}

.tt-product-single-info .tt-swatches-container form {
  margin-top: 7px;
}

.tt-product-single-info .tt-options-swatch {
  margin-top: -3px;
}

.tt-product-single-info .tt-review + .tt-wrapper {
  margin-top: 11px;
}

.tt-product-single-info .tt-collapse-block {
  margin-top: 3px;
}

.tt-product-single-info > *:nth-child(1) {
  margin-top: 0;
}

.tt-product-single-info .tt-list-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  list-style: none;
  padding: 0;
  margin: -10px 0 0 -20px;
}

.tt-product-single-info .tt-list-btn li {
  margin-left: 20px;
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .tt-product-single-info {
    margin: 0;
    padding-left: 0;
  }

  .tt-product-single-info .tt-title {
    font-size: 24px;
  }

  .tt-product-single-info .tt-price {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .tt-product-single-info {
    padding-top: 25px;
  }
}

@media (max-width: 575px) {
  .tt-product-single-info {
    padding-top: 34px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .tt-product-single-info .tt-title {
    margin-top: 14px;
    font-size: 20px;
    line-height: 30px;
  }

  .tt-product-single-info .tt-row-custom-01 {
    flex-direction: column;
  }

  .tt-product-single-info .tt-row-custom-01 .col-item {
    width: 100%;
  }

  .tt-product-single-info
    .tt-row-custom-01
    .col-item
    .tt-input-counter.style-01 {
    max-width: 100%;
  }

  .tt-product-single-info .tt-row-custom-01 .col-item:not(:first-child) {
    margin-top: 31px;
  }
}

/* tt-rating */
.tt-rating {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 16px;
}

.tt-rating .icon-star:before {
  display: inline-block;
  color: #ffb503;
}

.tt-rating .icon-star-half:before {
  display: inline-block;
  color: #ffb503;
}

.tt-rating .icon-star-empty:before {
  display: inline-block;
  color: #d4d4d4;
}

.tt-product-single-img,
.product-images-col .item,
.product-images-static li,
.tt-mobile-product-slider .slick-slide,
.tt-mobile-product-layout {
  position: relative;
}
.tt-mobile-product-slider {
  height: 100%;
}
.tt-mobile-product-slider .img-container {
  height: 100%;
}
.tt-mobile-product-slider img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.tt-label-new {
  background: #1393f5;
  color: #ffffff;
}

.tt-label-sale {
  background: #f8353e;
  color: #ffffff;
}

.tt-label-out-stock {
  background: #191919;
  color: #ffffff;
}

.tt-label-our-fatured {
  background: #fdbc20;
  color: #ffffff;
}

.tt-product-single-info .tt-label {
  margin-left: -10px;
  margin-top: -10px;
}

.tt-product-single-info .tt-label [class^="tt-label"] {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  padding: 4px 10px 2px;
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 6px;
}

.tt-product-vertical-layout .tt-product-single-img {
  float: right;
  width: 82.7%;
  position: relative;
}

.tt-product-vertical-layout .tt-product-single-img img {
  width: 100%;
  height: auto;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical {
  margin: 0 0 44px 0;
  top: 0px;
  left: 0;
  width: 13.9%;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-slider {
  width: 100%;
  padding: 0;
  margin: 0;
}

.tt-product-vertical-layout
  .tt-product-single-carousel-vertical
  .slick-slider
  .slick-slide {
  padding-bottom: 20px;
}

.tt-product-vertical-layout
  .tt-product-single-carousel-vertical
  .slick-slider
  .slick-slide
  img {
  opacity: 1;
}

.tt-product-vertical-layout
  .tt-product-single-carousel-vertical
  .slick-slider
  .slick-slide
  a {
  display: block;
  position: relative;
}

.tt-product-vertical-layout
  .tt-product-single-carousel-vertical
  .slick-slider
  .slick-slide
  a:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.tt-product-vertical-layout
  .tt-product-single-carousel-vertical
  .slick-slider
  .slick-slide:hover
  a:before,
.tt-product-vertical-layout
  .tt-product-single-carousel-vertical
  .slick-slider
  .slick-slide
  .zoomGalleryActive:before {
  opacity: 0.6;
}

.tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-arrow {
  top: auto;
  bottom: -33px;
  position: absolute;
  margin-top: 0;
  z-index: 2;
}

.tt-product-vertical-layout
  .tt-product-single-carousel-vertical
  .slick-slider:before {
  content: "";
  display: block;
  width: 100%;
  height: 14px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(to bottom, #ffffff, white 20%);
}

/* product-images-carousel */
.product-images-carousel {
  margin-top: 20px;
  padding-bottom: 57px;
}

.product-images-carousel ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.product-images-carousel ul .slick-list {
  margin-right: -25px;
}

.product-images-carousel ul li {
  padding-right: 25px;
}

.product-images-carousel > *:nth-child(1) {
  margin-top: 0;
}

.product-images-carousel > *:nth-child(2) {
  margin-top: 20px;
}

.product-images-carousel .slick-slider .slick-slide a {
  display: block;
  position: relative;
}

.product-images-carousel .slick-slider .slick-slide a:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.product-images-carousel .slick-slider .slick-slide:hover a:before,
.product-images-carousel .slick-slider .slick-slide .zoomGalleryActive:before {
  opacity: 0.6;
}

/* product-col-image (product-03.html) */
.product-images-static ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.product-images-static ul li:not(:first-child) {
  margin-top: 20px;
}

.product-images-static ul li img {
  width: 100%;
  height: auto;
}

/* product-images-col (product-04.html)*/
.product-images-col {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-top: -20px;
}

.product-images-col .item {
  width: 50%;
  padding: 20px 0 0 20px;
}

.product-images-col .item img {
  width: 100%;
  height: auto; 
}

.product-images-col .item .tt-label-left {
  top: 30px;
  left: 30px;
}

/* video-link-product (product.html)*/
.video-link-product {
  position: relative;
  cursor: pointer;
}

.video-link-product [class^="icon-"] {
  color: var(--yellow);
  font-size: 30px;
  line-height: 30px;
  transition: all 0.2s linear;
}

.video-link-product [class^="icon-"]:hover {
  color: #191919;
}

.video-link-product:not(.tt-position-left) {
  position: relative;
  cursor: pointer;
}

.video-link-product:not(.tt-position-left) > div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: all 0.2s linear;
}

.video-link-product.tt-position-left {
  display: inline-block;
}

/* tt-promo-brand */
.tt-promo-brand {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
}

.tt-promo-brand img {
  max-width: 100%;
  height: auto;
}

.tt-title-options {
  font-size: 14px;
  line-height: 26px;
  color: #191919;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  letter-spacing: 0.03em;
}

.tt-options-swatch:not(.options-large):not(.options-middle)
  li
  a:not(.options-color) {
  font-size: 12px;
  line-height: 16px;
}

.tt-options-swatch:not(.options-large):not(.options-middle) li a {
  min-width: 21px;
  min-height: 21px;
}

.tt-options-swatch:not(.options-large):not(.options-middle)
  li
  a:not(.options-color) {
  border-radius: 2px;
}

.tt-options-swatch:not(.options-large):not(.options-middle) li a.options-color {
  width: 21px;
  height: 21px;
  background-size: cover;
}

.tt-options-swatch:not(.options-large):not(.options-middle)
  li
  .options-color:hover:before {
  border-width: 4px;
}

.tt-options-swatch:not(.options-large):not(.options-middle)
  li
  .options-color:hover:after {
  border-width: 2px;
}

.tt-options-swatch:not(.options-large):not(.options-middle)
  li.active
  .options-color:before {
  border-width: 4px;
}

.tt-options-swatch:not(.options-large):not(.options-middle)
  li.active
  .options-color:after {
  border-width: 2px;
}

.tt-options-swatch.options-large li a {
  min-width: 40px;
  min-height: 40px;
}

.tt-options-swatch.options-large li a:not(.options-color) {
  border-radius: 6px;
}

.tt-options-swatch.options-large li a.options-color {
  width: 40px;
  height: 40px;
  background-size: cover;
}

.tt-options-swatch.options-large li .options-color:hover:before {
  border-width: 5px;
}

.tt-options-swatch.options-large li.active .options-color:before {
  border-width: 5px;
}

.tt-options-swatch.options-middle li a {
  min-width: 31px;
  min-height: 31px;
}

.tt-options-swatch.options-middle li a:not(.options-color) {
  border-radius: 6px;
}

.tt-options-swatch.options-middle li a.options-color {
  width: 31px;
  height: 31px;
  background-size: cover;
}

.tt-options-swatch.options-middle li .options-color:hover:before {
  border-width: 5px;
}

.tt-options-swatch.options-middle li.active .options-color:before {
  border-width: 5px;
}

html.win.ie .tt-options-swatch:not(.options-large):not(.options-middle) li a {
  line-height: 21px;
}

html.win.ie .tt-options-swatch.options-large li a {
  line-height: 40px;
}

html.win.ie .tt-options-swatch.options-middle li a {
  line-height: 30px;
}

.product-information-buttons a {
  display: inline-flex;
  color: var(--yellow);
  font-size: 14px;
  transition: color 0.2s linear;
}

.product-information-buttons a:first-child {
  padding: 8px 7px 8px 0;
}

.product-information-buttons a:not(:first-child) {
  padding: 8px 7px;
}

.product-information-buttons a:hover {
  color: #191919;
}

ul.tt-options-swatch {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: -10px -1px 0 -5px;
}

ul.tt-options-swatch li {
  margin: 10px 5px 0;
}

ul.tt-options-swatch li a {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  transition: all 0.2s linear;
  font-size: 14px;
  line-height: 1.1;
}

ul.tt-options-swatch li a.options-color-img {
  border-radius: 6px;
}

ul.tt-options-swatch li a .swatch-img img {
  border-radius: 50%;
}

ul.tt-options-swatch li a:not(.options-color):not(.options-color-img) {
  padding-left: 5px;
  padding-right: 5px;
}

ul.tt-options-swatch li img {
  width: 100%;
  height: auto;
  max-width: inherit;
}

ul.tt-options-swatch li a:not(.options-color):not(.options-color-img) {
  background: #3a3a3a;
  color: white;
}

ul.tt-options-swatch li:hover a:not(.options-color):not(.options-color-img),
ul.tt-options-swatch li.active a:not(.options-color):not(.options-color-img) {
  background: var(--main);
  color: #ffffff;
}

ul.tt-options-swatch li .options-color {
  font-size: 0;
  line-height: 0;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

ul.tt-options-swatch li .tt-border {
  border: 2px solid #e9e7e6;
}

ul.tt-options-swatch li .options-color:before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 50%;
  transition: all 0.12s linear;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

ul.tt-options-swatch li .options-color:after {
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  outline: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 50%;
  transition: all 0.12s linear;
}

ul.tt-options-swatch li .options-color:before {
  border: 0px solid #ffffff;
}

ul.tt-options-swatch li .options-color:after {
  border: 0px solid var(--yellow);
}

ul.tt-options-swatch li .options-color:hover:before {
  border-width: 6px;
}

ul.tt-options-swatch li .options-color:hover:after {
  border-width: 3px;
}

ul.tt-options-swatch li .tt-border.options-color:hover {
  border-color: transparent;
}

ul.tt-options-swatch li.active .options-color:before {
  border-width: 6px;
}

ul.tt-options-swatch li.active .options-color:after {
  border-width: 3px;
}

ul.tt-options-swatch li {
  line-height: 0;
  font-size: 0;
}

ul.tt-options-swatch li .options-color-img {
  width: 40px;
  height: 40px;
  padding: 0 !important;
  display: inline-block;
  background-position: center top !important;
  border-radius: 6px !important;
  background-size: cover !important;
}

ul.tt-options-swatch li .options-color-img:after {
  content: "";
  display: none;
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  outline: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 6px;
  border: 0px solid var(--yellow);
  transition: border-width 0.1s linear;
}

ul.tt-options-swatch li .options-color-img img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

ul.tt-options-swatch li .options-color-img:hover:after {
  border-width: 2px;
}

ul.tt-options-swatch li.active .options-color-img:after {
  border-width: 2px;
}

/* product-03.html - deactivate airSticky*/
@media (max-width: 1024px) {
  .airSticky {
    position: relative !important;
    top: 0 !important;
  }
}

@media (max-width: 575px) {
  .zoomContainer {
    display: none;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
  }
}

/* countdown single product */
.tt-product-single-info > *:nth-child(1).tt-wrapper {
  margin-top: 5px;
}

.tt-countdown_box_02 {
  text-align: center;
  margin-top: -7px;
  width: 100%;
  z-index: 5;
}

.tt-countdown_box_02 .tt-countdown_inner {
  overflow: hidden;
  width: 100%;
}

.tt-countdown_box_02 .countdown-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.tt-countdown_box_02 .countdown-row .countdown-section {
  width: 100%;
  max-width: 65px;
  position: relative;
  margin: 0 5px;
  padding: 19px 0 13px;
  white-space: nowrap;
  background-color: #f7f8fa;
  color: #191919;
  border-radius: 6px;
}

.tt-countdown_box_02 .countdown-row .countdown-section .countdown-amount {
  font-size: 16px;
  line-height: 15px;
  display: block;
  font-weight: 500;
}

.tt-countdown_box_02 .countdown-row .countdown-section .countdown-period {
  display: block;
  padding-top: 1px;
}

.tt-countdown_box_02 .countdown-row .countdown-section:first-child {
  margin-left: 0;
}

.tt-countdown_box_02 .countdown-row .countdown-section:last-child {
  margin-right: 0;
}

@media (max-width: 1229px) {
  .tt-countdown_box_02 .countdown-row .countdown-section {
    padding: 15px 0;
    max-width: 60px;
    margin: 0 3px;
    font-size: 13px;
    line-height: 16px;
  }

  .tt-countdown_box_02 .countdown-row .countdown-section .countdown-amount {
    font-size: 15px;
  }

  .tt-countdown_box_02 .countdown-row .countdown-section .countdown-period {
    padding-top: 0;
  }
}

/* zoom icon */
.tt-product-single-img .tt-btn-zomm {
  width: 38px;
  height: 38px;
  background: #ffffff;
  color: #191919;
  font-size: 20px;
  line-height: 2.1;
  border: none;
  outline: none;
  z-index: 2;
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.tt-product-single-img .tt-btn-zomm.tt-top-right {
  right: 10px;
  top: 10px;
}

.tt-product-single-img .tt-btn-zomm.tt-top-left {
  left: 10px;
  top: 10px;
}

.tt-product-single-img .tt-btn-zomm.tt-bottom-right {
  right: 10px;
  bottom: 10px;
}

.tt-product-single-img .tt-btn-zomm.tt-bottom-left {
  left: 10px;
  bottom: 10px;
}

.tt-product-single-img .tt-btn-zomm:hover {
  background: var(--yellow);
  color: #ffffff;
}

.zoomContainer {
  z-index: 1;
}

#smallGallery.tt-magnific-popup li a:not([class]) {
  pointer-events: none;
}

#smallGallery.tt-magnific-popup li .link-magnific-popup {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
}

@media (min-width: 1025px) {
  .custom-single-page .tt-product-single-info {
    padding-left: 19px;
    padding-right: 19px;
  }

  .custom-single-page .tt-slick-button-vertical .slick-arrow {
    width: 28px;
    height: 28px;
  }

  .custom-single-page .tt-slick-button-vertical .slick-arrow:before {
    font-size: 15px;
  }

  .custom-single-page .tt-slick-button-vertical .slick-next {
    left: 32px;
  }

  .custom-single-page
    .tt-product-vertical-layout
    .tt-product-single-carousel-vertical
    .slick-arrow {
    bottom: -21px;
  }
}

@media (min-width: 1230px) {
  .custom-single-page
    .tt-product-vertical-layout
    .tt-product-single-carousel-vertical
    .slick-slider
    .slick-slide {
    padding-bottom: 17px;
  }
}

@media (max-width: 1229px) and (min-width: 1025px) {
  .custom-single-page
    .tt-product-vertical-layout
    .tt-product-single-carousel-vertical
    .slick-slider
    .slick-slide {
    padding-bottom: 16px;
  }
}

.tt-product-single-aside .tt-promo-brand {
  text-align: center;
}

.tt-product-single-aside .tt-promo-brand img {
  max-width: 484px;
  width: 100%;
}

@media (max-width: 1024px) {
  .tt-product-single-aside {
    margin-top: 33px;
  }
}

@media (max-width: 1024px) {
  .container-fluid-mobile {
    width: 100%;
    max-width: inherit;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .container-fluid-mobile .row:first-child > *[class^="col-"] {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (max-width: 1024px) {
  .container-mobile-airSticky {
    width: 100%;
    max-width: inherit;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 575px) {
  .container-mobile-airSticky .row:first-child *[class^="col-"] {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (max-width: 767px) {
  .container-mobile-airSticky .tt-product-single-info {
    padding-top: 0;
  }

  .container-mobile-airSticky .tt-row-custom-01 {
    flex-wrap: wrap;
  }

  .container-mobile-airSticky .tt-row-custom-01 .col-item {
    width: 100%;
  }

  .container-mobile-airSticky
    .tt-row-custom-01
    .col-item
    .tt-input-counter.style-01 {
    max-width: 100%;
  }

  .container-mobile-airSticky .tt-row-custom-01 .col-item:not(:last-child) {
    margin-right: 0;
  }

  .container-mobile-airSticky .tt-row-custom-01 .col-item:not(:first-child) {
    margin-top: 31px;
  }
}

@media (max-width: 575px) {
  .container-mobile-airSticky {
    padding-top: 25px;
  }
}

#include-carusel-prod_single .tt-carousel-products + .tt-carousel-products {
  display: none;
}

/*---------------------------------------*/
/*--------      8. Modal         --------*/
/*---------------------------------------*/
/* open modal mobile menu */
body:not(.touch-device).mm-open {
  padding-right: 17px;
}

body:not(.touch-device).mm-open .tt-stuck-nav.stuck {
  padding-right: 17px;
}

/* open modal bootstrap */
.modal-open {
  overflow: hidden;
}

body:not(.touch-device).modal-open {
  overflow-x: hidden;
  padding-right: 17px;
}

body:not(.touch-device).modal-open .tt-stuck-nav.stuck {
  padding-right: 17px;
}

html.ie.gecko body.modal-open {
  padding-right: 0px !important;
}

/* open left col (*listing) */
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  -webkit-overflow-scrolling: touch;
  outline: none;
  text-align: center;
  padding: 0;
  transition: opacity 0.15s linear;
  opacity: 0;
}

.modal.show {
  opacity: 1;
}

@media (min-width: 320px) {
  .quick-modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
}

.modal-md {
  width: 736px;
}

.modal-lg {
  width: 962px;
}

@media (max-width: 1229px) {
  .modal-lg {
    width: 900px;
  }
}

@media (max-width: 1024px) {
  .modal-lg {
    width: 730px;
  }
}

@media (max-width: 789px) {
  .modal-lg {
    width: 550px;
  }
}

@media (max-width: 650px) {
  .modal-lg {
    width: 420px;
  }
}

@media (max-width: 500px) {
  .modal-lg {
    width: 320px;
  }
}

@media (max-width: 370px) {
  .modal-lg {
    width: 280px;
  }
}

.modal-compare {
  width: 996px;
}

@media (max-width: 1229px) {
  .modal-compare {
    width: 900px;
  }
}

@media (max-width: 1024px) {
  .modal-compare {
    width: 730px;
  }
}

@media (max-width: 789px) {
  .modal-compare {
    width: 550px;
  }
}

@media (max-width: 650px) {
  .modal-compare {
    width: 420px;
  }
}

@media (max-width: 500px) {
  .modal-compare {
    width: 320px;
  }
}

@media (max-width: 370px) {
  .modal-compare {
    width: 280px;
  }
}

@media (min-width: 651px) {
  .modal-sm {
    width: 506px;
  }
}

@media (max-width: 650px) {
  .modal-sm {
    width: 420px;
  }
}

@media (max-width: 500px) {
  .modal-sm {
    width: 320px;
  }
}

@media (max-width: 370px) {
  .modal-sm {
    width: 280px;
  }
}

@media (min-width: 651px) {
  .modal-discount {
    width: 600px;
  }
}

@media (max-width: 650px) {
  .modal-discount {
    width: 420px;
  }
}

@media (max-width: 500px) {
  .modal-discount {
    width: 320px;
  }
}

@media (max-width: 370px) {
  .modal-discount {
    width: 280px;
  }
}

@media (min-width: 851px) {
  .modal-newslettet {
    width: 800px;
  }
}

@media (max-width: 850px) {
  .modal-newslettet {
    width: 420px;
  }
}

@media (max-width: 500px) {
  .modal-newslettet {
    width: 320px;
  }
}

@media (max-width: 370px) {
  .modal-newslettet {
    width: 280px;
  }
}

.modal-small {
  width: 400px;
}

@media (max-width: 450px) {
  .modal-small {
    width: 280px;
  }
}

.modal-backdrop.in {
  opacity: 1;
}

.modal-newsletter .modal-backdrop.show {
  opacity: 0.87;
}

/* modal layout */
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  -webkit-overflow-scrolling: touch;
  outline: none;
}

.quick-modal .modal-content {
  border-radius: 0;
  border: none;
}

.quick-modal .modal-header {
  border: none;
  padding: 22px 20px 0px 20px;
  margin: 0;
}

.quick-modal .modal-header .close {
  font-size: 24px;
  line-height: 24px;
  position: relative;
  z-index: 7;
  opacity: 1;
  transition: all 0.2s linear;
}

.quick-modal .modal-title {
  font-size: 20px;
  line-height: 26px;
  margin: 34px 0 0 0;
  padding-bottom: 19px;
  letter-spacing: 0.02em;
}

.quick-modal .modal-footer {
  border: none;
}

/* tt-modal-addtocart */
#modalAddToCartProduct .modal-dialog {
  width: 736px;
}

@media (max-width: 1024px) {
  #modalAddToCartProduct .modal-dialog {
    width: 420px;
  }
}

@media (max-width: 575px) {
  #modalAddToCartProduct .modal-dialog {
    width: 270px;
  }
}

.tt-modal-addtocart.desctope {
  position: relative;
}

.tt-modal-addtocart.desctope .row {
  justify-content: center;
  align-content: center;
  align-items: center;
}

.tt-modal-addtocart.desctope .row > div:first-child {
  padding-right: 52px;
}

.tt-modal-addtocart.desctope .row > div:last-child {
  padding-left: 49px;
}

.tt-modal-addtocart.desctope .row > div:last-child .btn {
  width: 100%;
}

.tt-modal-addtocart.desctope .tt-modal-messages {
  font-family: "Chakra Petch", sans-serif;
  color: #191919;
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
}

.tt-modal-addtocart.desctope .tt-modal-messages [class^="icon-"] {
  color: var(--yellow);
}

.tt-modal-addtocart.desctope .tt-modal-product {
  margin-top: 26px;
}

.tt-modal-addtocart.desctope .tt-modal-product .tt-img img {
  width: 100%;
  max-width: 154px;
  height: auto;
}

.tt-modal-addtocart.desctope .tt-modal-product .tt-title {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 12px;
  color: #191919;
}

.tt-modal-addtocart.desctope .tt-modal-product .tt-title a {
  color: #191919;
}

.tt-modal-addtocart.desctope .tt-modal-product .tt-title a:hover {
  color: var(--yellow);
}

.tt-modal-addtocart.desctope .tt-modal-product .tt-qty {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin-top: 7px;
  color: #191919;
}

.tt-modal-addtocart.desctope .tt-modal-product > *:nth-child(1) {
  margin-top: 0;
}

.tt-modal-addtocart.desctope .tt-product-total {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  color: #191919;
  font-size: 14px;
  margin-top: 5px;
}

.tt-modal-addtocart.desctope .tt-product-total .tt-price {
  font-size: 16px;
}

.tt-modal-addtocart.desctope .tt-cart-total {
  color: #777777;
  display: inline-block;
}

.tt-modal-addtocart.desctope .tt-cart-total .tt-total {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  color: #191919;
  margin-top: 11px;
}

.tt-modal-addtocart.desctope .tt-cart-total .tt-total .tt-price {
  color: var(--yellow);
  font-size: 24px;
}

.tt-modal-addtocart.desctope [class^="col"] .btn {
  margin-top: 20px;
}

.tt-modal-addtocart.desctope [class^="col"] .tt-cart-total + .btn {
  margin-top: 25px;
}

.tt-modal-addtocart.desctope [class^="col"] > *:nth-child(1) {
  margin-top: 0;
}

.tt-modal-addtocart.desctope:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #e9e7e7;
  position: absolute;
  left: 50%;
  margin-left: 8px;
  top: 0px;
}

.tt-modal-addtocart.mobile {
  margin-top: 9px;
}

.tt-modal-addtocart.mobile .tt-modal-messages {
  font-family: "Chakra Petch", sans-serif;
  color: #191919;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  padding-bottom: 13px;
}

.tt-modal-addtocart.mobile .tt-modal-messages [class^="icon-"] {
  color: var(--yellow);
}

.tt-modal-addtocart.mobile [class^="btn"] {
  padding-top: 9px;
  padding-bottom: 9px;
}

@media (min-width: 1025px) {
  .tt-modal-addtocart.desctope {
    display: block;
  }

  .tt-modal-addtocart.mobile {
    display: none;
  }
}

@media (max-width: 1024px) {
  .tt-modal-addtocart.desctope {
    display: none;
  }

  .tt-modal-addtocart.mobile {
    display: flex;
    flex-direction: column;
  }
}

/* tt-modal-addtocart */
.tt-modal-quickview {
  text-align: left;
}

@media (max-width: 1024px) and (min-width: 791px) {
  .tt-modal-quickview .tt-product-single-info {
    padding-top: 0;
  }
}

@media (max-width: 575px) {
  .tt-modal-quickview .tt-product-single-info {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.tt-modal-quickview .tt-product-single-info .tt-title {
  font-weight: 500;
}

/*
	modal custom
*/
.modal {
  text-align: center;
  display: none;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  -webkit-overflow-scrolling: touch;
  outline: none;
}

.quick-modal .modal-dialog {
  display: inline-block;
  vertical-align: middle;
}
.ltr-style {
  left: 50%;
  transform: translateX(-50%);
}
.rtl-style {
  right: 50% !important;
  transform: translateX(50%) !important;
}

@media (min-width: 576px) {
  .quick-modal .modal-dialog {
    max-width: inherit;
  }
}

.quick-modal .modal-header {
  height: 54px;
  position: relative;
}

.quick-modal .modal-header .close {
  width: 34px;
  height: 34px;
  top: -10px;
  line-height: 34px;
  position: absolute;
  text-decoration: none;
  text-align: center;
  color: white;
  background: black;
  border: none;
  cursor: pointer;

  transition: all 0.2s linear;
}

@media (min-width: 1025px) {
  .quick-modal .modal-header .close {
    right: -12px;
  }
}

@media (max-width: 1024px) {
  .quick-modal .modal-header .close {
    right: -20px;
  }
}

.quick-modal .modal-header .close:before {
  font-size: 24px;
  line-height: 1;
}

.quick-modal .modal-header .close:hover {
  color: var(--yellow);
}

.quick-modal .modal-body:not(.no-background) {
  background-color: #ffffff;
  border-radius: 6px;
}

.quick-modal .modal-body {
  overflow: hidden;
}

@media (min-width: 1025px) {
  .quick-modal .modal-body {
    padding: 40px;
  }
}

@media (max-width: 1024px) {
  .quick-modal .modal-body {
    padding: 30px;
  }
}

@media (max-width: 789px) {
  .quick-modal .modal-body {
    padding: 25px;
  }
}

@media (max-width: 500px) {
  .quick-modal .modal-body {
    padding: 20px;
  }
}

@media (max-width: 270px) {
  .quick-modal .modal-body {
    padding: 15px;
  }
}

@media (min-width: 320px) {
  .quick-modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
}

.modal-backdrop {
  background: #000000;
}

.modal-backdrop.show {
  opacity: 0.5;
}

/* modal-video */
.modal-video {
  width: 1055px;
}

@media (max-width: 1170px) {
  .modal-video {
    width: 604px;
  }
}

@media (max-width: 667px) {
  .modal-video {
    width: 461px;
  }
}

@media (max-width: 507px) {
  .modal-video {
    width: 277px;
  }
}

.modal-video-content {
  font-size: 0;
  line-height: 0;
  display: flex;
}

.modal-video-content iframe {
  border: none;
  width: 100%;
  height: 584px;
  max-height: 80vh;
}

@media (max-width: 1100px) {
  .modal-video-content iframe {
    height: 315px;
  }
}

@media (max-width: 667px) {
  .modal-video-content iframe {
    height: 239px;
  }
}

@media (max-width: 507px) {
  .modal-video-content iframe {
    height: 134px;
  }
}

.tt-layout-product-info .tt-title {
  color: #191919;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 6px;
}

.tt-layout-product-info .tt-table-modal-info {
  width: 100%;
  margin-top: 17px;
}

.tt-layout-product-info .tt-table-modal-info thead th {
  color: #777777;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  padding: 2px 4px;
}

.tt-layout-product-info .tt-table-modal-info tbody tr:nth-child(odd) {
  background: #f7f8fa;
}

.tt-layout-product-info .tt-table-modal-info tbody td {
  color: #777777;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  padding: 4px 4px;
}

@media (max-width: 789px) {
  .tt-table-responsive-md .tt-table-modal-info {
    width: 700px;
  }
}

@media (min-width: 1025px) {
  #modalProductInfo-02 .modal-body {
    padding: 36px 40px 32px 36px;
  }
}

.tt-layout-product-info-02 {
  text-align: left;
}

.tt-layout-product-info-02 .tt-title {
  color: #191919;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 6px;
  letter-spacing: 0.04em;
}

.tt-layout-product-info-02 .tt-title:not(:first-child) {
  margin-top: 19px;
}

.tt-layout-product-info-02 p {
  margin-top: 5px;
}

.tt-layout-product-info-02 .tt-title + p {
  margin-top: 0;
}

.tt-layout-product-info-02 ul:not([class]) {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tt-layout-product-info-02 ul:not([class]) > li {
  font-size: 14px;
  position: relative;
  padding: 0 0 0 11px;
  color: #777777;
}

.tt-layout-product-info-02 ul:not([class]) > li a {
  color: #777777;
  transition: color 0.2s linear;
  text-decoration: none;
}

.tt-layout-product-info-02 ul:not([class]) > li a:hover {
  color: var(--yellow);
}

.tt-layout-product-info-02 ul:not([class]) > li:before {
  content: "";
  width: 3px;
  height: 3px;
  background: #777777;
  display: inline-block;
  position: absolute;
  top: 8px;
  left: 1px;
  border-radius: 50%;
}

.tt-layout-product-info-02 ul:not([class]) + ul {
  margin-top: 5px;
}

.modal-xs {
  width: 280px;
}

.modal-xs .modal-header .close {
  right: -42px;
  top: 10px;
}

.modal-xs .modal-body {
  padding: 10px 10px 23px !important;
}

.tt-modal-subsribe-good {
  color: #191919;
  font-size: 14px;
  font-weight: 500;
}

.tt-modal-subsribe-good i {
  font-size: 30px;
  color: var(--yellow);
  position: relative;
  top: 5px;
  margin-right: 1px;
}

#ModalVerifyAge .modal-header {
  height: 0;
}

#ModalVerifyAge .modal-header .close {
  top: 22px;
  right: 0;
}

#ModalVerifyAge .modal-header .close:not(:hover) {
  color: #999999;
}

#ModalVerifyAge .modal-header .close:before {
  font-size: 14px;
}

#ModalVerifyAge .tt-modal-verifyage p {
  color: #191919;
  font-size: 16px;
  font-weight: 500;
}

#ModalVerifyAge .tt-modal-verifyage .tt-title {
  color: var(--yellow);
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
}

#ModalVerifyAge .tt-modal-verifyage .row-btn {
  margin-top: 10px;
}

#ModalVerifyAge .tt-modal-verifyage .row-btn [class^="btn"] {
  margin-top: 10px;
  width: 100px;
  height: 35px;
}

#ModalVerifyAge .tt-modal-verifyage .row-btn [class^="btn"]:first-child {
  background-color: #0ec482;
}

#ModalVerifyAge .tt-modal-verifyage .row-btn [class^="btn"]:first-child:hover {
  background-color: #191919;
}

#ModalVerifyAge .tt-modal-verifyage .row-btn [class^="btn"]:not(:first-child) {
  margin-left: 10px;
  background-color: #f8353e;
}

#ModalVerifyAge
  .tt-modal-verifyage
  .row-btn
  [class^="btn"]:not(:first-child):hover {
  background-color: #191919;
}

@media (max-width: 650px) {
  #ModalVerifyAge .tt-modal-verifyage .tt-title {
    font-size: 24px;
  }

  #ModalVerifyAge .tt-modal-verifyage .row-btn {
    margin-top: 5px;
  }
}

#ModalMessage .modal-header {
  height: 0;
}

#ModalMessage .modal-header .close {
  top: 23px;
  right: 2px;
  width: 27px;
  height: 27px;
  line-height: 1em;
}

#ModalMessage .modal-header .close:not(:hover) {
  color: #999999;
}

#ModalMessage .modal-header .close:before {
  font-size: 14px;
  line-height: 1;
  position: relative;
  top: -3px;
}

@media (min-width: 515px) {
  #ModalMessage .modal-dialog {
    width: 452px;
  }
}

@media (max-width: 514px) {
  #ModalMessage .modal-dialog {
    width: 370px;
  }
}

@media (max-width: 417px) {
  #ModalMessage .modal-dialog {
    width: 320px;
  }
}

@media (max-width: 370px) {
  #ModalMessage .modal-dialog {
    width: 280px;
  }
}

.tt-modal-wishlist p {
  color: #191919;
  font-size: 16px;
  font-weight: 500;
}

.tt-modal-wishlist .row-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  align-items: flex-start;
}

@media (min-width: 418px) {
  .tt-modal-wishlist .row-btn {
    margin-left: -15px;
  }

  .tt-modal-wishlist .row-btn [class^="btn"] {
    margin-top: 15px;
    margin-left: 15px;
  }
}

@media (max-width: 417px) {
  .tt-modal-wishlist .row-btn {
    flex-direction: column;
  }

  .tt-modal-wishlist .row-btn [class^="btn"] {
    margin-top: 10px;
    width: 100%;
  }
}

#ModalDiscount .tt-background {
  /* background: #ffffff url("../images/custom/bg-discount.png") 0 0 no-repeat; */
  background-size: 50% auto;
}

#ModalDiscount .modal-body {
  padding: 52px 0;
}

@media (max-width: 650px) {
  #ModalDiscount .modal-body:not(.tt-layout-center) {
    background: #ffffff;
  }

  #ModalDiscount .modal-body:not(.tt-layout-center) [class^="col"] {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 650px) {
  #ModalDiscount .modal-body {
    padding: 30px 0;
  }

  #ModalDiscount .modal-body .tt-modal-discount .tt-promo-title {
    padding-bottom: 18px;
  }

  #ModalDiscount .modal-body .tt-modal-discount .tt-promo-title .text-01 {
    font-size: 24px;
    line-height: 32px;
  }

  #ModalDiscount .modal-body .tt-modal-discount .tt-promo-title .text-02 {
    margin-top: 8px;
    font-size: 20px;
    line-height: 32px;
  }

  #ModalDiscount .modal-body .tt-modal-discount .tt-promo-title .text-03 {
    font-size: 36px;
    line-height: 32px;
    margin-top: 5px;
  }

  #ModalDiscount .modal-body .tt-modal-discount p {
    margin-top: 20px;
  }
}

#ModalDiscount .tt-modal-discount [class^="col"] {
  padding-left: 30px;
  padding-right: 30px;
}

#ModalDiscount .tt-modal-discount [class^="btn"] {
  width: 100%;
}

#ModalDiscount .tt-modal-discount .tt-promo-title {
  padding-bottom: 51px;
}

#ModalDiscount .tt-modal-discount .tt-promo-title .text-01 {
  color: #191919;
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
}

#ModalDiscount .tt-modal-discount .tt-promo-title .text-02 {
  color: #191919;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  margin-top: 33px;
}

#ModalDiscount .tt-modal-discount .tt-promo-title .text-03 {
  color: #191919;
  font-size: 40px;
  line-height: 34px;
  font-weight: 700;
  margin-top: 5px;
}

#ModalDiscount .tt-modal-discount .tt-link-underline {
  color: var(--yellow);
  text-decoration: underline;
}

#ModalDiscount .tt-modal-discount .tt-link-underline:hover {
  text-decoration: none;
}

#ModalDiscount .tt-modal-discount p {
  margin-top: 34px;
}

#ModalDiscount .form-default .form-group {
  margin-bottom: 10px;
}

#ModalDiscount .form-default .form-control {
  text-align: center;
}

#ModalDiscount .tt-background-fullwidth {
  background-size: 100% auto;
}

#ModalDiscount .tt-layout-center [class^="col"] {
  flex: 0 0 100%;
  max-width: 100%;
}

#ModalDiscount .tt-layout-center .tt-modal-discount .form-group {
  text-align: center;
}

#ModalDiscount .tt-layout-center .tt-modal-discount .form-group .form-control {
  max-width: 300px;
  display: inline-block;
}

#ModalDiscount .tt-layout-center .tt-modal-discount [class^="btn"] {
  max-width: 300px;
}

/*---------------------------------------*/
/*--------   9. Listing product   -------*/
/*---------------------------------------*/
/* section inside container */
.content-indent:not(:first-child) {
  margin-top: 52px;
}

/* tt-filters-options */
.tt-filters-options {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}

.tt-filters-options .tt-title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  letter-spacing: 0.02em;
  flex: 1 1 auto;
}

.tt-filters-options .tt-btn-toggle {
  display: none;
}

.tt-filters-options .tt-btn-toggle a {
  color: var(--yellow);
  transition: color 0.2s linear;
  font-weight: 500;
  letter-spacing: -0.02em;
  display: inline-block;
  position: relative;
  top: -2px;
  font-family: "Chakra Petch", sans-serif;
}

.tt-filters-options .tt-btn-toggle a:before {
  font-size: 20px;
  position: relative;
  top: 4px;
  margin-right: 7px;
  font-weight: normal;
  transition: color 0.2s linear;
  color: var(--yellow);
}

.tt-filters-options .tt-btn-toggle a:hover {
  color: #191919;
}

.tt-filters-options .tt-btn-toggle a:hover:before {
  color: #191919;
}

@media (min-width: 1025px) {
  .tt-filters-options .tt-btn-toggle a {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .tt-filters-options .tt-btn-toggle a {
    font-size: 13px;
  }
}

.tt-filters-options .tt-sort {
  margin-top: -1px;
}

.tt-filters-options .tt-sort select {
  border-color: transparent;
  outline: none;
  border: none;
  background: transparent;
  color: #777777;
  cursor: pointer;
  transition: color 0.2s linear;
}

.tt-filters-options .tt-sort select:hover {
  color: #191919;
}

.tt-filters-options .tt-sort select:not(:first-child) {
  margin-left: 10px;
}

.tt-filters-options .tt-quantity {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  margin-right: -2px;
  margin-left: 3px;
  margin-top: -1px;
}

.tt-filters-options .tt-quantity a:not(.last-child) {
  margin-left: 10px;
}

.tt-filters-options .tt-quantity a:not(.tt-show):not(.tt-show-siblings) {
  display: none;
}

.tt-filters-options .tt-quantity > a,
.tt-filters-options .tt-quantity > a:active,
.tt-filters-options .tt-quantity > a:focus {
  color: #191919;
  opacity: 0.15;
  transition: opacity 0.2s linear;
}

.tt-filters-options .tt-quantity > a.tt-col-one,
.tt-filters-options .tt-quantity > a.tt-col-two,
.tt-filters-options .tt-quantity > a.tt-col-three {
  font-size: 20px;
}

.tt-filters-options .tt-quantity > a.tt-col-four,
.tt-filters-options .tt-quantity > a.tt-col-six {
  font-size: 23px;
}

.tt-filters-options .tt-quantity > a.active,
.tt-filters-options .tt-quantity > a:hover {
  opacity: 1;
}

@media (max-width: 1024px) {
  .tt-filters-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  .tt-filters-options .tt-title {
    flex: 0 0 auto;
    width: 100%;
    text-align: center;
    padding-bottom: 16px;
  }

  .tt-filters-options .tt-btn-toggle {
    display: inline-block;
    flex: 1 1 auto;
  }
}

/*
	tt-filters-options desctop with desctop-no-sidebar
	*add class desctop-no-sidebar in html
*/
@media (min-width: 1025px) {
  .desctop-no-sidebar.tt-filters-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
  }

  .desctop-no-sidebar.tt-filters-options .tt-title {
    flex: 0 0 auto;
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
  }

  .desctop-no-sidebar.tt-filters-options .tt-btn-toggle {
    display: inline-block;
    flex: 1 1 auto;
  }
}

/* tt-filters-options - buttons at different breakpoints */
@media (min-width: 1025px) {
  .tt-filters-options + .tt-product-listing {
    margin-top: 15px;
  }
}

@media (max-width: 1024px) and (min-width: 576px) {
  .tt-filters-options + .tt-product-listing {
    margin-top: -13px;
  }
}

@media (max-width: 575px) {
  .tt-filters-options + .tt-product-listing {
    margin-top: -2px;
  }
}

.tt-product-listing {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.tt-product-listing > .tt-col-item {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

@media (min-width: 1025px) {
  .tt-product-listing > .tt-col-item {
    margin-top: 28px;
  }
}

@media (max-width: 1024px) and (min-width: 576px) {
  .tt-product-listing > .tt-col-item {
    margin-top: 40px;
  }
}

@media (max-width: 575px) {
  .tt-product-listing > .tt-col-item {
    margin-top: 30px;
  }
}

.tt-product-listing:not(:only-child) {
  justify-content: start;
}

.tt-product-listing.tt-col-one > .tt-col-item {
  flex: 0 0 100%;
  max-width: 100%;
}

.tt-product-listing.tt-col-two > .tt-col-item {
  flex: 0 0 50%;
  max-width: 50%;
}

.tt-product-listing.tt-col-three > .tt-col-item {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.tt-product-listing.tt-col-four > .tt-col-item {
  flex: 0 0 25%;
  max-width: 25%;
}

.tt-product-listing.tt-col-six > .tt-col-item {
  flex: 0 0 16.666%;
  max-width: 16.666%;
}

.tt_product_showmore {
  margin-top: 28px;
}

.tt_product_showmore .tt_item_all_js {
  margin-top: 18px;
  display: none;
}

.tt_product_showmore .tt_item_all_js.is-visibility {
  display: block;
}

@media (max-width: 360px) {
  .tt_product_showmore [class^="btn"] {
    width: 100%;
  }
}

/* leftColumn mobile toggle*/
body.no-scroll:not(.touch-device),
html.win.ie body.no-scroll {
  padding-right: 17px;
}

body.no-scroll {
  overflow: hidden;
  width: 100%;
  position: fixed;
  z-index: 0;
}

.modal-filter {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 11;
  display: block;
  opacity: 0;
  transition: background 0.2s linear;
}

.tt-btn-col-close {
  display: none;
  text-decoration: none;
  position: relative;
  width: 286px;
  left: -150%;
  top: 0;
  z-index: 13;
  transition: left 0.2s linear;
}

.tt-btn-col-close a {
  display: block;
  position: relative;
  padding: 15px 14px 13px 28px;
  font-size: 14px;
  text-align: left;
  background: transparent;
  transition: color 0.2s linear;
}

.tt-btn-col-close a:before {
  position: relative;
  font-size: 14px;
  line-height: 1;

  margin: 0 5px 0 0;
  transition: color 0.2s linear;
}

@media (min-width: 1025px) {
  .tt-btn-col-close {
    background: #ffffff;
    background: linear-gradient(to top, rgba(255, 255, 255, 0), white 20%);
  }

  .tt-btn-col-close a {
    color: #191919;
    font-family: "Chakra Petch", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    padding: 22px 14px 20px 28px;
  }

  .tt-btn-col-close a:before {
    font-size: 20px;
    top: 4px;
    font-weight: normal;
    letter-spacing: 0.04em;
    color: #191919;
  }

  .tt-btn-col-close a:hover {
    color: var(--yellow);
  }

  .tt-btn-col-close a:hover:before {
    color: #191919;
  }
}

@media (max-width: 1024px) {
  .tt-btn-col-close {
    background: #ffffff;
    border-bottom: 1px solid #e9e7e7;
  }

  .tt-btn-col-close a {
    color: #777777;
  }

  .tt-btn-col-close a:before {
    top: 1px;
    color: #191919;
  }

  .tt-btn-col-close a:hover {
    color: var(--yellow);
  }

  .tt-btn-col-close a:hover:before {
    color: #191919;
  }

  .tt-btn-col-close a:after {
    content: "";
    position: absolute;
    bottom: -11px;
    width: 100%;
    height: 10px;
    left: 0;
    background: linear-gradient(to top, rgba(255, 255, 255, 0), white 60%);
  }
}

@media (max-width: 1024px) {
  .aside.leftColumn {
    position: fixed;
    display: block;
    margin: 0;
    background: #ffffff;
    z-index: 14;
    top: 0;
    left: -150%;
    height: 100%;
    width: 287px;
    max-width: inherit;
    text-align: left;
    transition: left 0.2s linear;
  }

  .aside.leftColumn .tt-btn-col-close {
    display: block;
  }
}

@media (min-width: 1025px) {
  .aside.leftColumn:not(.desctop-no-sidebar) .tt-filter-detach-option {
    display: none;
  }
}

/*
	leftColumn desctop toggle (listing-metro.html, listing-not-sidebar-full-width.html)
	*add class in html desctop-no-sidebar
*/
@media (min-width: 1025px) {
  .aside.leftColumn.desctop-no-sidebar {
    position: fixed;
    display: block;
    margin: 0;
    background: #ffffff;
    z-index: 14;
    top: 0;
    left: -150%;
    height: 100%;
    width: 287px;
    max-width: inherit;
    text-align: left;
    overflow-x: hidden;
    transition: left 0.2s linear;
  }

  .aside.leftColumn.desctop-no-sidebar .tt-btn-col-close {
    display: block;
  }

  .aside.leftColumn.desctop-no-sidebar .tt-btn-col-close + .tt-collapse {
    margin-top: 46px;
  }
}

/* leftColumn column open*/
.aside.leftColumn.column-open {
  left: 0;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 50px;
  padding-bottom: 20px;
}

.aside.leftColumn.column-open .tt-btn-col-close {
  left: 0;
  position: fixed;
}

html.ie .aside.leftColumn.desctop-no-sidebar,
html.ie .aside.leftColumn,
html.ie .tt-btn-col-close {
  transition: left 0s linear;
}

/*
	aside listing block
	*leftColumn
	*rightColumn
*/
.tt-collapse {
  position: relative;
}

.tt-collapse .tt-collapse-title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding: 0 20px 13px 0;
  cursor: pointer;
  color: #191919;
  border-bottom: 1px solid #e9e7e7;
  transition: color 0.2s linear;
}

.tt-collapse .tt-collapse-title:after {
  position: absolute;
  display: block;
  top: 7px;
  right: -2px;

  font-size: 10px;

  line-height: 1;
  font-weight: normal;
  color: #191919;
}

.tt-collapse .tt-collapse-title:hover {
  color: var(--yellow);
}

.tt-collapse .tt-collapse-title:hover:after {
  color: #191919;
}

.tt-collapse .tt-collapse-title + .tt-collapse-content {
  margin-top: 20px;
}

.tt-collapse .tt-collapse-title + .tt-collapse-content .tt-list-row {
  margin-top: -8px;
}

.tt-collapse .tt-collapse-title + .tt-collapse-content .tt-filter-list {
  margin-top: -7px;
}

.tt-collapse .tt-collapse-title + .tt-collapse-content .tt-list-inline,
.tt-collapse .tt-collapse-title + .tt-collapse-content .tt-aside {
  margin-top: -4px;
}

@media (min-width: 1025px) {
  .column-open .tt-collapse.hide ~ div {
    margin-top: 46px;
  }
}

@media (max-width: 1024px) {
  .column-open .tt-collapse.hide ~ div {
    margin-top: 32px;
  }
}

.tt-collapse:not(:last-child) {
  margin-bottom: 33px;
}

.tt-btn-col-close + .tt-collapse {
  margin-top: 32px;
}

/* desctope (listing-not-sidebar-full-width.html)*/
@media (min-width: 1025px) {
  .column-open .tt-btn-col-close + .tt-collapse {
    margin-top: 20px;
  }
}

/* tt-filter-list (aside listing block)*/
.tt-filter-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: -3px;
}

.tt-filter-list li {
  position: relative;
}

.tt-filter-list li a {
  display: block;
  padding: 3px 0 3px 20px;
  letter-spacing: 0.01em;
  color: #777777;
  transition: color 0.2s linear;
}

.tt-filter-list li a:before {
  position: absolute;
  left: -1px;
  top: 7px;
  font-size: 12px;
  line-height: 1;

  color: #191919;
}

.tt-filter-list li a:hover {
  color: var(--yellow);
}

.tt-filter-list li a:hover:before {
  color: #191919;
}

.tt-filter-list li.active {
  color: var(--yellow);
}

.tt-filter-list li.active a:before {
  color: var(--yellow);
}

.tt-filter-list + .btn-link-02 {
  margin-top: 6px;
}

/* tt-list-row (aside simple list)*/
.leftColumn .tt-list-row li a,
.rightColumn .tt-list-row li a {
  display: block;
}

.tt-list-row {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: -2px;
}

.tt-list-row li a {
  color: #777777;
  padding: 5px 0;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  transition: color 0.2s linear;
}

.tt-list-row li a:hover {
  color: var(--yellow);
}

.tt-list-row li.active a {
  color: var(--yellow);
}

.tt-list-row li:last-child a {
  padding-bottom: 0;
}

.tt-list-row + [class^="btn"] {
  margin-top: 5px;
}

/* tt-aside (aside img block)*/
.tt-aside .tt-item:not(:first-child) {
  margin-top: 16px;
}

.tt-aside .tt-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.tt-aside .tt-item .tt-img {
  flex: 0 0 calc(100% - 20px);
  max-width: calc(100% - 20px);
  width: calc(100% - 20px);
  margin-right: 20px;
  margin-top: 4px;
  position: relative;
}

.tt-aside .tt-item .tt-img img {
  max-width: 100%;
  height: auto;
}

.tt-aside .tt-item .tt-img .tt-img-default {
  transition: opacity 0.3s ease-out;
  overflow: hidden;
  top: 0;
  left: 0;
}

.tt-aside .tt-item .tt-img .tt-img-roll-over {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  width: 100%;
  transition: opacity 0.3s ease-out;
}

.tt-aside .tt-item .tt-img + .tt-content {
  flex: 0 0 50%;
  max-width: 50%;
}

.tt-aside .tt-item .tt-content .tt-title {
  font-size: 14px;
  font-weight: normal;
  font-family: "Chakra Petch", sans-serif;
  color: #191919;
  transition: color 0.2s linear;
}

.tt-aside .tt-item .tt-content .tt-price {
  color: #191919;
  font-size: 16px;
  font-weight: 500;
}

.tt-aside .tt-item .tt-content .tt-price .sale-price {
  color: #f8353e;
  margin-right: 4px;
}

.tt-aside .tt-item .tt-content .tt-price .old-price {
  color: #191919;
  text-decoration: line-through;
}

.tt-aside .tt-item:hover .tt-img-default + .tt-roll-over {
  opacity: 0;
}

.tt-aside .tt-item:hover .tt-img-roll-over,
.tt-aside .tt-item:hover [class^="tt-img"]:last-child {
  opacity: 1;
}

.tt-aside .tt-item:hover .tt-title {
  color: var(--yellow);
}

/* tt-filter-list (aside filter block)*/
.tt-filter-detach-option .filters-mobile .filters-row-select:not(:first-child) {
  margin-top: 20px;
}

.tt-filter-detach-option .filters-mobile .filters-row-select select {
  width: 100%;
  font-size: 14px;
  line-height: 1;
  border-color: transparent;
  outline: none;
  border: none;
  background: #f7f8fa;
  color: #777777;
  cursor: pointer;
  padding: 9px 5px 12px 9px;
  letter-spacing: 0.01em;
  transition: color 0.2s linear;
}

.tt-filter-detach-option .filters-mobile .filters-row-select select:hover {
  color: #191919;
}

.tt-filter-detach-option
  .filters-mobile
  .filters-row-select
  select:not(:first-child) {
  margin-top: 20px;
}

/* tt-product-listing-masonry (listing-metro.html)*/
@media (min-width: 1025px) {
  .tt-filters-options + .tt-product-listing-masonry {
    margin-top: 28px;
  }
}

@media (max-width: 1024px) {
  .tt-filters-options + .tt-product-listing-masonry {
    margin-top: -4px;
  }
}

@media (max-width: 575px) {
  .tt-filters-options + .tt-product-listing-masonry {
    margin-top: 8px;
  }
}

.tt-product-listing-masonry .tt-product-init {
  margin: -10px -10px;
}

.tt-product-listing-masonry .grid:after {
  content: "";
  display: block;
  clear: both;
}

.tt-product-listing-masonry .element-item {
  position: relative;
  float: left;
}

@media (min-width: 1025px) {
  .tt-product-listing-masonry .element-item {
    width: 25%;
    padding: 10px;
  }

  .tt-product-listing-masonry .element-item.double-size {
    width: 50%;
  }
}

@media (max-width: 1024px) {
  .tt-product-listing-masonry {
    margin-top: -40px;
  }

  .tt-product-listing-masonry .element-item {
    width: 33.333%;
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 789px) {
  .tt-product-listing-masonry .element-item {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .tt-product-listing-masonry {
    margin-top: -30px;
  }

  .tt-product-listing-masonry .element-item {
    margin-top: 30px;
  }
}

.tt-product-listing.tt-row-view > .tt-col-item {
  flex: 0 0 100%;
  max-width: 100%;
}

.tt-grid-switch {
  color: #191919;
  font-size: 20px;
  margin-left: 10px;
  margin-top: -1px;
  text-decoration: none;
  opacity: 0.15;
  transition: color 0.2s linear;
}

.tt-grid-switch:hover,
.tt-grid-switch.active {
  color: #191919;
  opacity: 1;
}

.tt-quantity.tt-disabled a.active {
  opacity: 0.3;
}

.tt-product.tt-view,
.tt-product-design02.tt-view {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
}

.tt-product.tt-view .tt-image-box,
.tt-product-design02.tt-view .tt-image-box {
  position: relative;
  width: 40%;
}

.tt-product.tt-view .tt-image-box img,
.tt-product-design02.tt-view .tt-image-box img {
  width: 100%;
  height: auto;
}

.tt-product.tt-view .tt-image-box .tt-btn-quickview,
.tt-product-design02.tt-view .tt-image-box .tt-btn-quickview {
  display: none;
}

.tt-product.tt-view .tt-image-box .tt-img,
.tt-product-design02.tt-view .tt-image-box .tt-img {
  transition: opacity 0.2s ease-out;
  overflow: hidden;
  top: 0;
  left: 0;
}

.tt-product.tt-view .tt-image-box .tt-img-roll-over,
.tt-product-design02.tt-view .tt-image-box .tt-img-roll-over {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  width: 100%;
  transition: opacity 0.2s ease-out;
}

.tt-product.tt-view .tt-image-box .tt-label-location,
.tt-product-design02.tt-view .tt-image-box .tt-label-location {
  position: absolute;
  top: 7px;
  left: 8px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.tt-product.tt-view .tt-image-box .tt-label-location [class^="tt-label-"],
.tt-product-design02.tt-view
  .tt-image-box
  .tt-label-location
  [class^="tt-label-"] {
  margin-left: 3px;
  margin-top: 3px;
  font-size: 12px;
  line-height: 1;
  color: #ffffff;
  font-weight: 500;
  padding: 2px 4px;
}

.tt-product.tt-view .tt-image-box .tt-label-location .tt-label-new,
.tt-product-design02.tt-view .tt-image-box .tt-label-location .tt-label-new {
  background: #03a9f5;
  color: #ffffff;
}

.tt-product.tt-view .tt-image-box .tt-label-location .tt-label-sale,
.tt-product-design02.tt-view .tt-image-box .tt-label-location .tt-label-sale {
  background: #f8353e;
  color: #ffffff;
}

.tt-product.tt-view .tt-image-box .tt-label-location .tt-label-our-fatured,
.tt-product-design02.tt-view
  .tt-image-box
  .tt-label-location
  .tt-label-our-fatured {
  background: #fdbc20;
  color: #ffffff;
}

.tt-product.tt-view .tt-image-box .tt-label-location .tt-label-our-stock,
.tt-product-design02.tt-view
  .tt-image-box
  .tt-label-location
  .tt-label-our-stock {
  background: #191919;
  color: #ffffff;
}

.tt-product.tt-view .tt-description,
.tt-product-design02.tt-view .tt-description {
  width: 60%;
  position: relative;
  z-index: 2;
}

@media (min-width: 421px) {
  .tt-product.tt-view .tt-description,
  .tt-product-design02.tt-view .tt-description {
    padding-left: 30px;
  }
}

@media (max-width: 420px) {
  .tt-product.tt-view .tt-description,
  .tt-product-design02.tt-view .tt-description {
    padding-left: 20px;
  }
}

.tt-product.tt-view .tt-description .tt-row,
.tt-product-design02.tt-view .tt-description .tt-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.tt-product.tt-view .tt-description .tt-row > *:nth-child(1),
.tt-product-design02.tt-view .tt-description .tt-row > *:nth-child(1) {
  flex: 1 1 auto;
}

@media (max-width: 420px) {
  .tt-product.tt-view .tt-description .tt-row,
  .tt-product-design02.tt-view .tt-description .tt-row {
    flex-direction: column;
    padding-bottom: 3px;
  }

  .tt-product.tt-view .tt-description .tt-row .tt-rating:not(:first-child),
  .tt-product-design02.tt-view
    .tt-description
    .tt-row
    .tt-rating:not(:first-child) {
    margin-top: 3px;
  }
}

.tt-product.tt-view .tt-description .tt-add-info,
.tt-product-design02.tt-view .tt-description .tt-add-info {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.tt-product.tt-view .tt-description .tt-add-info li,
.tt-product-design02.tt-view .tt-description .tt-add-info li {
  font-size: 12px;
  line-height: 19px;
  color: #999999;
}

.tt-product.tt-view .tt-description .tt-add-info li a,
.tt-product-design02.tt-view .tt-description .tt-add-info li a {
  color: #999999;
  transition: color 0.2s linear;
}

.tt-product.tt-view .tt-description .tt-add-info li a:hover,
.tt-product-design02.tt-view .tt-description .tt-add-info li a:hover {
  color: var(--yellow);
}

.tt-product.tt-view .tt-description .tt-title,
.tt-product-design02.tt-view .tt-description .tt-title {
  font-family: "Chakra Petch", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #191919;
}

.tt-product.tt-view .tt-description .tt-title a,
.tt-product-design02.tt-view .tt-description .tt-title a {
  color: #191919;
}

.tt-product.tt-view .tt-description .tt-title a:hover,
.tt-product-design02.tt-view .tt-description .tt-title a:hover {
  color: var(--yellow);
}

.tt-product.tt-view .tt-description .tt-price,
.tt-product-design02.tt-view .tt-description .tt-price {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #191919;
  margin-top: 3px;
}

.tt-product.tt-view .tt-description .tt-price .new-price,
.tt-product-design02.tt-view .tt-description .tt-price .new-price {
  color: #f8353e;
  margin-right: 7px;
}

.tt-product.tt-view .tt-description .tt-price .old-price,
.tt-product-design02.tt-view .tt-description .tt-price .old-price {
  color: #191919;
  text-decoration: line-through;
}

.tt-product.tt-view .tt-description .tt-price .old-price .money,
.tt-product-design02.tt-view .tt-description .tt-price .old-price .money {
  text-decoration: line-through;
}

.tt-product.tt-view .tt-description .tt-price span,
.tt-product-design02.tt-view .tt-description .tt-price span {
  display: inline-block;
}

.tt-product.tt-view .tt-description .tt-option-block,
.tt-product-design02.tt-view .tt-description .tt-option-block {
  margin-top: 15px;
}

.tt-product.tt-view
  .tt-description
  .tt-option-block
  > [class^="tt-options-"]:not(:first-child),
.tt-product-design02.tt-view
  .tt-description
  .tt-option-block
  > [class^="tt-options-"]:not(:first-child) {
  margin-top: 3px;
}

.tt-product.tt-view .tt-description .tt-option-block > [class^="tt-options-"],
.tt-product-design02.tt-view
  .tt-description
  .tt-option-block
  > [class^="tt-options-"] {
  width: 100%;
}

.tt-product.tt-view .tt-description .tt-product-inside-hover,
.tt-product-design02.tt-view .tt-description .tt-product-inside-hover {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
}

.tt-product.tt-view .tt-description .tt-btn-addtocart,
.tt-product-design02.tt-view .tt-description .tt-btn-addtocart {
  line-height: 1;
  font-weight: 500;
  font-family: "Chakra Petch", sans-serif;
  display: inline-block;
  position: relative;
  letter-spacing: 0.04em;
  color: var(--main);
  font-size: 14px;
  margin-top: 2px;
  transition: color 0.2s linear;
}

.tt-product.tt-view .tt-description .tt-btn-addtocart:before,
.tt-product-design02.tt-view .tt-description .tt-btn-addtocart:before {
  font-size: 20px;
  top: 3px;
  margin-right: 6px;
  font-weight: normal;
  position: relative;
  color: var(--main);
  transition: color 0.2s linear;
}

.tt-product.tt-view .tt-description .tt-btn-addtocart:hover,
.tt-product-design02.tt-view .tt-description .tt-btn-addtocart:hover {
  color: #191919;
}

.tt-product.tt-view .tt-description .tt-btn-addtocart:hover:before,
.tt-product-design02.tt-view .tt-description .tt-btn-addtocart:hover:before {
  color: #191919;
}

.tt-product.tt-view .tt-description .tt-btn-quickview,
.tt-product-design02.tt-view .tt-description .tt-btn-quickview {
  font-size: 20px;
  line-height: 1;
  padding: 4px 6px 4px 6px;
  margin-left: 9px;
  margin-right: 9px;
  margin-top: 4px;
  color: #191919;
  transition: color 0.2s linear;
  display: inline-block;
  position: relative;
}

.tt-product.tt-view .tt-description .tt-btn-quickview:hover,
.tt-product-design02.tt-view .tt-description .tt-btn-quickview:hover {
  color: var(--yellow);
}

.tt-product.tt-view .tt-description .fa-heart,
.tt-product-design02.tt-view .tt-description .fa-heart {
  font-size: 16px;
  line-height: 1;
  padding: 4px 6px 4px 6px;
  margin-left: 9px;
  margin-right: 9px;
  margin-top: 4px;
  color: #191919;
  transition: color 0.2s linear;
  display: inline-block;
  position: relative;
}

.tt-product.tt-view .tt-description .fa-heart:hover,
.tt-product-design02.tt-view .tt-description .fa-heart:hover {
  color: var(--yellow);
}

.tt-product.tt-view .tt-description .fa-heart.active,
.tt-product-design02.tt-view .tt-description .fa-heart.active {
  color: var(--yellow);
}

.tt-product.tt-view .tt-description .fa-cart-plus,
.tt-product-design02.tt-view .tt-description .fa-cart-plus {
  font-size: 16px;
  line-height: 1;
  padding: 4px 6px 4px 6px;
  margin-left: 9px;
  margin-right: 9px;
  margin-top: 4px;
  color: #191919;
  transition: color 0.2s linear;
  display: inline-block;
  position: relative;
}

.tt-product.tt-view .tt-description .fa-cart-plus:hover,
.tt-product-design02.tt-view .tt-description .fa-cart-plus:hover {
  color: var(--yellow);
}

.tt-product.tt-view .tt-description .fa-cart-plus.active,
.tt-product-design02.tt-view .tt-description .fa-cart-plus.active {
  color: var(--yellow);
}

@media (max-width: 370px) {
  .tt-product.tt-view .tt-description .tt-btn-quickview,
  .tt-product.tt-view .tt-description .fa-heart,
  .tt-product.tt-view .tt-description .fa-cart-plus,
  .tt-product-design02.tt-view .tt-description .tt-btn-quickview,
  .tt-product-design02.tt-view .tt-description .fa-heart,
  .tt-product-design02.tt-view .tt-description .fa-cart-plus {
    margin-left: 3px;
    margin-right: 3px;
  }
}

.tt-product.tt-view .tt-description .tt-row-btn,
.tt-product-design02.tt-view .tt-description .tt-row-btn {
  margin-top: 12px;
}

.tt-product.tt-view
  .tt-description
  .tt-row-btn:not(:first-child)
  [class^="tt-btn"]:first-child,
.tt-product-design02.tt-view
  .tt-description
  .tt-row-btn:not(:first-child)
  [class^="tt-btn"]:first-child {
  margin-left: 0;
  padding-left: 0;
}

.tt-product.tt-view .tt-description > *:nth-child(1),
.tt-product-design02.tt-view .tt-description > *:nth-child(1) {
  margin-top: 0px;
}

.tt-product.tt-view .tt-description > *:nth-child(1).tt-row,
.tt-product.tt-view .tt-description > *:nth-child(1).tt-row,
.tt-product.tt-view .tt-description > *:nth-child(1).tt-title,
.tt-product.tt-view .tt-description > *:nth-child(1).tt-text-info,
.tt-product-design02.tt-view .tt-description > *:nth-child(1).tt-row,
.tt-product-design02.tt-view .tt-description > *:nth-child(1).tt-row,
.tt-product-design02.tt-view .tt-description > *:nth-child(1).tt-title,
.tt-product-design02.tt-view .tt-description > *:nth-child(1).tt-text-info {
  margin-top: -4px;
}

.tt-product.tt-view .tt-image-box:hover .tt-img-roll-over + .tt-img,
.tt-product-design02.tt-view .tt-image-box:hover .tt-img-roll-over + .tt-img {
  opacity: 0;
}

.tt-product.tt-view .tt-image-box:hover .tt-img-roll-over,
.tt-product.tt-view .tt-image-box:hover [class^="tt-img"]:last-child,
.tt-product-design02.tt-view .tt-image-box:hover .tt-img-roll-over,
.tt-product-design02.tt-view .tt-image-box:hover [class^="tt-img"]:last-child {
  opacity: 1;
}

/*---------------------------------------*/
/*--------  10. Product item     --------*/
/*---------------------------------------*/
/* tt-product item  desctope*/
@media (min-width: 1025px) {
  .tt-product:not(.tt-view).thumbprod-center {
    text-align: center;
  }

  .tt-product:not(.tt-view).thumbprod-center .tt-description .tt-row {
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }

  .tt-product:not(.tt-view).thumbprod-center
    .tt-description
    .tt-row
    .tt-add-info {
    width: 100%;
  }

  .tt-product:not(.tt-view).thumbprod-center .tt-description .tt-rating {
    width: 100%;
    display: block;
    text-align: center;
  }

  .tt-product:not(.tt-view).thumbprod-center .tt-description .tt-price {
    display: block;
  }

  .tt-product:not(.tt-view).thumbprod-center
    .tt-description
    .tt-options-swatch {
    justify-content: center;
  }

  .tt-product:not(.tt-view).thumbprod-center
    .tt-description
    .tt-product-inside-hover {
    display: block;
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .tt-product:not(.tt-view).thumbprod-center {
    text-align: center;
  }

  .tt-product:not(.tt-view).thumbprod-center .tt-description .tt-row {
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }

  .tt-product:not(.tt-view).thumbprod-center
    .tt-description
    .tt-row
    .tt-add-info {
    width: 100%;
  }

  .tt-product:not(.tt-view).thumbprod-center .tt-description .tt-rating {
    width: 100%;
    display: block;
    text-align: center;
  }

  .tt-product:not(.tt-view).thumbprod-center .tt-description .tt-price {
    display: block;
  }

  .tt-product:not(.tt-view).thumbprod-center
    .tt-description
    .tt-options-swatch {
    justify-content: center;
  }
}

@media (min-width: 1025px) {
  .tt-product:not(.tt-view) {
    overflow: hidden;
  }

  .tt-product:not(.tt-view) .tt-image-box {
    position: relative;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-btn-quickview {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: #ffffff;
    color: #191919;
    width: 46px;
    height: 46px;
    position: absolute;
    top: 10px;
    right: 17px;
    cursor: pointer;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.2s linear;
    z-index: 6;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-btn-quickview:before {
    font-size: 20px;
    line-height: 1;
    margin-top: -2px;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-btn-quickview:hover {
    background: var(--main);
    color: #ffffff;
  }

  .tt-product:not(.tt-view) .tt-image-box .fa-heart {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: #ffffff;
    color: #191919;
    width: 46px;
    height: 46px;
    position: absolute;
    top: 10px;
    right: 70px;
    cursor: pointer;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.2s linear;
    z-index: 6;
  }

  .tt-product:not(.tt-view) .tt-image-box .fa-heart:before {
    font-size: 20px;
    line-height: 1;
    margin-top: 1px;
  }

  .tt-product:not(.tt-view) .tt-image-box .fa-heart:hover {
    background: var(--main);
    color: #ffffff;
  }

  .tt-product:not(.tt-view) .tt-image-box .fa-heart.active {
    background: var(--yellow);
    color: #ffffff;
  }

  .tt-product:not(.tt-view) .tt-image-box .fa-cart-plus {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: #ffffff;
    color: #191919;
    width: 46px;
    height: 46px;
    position: absolute;
    top: 70px;
    right: 70px;
    cursor: pointer;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.2s linear;
    z-index: 6;
  }

  .tt-product:not(.tt-view) .tt-image-box .fa-cart-plus:before {
    font-size: 20px;
    line-height: 1;
    margin-top: 1px;
  }

  .tt-product:not(.tt-view) .tt-image-box .fa-cart-plus:hover {
    background: var(--main);
    color: #ffffff;
  }

  .tt-product:not(.tt-view) .tt-image-box .fa-cart-plus.active {
    background: var(--yellow);
    color: #ffffff;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-btn-quickview + .fa-heart {
    top: 61px;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-btn-quickview + .fa-cart-plus {
    top: 61px;
  }

  .tt-product:not(.tt-view)
    .tt-image-box
    .tt-btn-quickview
    + .fa-heart
    + .fa-cart-plus {
    top: 116px;
  }

  .tt-product:not(.tt-view) .tt-image-box img {
    width: 100%;
    height: auto;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-img {
    transition: opacity 0.3s ease-out;
    overflow: hidden;
    top: 0;
    left: 0;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-img-roll-over {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    width: 100%;
    transition: opacity 0.2s ease-out;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-img-roll-over.disable {
    display: none;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-label-location {
    position: absolute;
    top: 15px;
    left: 30px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .tt-product:not(.tt-view)
    .tt-image-box
    .tt-label-location
    [class^="tt-label-"] {
    margin-left: 3px;
    margin-top: 3px;
    font-size: 12px;
    line-height: 1;
    color: #ffffff;
    font-weight: 500;
    padding: 3px 4px 1px;
    border-radius: 2px;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-label-location .tt-label-new {
    background: #03a9f5;
    color: #ffffff;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-label-location .tt-label-sale {
    background: #f8353e;
    color: #ffffff;
  }

  .tt-product:not(.tt-view)
    .tt-image-box
    .tt-label-location
    .tt-label-our-fatured {
    background: #fdbc20;
    color: #ffffff;
  }

  .tt-product:not(.tt-view)
    .tt-image-box
    .tt-label-location
    .tt-label-our-stock {
    background: #191919;
    color: #ffffff;
  }

  .tt-product:not(.tt-view) .tt-description {
    padding-top: 17px;
    position: relative;
    z-index: 2;
    background: #ffffff;
  }

  .tt-product:not(.tt-view) .tt-description .tt-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }

  .tt-product:not(.tt-view) .tt-description .tt-row > *:nth-child(1) {
    flex: 1 1 auto;
  }

  .tt-product:not(.tt-view) .tt-description .tt-add-info {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    font-family: "Chakra Petch", sans-serif;
  }

  .tt-product:not(.tt-view) .tt-description .tt-add-info li {
    font-size: 12px;
    line-height: 19px;
    color: #999999;
  }

  .tt-product:not(.tt-view) .tt-description .tt-add-info li a {
    color: #999999;
    transition: color 0.2s linear;
  }

  .tt-product:not(.tt-view) .tt-description .tt-add-info li a:hover {
    color: var(--yellow);
  }

  .tt-product:not(.tt-view) .tt-description .tt-title {
    font-family: "Chakra Petch", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #191919;
  }

  .tt-product:not(.tt-view) .tt-description .tt-title a {
    color: #191919;
  }

  .tt-product:not(.tt-view) .tt-description .tt-title a:hover {
    color: var(--yellow);
  }

  .tt-product:not(.tt-view) .tt-description .tt-select {
    background-color: #f7f8fa;
    color: #777777;
    border: none;
    height: 30px;
    width: 100%;
    max-width: 170px;
    padding: 2px 8px;
    cursor: pointer;
    outline: none;
    display: block;
  }

  .tt-product:not(.tt-view) .tt-description .tt-price {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    font-family: "Chakra Petch", sans-serif;
    color: #191919;
  }

  .tt-product:not(.tt-view) .tt-description .tt-price .new-price {
    color: #f8353e;
    margin-right: 7px;
  }

  .tt-product:not(.tt-view) .tt-description .tt-price .old-price {
    color: #191919;
    text-decoration: line-through;
  }

  .tt-product:not(.tt-view) .tt-description .tt-price .old-price .money {
    text-decoration: line-through;
  }

  .tt-product:not(.tt-view) .tt-description .tt-price span {
    display: inline-block;
  }

  .tt-product:not(.tt-view) .tt-description .tt-option-block {
    margin-top: 14px;
  }

  .tt-product:not(.tt-view)
    .tt-description
    .tt-option-block
    > [class^="tt-options-"]:not(:first-child) {
    margin-top: 9px;
  }

  .tt-product:not(.tt-view) .tt-description .tt-product-inside-hover {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    position: absolute;
    width: 100%;
  }
}

@media (min-width: 1025px) and (min-width: 1025px) {
  .tt-product:not(.tt-view) .tt-description .tt-product-inside-hover {
    display: none;
    opacity: 0;
  }

  .tt-product:not(.tt-view) .tt-description .tt-product-inside-hover > * {
    margin-top: 17px;
  }
}

@media (min-width: 1025px) {
  .tt-product:not(.tt-view) .tt-description .tt-btn-addtocart {
    line-height: 1;
    font-weight: 400;
    font-family: "Chakra Petch", sans-serif;
    display: inline-block;
    position: relative;
    letter-spacing: 0.04em;
    color: var(--main);
    font-size: 14px;
    transition: color 0.2s linear;
  }

  .tt-product:not(.tt-view)
    .tt-description
    .tt-btn-addtocart.thumbprod-button-bg {
    background-color: var(--main);
    color: #ffffff;
    padding: 3px 16px 9px;
    border-radius: 3px;
    transition: all 0.2s linear;
  }

  .tt-product:not(.tt-view)
    .tt-description
    .tt-btn-addtocart.thumbprod-button-bg:before {
    color: #ffffff;
  }

  .tt-product:not(.tt-view)
    .tt-description
    .tt-btn-addtocart.thumbprod-button-bg:hover {
    background-color: #606060;
    color: #ffffff;
  }

  .tt-product:not(.tt-view)
    .tt-description
    .tt-btn-addtocart.thumbprod-button-bg:hover:before {
    color: #ffffff;
  }

  .tt-product:not(.tt-view) .tt-description .tt-btn-addtocart:before {
    font-size: 20px;
    top: 3px;
    margin-right: 6px;
    font-weight: normal;
    position: relative;
    color: var(--yellow);
    transition: color 0.2s linear;
  }

  .tt-product:not(.tt-view) .tt-description .tt-btn-addtocart:hover {
    color: #191919;
  }

  .tt-product:not(.tt-view) .tt-description .tt-btn-addtocart:hover:before {
    color: #191919;
  }

  .tt-product:not(.tt-view) .tt-description > *:nth-child(1) {
    margin-top: 0;
  }
}

@media (max-width: 1024px) {
  .tt-product:not(.tt-view) .tt-image-box .tt-btn-quickview {
    display: none;
  }
}

@media (min-width: 1025px) {
  .tt-product:not(.tt-view):hover .tt-image-box .tt-btn-quickview {
    opacity: 1;
  }

  .tt-product:not(.tt-view):hover .tt-image-box .fa-heart {
    opacity: 1;
  }

  .tt-product:not(.tt-view):hover .tt-image-box .fa-cart-plus {
    opacity: 1;
  }

  .tt-product:not(.tt-view) .tt-image-box:hover .tt-img-roll-over + .tt-img {
    opacity: 0;
  }

  .tt-product:not(.tt-view) .tt-image-box:hover .tt-img-roll-over,
  .tt-product:not(.tt-view) .tt-image-box:hover [class^="tt-img"]:last-child {
    opacity: 1;
  }
}

@media (min-width: 1025px) {
  .tt-product:not(.tt-view).hovered .tt-product-inside-hover {
    display: inline-flex;
  }
}

/* tt-product-design02 desctope*/
@media (min-width: 1025px) {
  .tt-product-design02:not(.tt-view).thumbprod-center .tt-description {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 12px 20px;
    margin-top: 0px;
    bottom: -50%;
    position: absolute;
    width: 100%;
    opacity: 0;
    z-index: 2;
    visibility: hidden;
    transition: bottom 0.2s linear;
  }

  .tt-product-design02:not(.tt-view).thumbprod-center .tt-description .tt-row {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .tt-product-design02:not(.tt-view).thumbprod-center
    .tt-description
    .tt-row
    .tt-add-info {
    width: 100%;
    display: block;
    text-align: center;
  }

  .tt-product-design02:not(.tt-view).thumbprod-center
    .tt-description
    .tt-options-swatch {
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .tt-product-design02:not(.tt-view).thumbprod-center
    .tt-description
    .tt-options-swatch:not(.options-large):not(.options-middle)
    li
    a:not(.options-color) {
    padding-top: 1px;
  }

  .tt-product-design02:not(.tt-view).thumbprod-center
    .tt-description
    .tt-product-inside-hover {
    display: block;
    text-align: center;
  }

  .tt-product-design02:not(.tt-view).thumbprod-center
    .tt-description
    .tt-btn-quickview {
    position: absolute;
    bottom: 8px;
    right: 18px;
  }

  .tt-product-design02:not(.tt-view).thumbprod-center
    .tt-description
    .tt-btn-link {
    position: absolute;
    bottom: 7px;
    right: 18px;
  }

  .tt-product-design02:not(.tt-view).thumbprod-center:hover .tt-description {
    height: 100%;
  }
}

@media (min-width: 1025px) {
  .tt-product-design02:not(.tt-view) {
    position: relative;
    overflow: hidden;
  }

  .tt-product-design02:not(.tt-view) .tt-image-box {
    position: relative;
  }

  .tt-product-design02:not(.tt-view) .tt-image-box .tt-img img {
    width: 100%;
    height: auto;
  }

  .tt-product-design02:not(.tt-view) .tt-image-box .tt-img-roll-over {
    display: none;
  }

  .tt-product-design02:not(.tt-view) .tt-image-box .tt-label-location {
    position: absolute;
    top: 7px;
    left: 8px;
    z-index: 7;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    opacity: 1;
    transition: opacity 0.2s linear;
  }

  .tt-product-design02:not(.tt-view)
    .tt-image-box
    .tt-label-location
    [class^="tt-label-"] {
    margin-left: 3px;
    margin-top: 3px;
    font-size: 12px;
    line-height: 1;
    color: #ffffff;
    font-weight: 500;
    padding: 2px 4px;
  }

  .tt-product-design02:not(.tt-view)
    .tt-image-box
    .tt-label-location
    .tt-label-new {
    color: #ffffff;
    background: #1393f5;
  }

  .tt-product-design02:not(.tt-view)
    .tt-image-box
    .tt-label-location
    .tt-label-sale {
    color: #ffffff;
    background: #f8353e;
  }

  .tt-product-design02:not(.tt-view)
    .tt-image-box
    .tt-label-location
    .tt-label-our-fatured {
    color: #ffffff;
    background: #fdbc20;
  }

  .tt-product-design02:not(.tt-view)
    .tt-image-box
    .tt-label-location
    .tt-label-our-stock {
    color: #ffffff;
    background: #191919;
  }

  .tt-product-design02:not(.tt-view) .tt-image-box:before {
    background: #f7f8fa;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.2s linear;
  }

  .tt-product-design02:not(.tt-view) .tt-description {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: flex-start;
    align-items: flex-start;
    padding: 12px 20px;
    margin-top: 0px;
    bottom: -50%;
    position: absolute;
    width: 100%;
    opacity: 0;
    z-index: 2;
    visibility: hidden;
    transition: bottom 0.2s linear;
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-row {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-row .tt-rating {
    order: 1;
    margin-left: -1px;
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-row .tt-add-info {
    order: 2;
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-add-info {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: 13px;
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-add-info li {
    font-size: 12px;
    line-height: 19px;
    color: #191919;
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-add-info li a {
    color: #191919;
    transition: all 0.2s linear;
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-add-info li a:hover {
    color: var(--yellow);
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-title {
    font-family: "Chakra Petch", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #191919;
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-title a {
    color: #191919;
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-title a:hover {
    color: var(--yellow);
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-price {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #191919;
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-price .new-price {
    color: #f8353e;
    margin-right: 7px;
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-price .old-price {
    color: #191919;
    text-decoration: line-through;
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-price span {
    display: inline-block;
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-option-block {
    margin-top: 9px;
  }

  .tt-product-design02:not(.tt-view)
    .tt-description
    .tt-option-block
    > [class^="tt-options-"]:not(:first-child) {
    margin-top: 9px;
  }

  .tt-product-design02:not(.tt-view) .tt-description .tt-product-inside-hover {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    overflow: hidden;
    width: 100%;
  }

  .tt-product-design02:not(.tt-view) .tt-description > *:nth-child(1) {
    margin-top: 0;
  }

  .tt-product-design02:not(.tt-view)
    .tt-options-swatch
    li
    a:not(.options-color) {
    background: #ffffff;
    color: #191919;
  }

  .tt-product-design02:not(.tt-view) .tt-options-swatch li a.options-color {
    font-size: 0;
    line-height: 0;
  }

  .tt-product-design02:not(.tt-view)
    .tt-options-swatch
    li:hover
    a:not(.options-color) {
    color: #ffffff;
    background: var(--yellow);
  }

  .tt-product-design02:not(.tt-view)
    .tt-options-swatch
    li:hover
    .options-color:before {
    border-color: #ffffff;
  }

  .tt-product-design02:not(.tt-view)
    .tt-options-swatch
    li.active
    a:not(.options-color) {
    color: #ffffff;
    background: var(--yellow);
  }

  .tt-product-design02:not(.tt-view)
    .tt-options-swatch
    li.active
    .options-color:before {
    border-color: #ffffff;
  }

  .tt-product-design02:not(.tt-view) .tt-btn-addtocart {
    font-size: 20px;
    line-height: 1;
    color: var(--yellow);
    font-size: 14px;
    font-weight: 400;
    font-family: "Chakra Petch", sans-serif;
    transition: color 0.2s linear;
    display: inline-block;
    position: relative;
    letter-spacing: 0.04em;
  }

  .tt-product-design02:not(.tt-view) .tt-btn-addtocart:before {
    color: var(--yellow);
    font-size: 20px;
    top: 3px;
    margin-right: 6px;
    font-weight: normal;
    position: relative;
    transition: color 0.2s linear;
  }

  .tt-product-design02:not(.tt-view) .tt-btn-addtocart:hover {
    color: #191919;
  }

  .tt-product-design02:not(.tt-view) .tt-btn-addtocart:hover:before {
    color: #191919;
  }

  .tt-product-design02:not(.tt-view) .tt-btn-quickview {
    font-size: 20px;
    line-height: 1;
    padding: 4px 0 4px 4px;
    color: #191919;
    transition: all 0.2s linear;
    display: inline-block;
    position: absolute;
    right: 18px;
    top: 7px;
    opacity: 0;
    visibility: hidden;
  }

  .tt-product-design02:not(.tt-view) .tt-btn-quickview:hover {
    color: var(--yellow);
  }

  .tt-product-design02:not(.tt-view) .tt-btn-quickview.active {
    color: var(--yellow);
  }

  .tt-product-design02:not(.tt-view) .fa-heart {
    font-size: 16px;
    line-height: 1;
    padding: 4px 0 4px 4px;
    color: #191919;
    transition: all 0.2s linear;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 52px;
    opacity: 0;
    visibility: hidden;
  }

  .tt-product-design02:not(.tt-view) .fa-heart:hover {
    color: var(--yellow);
  }

  .tt-product-design02:not(.tt-view) .fa-heart.active {
    color: var(--yellow);
  }

  .tt-product-design02:not(.tt-view) .fa-cart-plus {
    font-size: 16px;
    line-height: 1;
    padding: 4px 0 4px 4px;
    color: #191919;
    transition: all 0.2s linear;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 96px;
    opacity: 0;
    visibility: hidden;
  }

  .tt-product-design02:not(.tt-view) .fa-cart-plus:hover {
    color: var(--yellow);
  }

  .tt-product-design02:not(.tt-view) .fa-cart-plus.active {
    color: var(--yellow);
  }

  .tt-product-design02:not(.tt-view) .tt-btn-link {
    font-size: 20px;
    line-height: 1;
    padding: 4px 0 4px 4px;
    color: #191919;
    transition: color 0.2s linear;
    display: inline-block;
  }

  .tt-product-design02:not(.tt-view) .tt-btn-link:hover {
    color: var(--yellow);
  }

  .tt-product-design02:not(.tt-view) .tt-description.scrollable {
    padding-top: 0;
    padding-bottom: 0;
  }

  .tt-product-design02:not(.tt-view) .tt-description.scrollable .viewport {
    width: 100%;
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .tt-product-design02:not(.tt-view) .tt-countdown_box {
    visibility: visible;
    transition: opacity 0.2s linear;
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .tt-product-design02:not(.tt-view) .tt-description .tt-btn-link {
    display: none;
  }

  .tt-product-design02:not(.tt-view)
    .tt-description
    .tt-product-inside-hover
    .tt-row-btn:not(:first-child)
    [class^="tt-btn"]:not(.tt-btn-addtocart):first-child {
    margin-left: 0;
    padding-left: 0;
  }

  .tt-product-design02:not(.tt-view) .tt-btn-quickview {
    display: none;
  }
}

@media (min-width: 1025px) {
  .tt-product-design02:not(.tt-view):hover .tt-label-location {
    opacity: 0;
  }

  .tt-product-design02:not(.tt-view):hover .tt-image-box:before {
    opacity: 0.8;
  }

  .tt-product-design02:not(.tt-view):hover .tt-description {
    bottom: 0%;
    opacity: 1;
    visibility: visible;
  }

  .tt-product-design02:not(.tt-view):hover .tt-countdown_box {
    opacity: 0;
    visibility: hidden;
  }

  .tt-product-design02:not(.tt-view):hover > .tt-btn-quickview {
    opacity: 1;
    visibility: visible;
    z-index: 7;
  }

  .tt-product-design02:not(.tt-view):hover > .fa-heart {
    opacity: 1;
    visibility: visible;
    z-index: 7;
  }

  .tt-product-design02:not(.tt-view):hover > .fa-cart-plus {
    opacity: 1;
    visibility: visible;
    z-index: 7;
  }
}

.tt-product:not(.tt-view) .tt-options-swatch,
.tt-product-design02:not(.tt-view) .tt-options-swatch {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.tt-product:not(.tt-view) .tt-text-info,
.tt-product-design02:not(.tt-view) .tt-text-info {
  display: none;
}

.tt-product-design02:not(.tt-view) .tt-options-swatch {
  margin: -10px -1px 0 -5px;
}

@media (min-width: 1025px) {
  .tt-product:not(.tt-view) .tt-options-swatch {
    margin: -10px -1px 2px -1px;
  }

  .tt-product:not(.tt-view) .tt-options-swatch li {
    margin-left: 2px;
    margin-right: 2px;
  }
}

@media (max-width: 1024px) {
  .tt-product:not(.tt-view) .tt-options-swatch {
    margin: -11px -1px 1px -7px;
  }

  .tt-product:not(.tt-view) .tt-options-swatch li {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 16px;
  }
}

@media (min-width: 1025px) {
  .tt-layout-product-item:not(.tt-view) {
    margin-top: -38px;
  }
}

@media (max-width: 1024px) and (min-width: 576px) {
  .tt-layout-product-item:not(.tt-view) {
    margin-top: -40px;
  }
}

@media (max-width: 575px) {
  .tt-layout-product-item:not(.tt-view) {
    margin-top: -30px;
  }
}

@media (min-width: 1025px) {
  .tt-layout-product-item:not(.tt-view) .tt-product,
  .tt-layout-product-item:not(.tt-view) .tt-product-design02,
  .tt-layout-product-item:not(.tt-view) .tt-collection-item {
    margin-top: 38px;
  }
}

@media (max-width: 1024px) and (min-width: 576px) {
  .tt-layout-product-item:not(.tt-view) .tt-product,
  .tt-layout-product-item:not(.tt-view) .tt-product-design02,
  .tt-layout-product-item:not(.tt-view) .tt-collection-item {
    margin-top: 40px;
  }
}

@media (max-width: 575px) {
  .tt-layout-product-item:not(.tt-view) .tt-product,
  .tt-layout-product-item:not(.tt-view) .tt-product-design02,
  .tt-layout-product-item:not(.tt-view) .tt-collection-item {
    margin-top: 30px;
  }
}

.tt-layout-product-item + .tt_product_showmore {
  margin-top: 40px;
}

/* mobile product
   - tt-product
   - tt-product-design02
*/
@media (max-width: 1024px) {
  .tt-product:not(.tt-view) .tt-image-box,
  .tt-product-design02:not(.tt-view) .tt-image-box {
    position: relative;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-img img,
  .tt-product-design02:not(.tt-view) .tt-image-box .tt-img img {
    width: 100%;
    height: auto;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-img-roll-over,
  .tt-product-design02:not(.tt-view) .tt-image-box .tt-img-roll-over {
    display: none;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-label-location,
  .tt-product-design02:not(.tt-view) .tt-image-box .tt-label-location {
    position: absolute;
    top: 7px;
    left: 8px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .tt-product:not(.tt-view)
    .tt-image-box
    .tt-label-location
    [class^="tt-label-"],
  .tt-product-design02:not(.tt-view)
    .tt-image-box
    .tt-label-location
    [class^="tt-label-"] {
    margin-left: 3px;
    margin-top: 3px;
    font-size: 12px;
    line-height: 1;
    color: #ffffff;
    font-weight: 500;
    padding: 3px 4px 1px;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-label-location .tt-label-new,
  .tt-product-design02:not(.tt-view)
    .tt-image-box
    .tt-label-location
    .tt-label-new {
    color: #ffffff;
    background: #1393f5;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-label-location .tt-label-sale,
  .tt-product-design02:not(.tt-view)
    .tt-image-box
    .tt-label-location
    .tt-label-sale {
    color: #ffffff;
    background: #f8353e;
  }

  .tt-product:not(.tt-view)
    .tt-image-box
    .tt-label-location
    .tt-label-our-fatured,
  .tt-product-design02:not(.tt-view)
    .tt-image-box
    .tt-label-location
    .tt-label-our-fatured {
    color: #ffffff;
    background: #fdbc20;
  }

  .tt-product:not(.tt-view)
    .tt-image-box
    .tt-label-location
    .tt-label-our-stock,
  .tt-product-design02:not(.tt-view)
    .tt-image-box
    .tt-label-location
    .tt-label-our-stock {
    color: #ffffff;
    background: #191919;
  }

  .tt-product:not(.tt-view) .tt-description,
  .tt-product-design02:not(.tt-view) .tt-description {
    margin-top: 16px;
  }

  .tt-product:not(.tt-view) .tt-description .tt-row,
  .tt-product-design02:not(.tt-view) .tt-description .tt-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }

  .tt-product:not(.tt-view) .tt-description .tt-row > *:nth-child(1),
  .tt-product-design02:not(.tt-view) .tt-description .tt-row > *:nth-child(1) {
    flex: 1 1 auto;
  }

  .tt-product:not(.tt-view) .tt-description .tt-add-info,
  .tt-product-design02:not(.tt-view) .tt-description .tt-add-info {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .tt-product:not(.tt-view) .tt-description .tt-add-info li,
  .tt-product-design02:not(.tt-view) .tt-description .tt-add-info li {
    font-size: 12px;
    line-height: 19px;
    color: #999999;
  }

  .tt-product:not(.tt-view) .tt-description .tt-add-info li a,
  .tt-product-design02:not(.tt-view) .tt-description .tt-add-info li a {
    color: #999999;
    transition: color 0.2s linear;
  }

  .tt-product:not(.tt-view) .tt-description .tt-add-info li a:hover,
  .tt-product-design02:not(.tt-view) .tt-description .tt-add-info li a:hover {
    color: var(--yellow);
  }

  .tt-product:not(.tt-view) .tt-description .tt-title,
  .tt-product-design02:not(.tt-view) .tt-description .tt-title {
    font-family: "Chakra Petch", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #191919;
  }

  .tt-product:not(.tt-view) .tt-description .tt-title a,
  .tt-product-design02:not(.tt-view) .tt-description .tt-title a {
    color: #191919;
  }

  .tt-product:not(.tt-view) .tt-description .tt-title a:hover,
  .tt-product-design02:not(.tt-view) .tt-description .tt-title a:hover {
    color: var(--yellow);
  }

  .tt-product:not(.tt-view) .tt-description .tt-select,
  .tt-product-design02:not(.tt-view) .tt-description .tt-select {
    background-color: #f7f8fa;
    color: #777777;
    border: none;
    height: 30px;
    width: 100%;
    max-width: 170px;
    padding: 2px 8px;
    cursor: pointer;
    outline: none;
    display: block;
  }

  .tt-product:not(.tt-view) .tt-description .tt-price,
  .tt-product-design02:not(.tt-view) .tt-description .tt-price {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: #191919;
  }

  .tt-product:not(.tt-view) .tt-description .tt-price .new-price,
  .tt-product-design02:not(.tt-view) .tt-description .tt-price .new-price {
    color: #f8353e;
    margin-right: 7px;
  }

  .tt-product:not(.tt-view) .tt-description .tt-price .old-price,
  .tt-product-design02:not(.tt-view) .tt-description .tt-price .old-price {
    color: #191919;
    text-decoration: line-through;
  }

  .tt-product:not(.tt-view) .tt-description .tt-price span,
  .tt-product-design02:not(.tt-view) .tt-description .tt-price span {
    display: inline-block;
  }

  .tt-product:not(.tt-view) .tt-description .tt-option-block,
  .tt-product-design02:not(.tt-view) .tt-description .tt-option-block {
    margin-top: 14px;
  }

  .tt-product:not(.tt-view)
    .tt-description
    .tt-option-block
    > [class^="tt-options-"]:not(:first-child),
  .tt-product-design02:not(.tt-view)
    .tt-description
    .tt-option-block
    > [class^="tt-options-"]:not(:first-child) {
    margin-top: 9px;
  }

  .tt-product:not(.tt-view) .tt-description .tt-product-inside-hover,
  .tt-product-design02:not(.tt-view) .tt-description .tt-product-inside-hover {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-top: 20px;
  }
}

@media (max-width: 1024px) and (min-width: 1025px) {
  .tt-product:not(.tt-view) .tt-description .tt-product-inside-hover,
  .tt-product-design02:not(.tt-view) .tt-description .tt-product-inside-hover {
    visibility: visible;
    max-height: 0;
    overflow: hidden;
  }
}

@media (max-width: 1024px) {
  .tt-product:not(.tt-view)
    .tt-description
    .tt-product-inside-hover
    .tt-row-btn,
  .tt-product-design02:not(.tt-view)
    .tt-description
    .tt-product-inside-hover
    .tt-row-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: stretch;
    align-items: flex-start;
  }

  .tt-product:not(.tt-view)
    .tt-description
    .tt-product-inside-hover
    .tt-row-btn:not(:first-child),
  .tt-product-design02:not(.tt-view)
    .tt-description
    .tt-product-inside-hover
    .tt-row-btn:not(:first-child) {
    margin-top: 10px;
  }

  .tt-product:not(.tt-view) .tt-description .tt-btn-addtocart,
  .tt-product-design02:not(.tt-view) .tt-description .tt-btn-addtocart {
    line-height: 1;
    font-weight: 400;
    font-family: "Chakra Petch", sans-serif;
    display: inline-block;
    position: relative;
    letter-spacing: 0.04em;
    color: #ffffff;
    font-size: 12px;
    padding: 6px 16px ;
    background: var(--main);
    border-radius: 2px;
    transition: background 0.2s linear;
  }

  .tt-product:not(.tt-view) .tt-description .tt-btn-addtocart:before,
  .tt-product-design02:not(.tt-view) .tt-description .tt-btn-addtocart:before {
    font-size: 20px;
    top: 4px;
    margin-right: 4px;
    font-weight: normal;
    position: relative;
    color: #ffffff;
  }

  .tt-product:not(.tt-view) .tt-description .tt-btn-addtocart:hover,
  .tt-product-design02:not(.tt-view) .tt-description .tt-btn-addtocart:hover {
    color: #ffffff;
    background: var(--yellow);
  }

  .tt-product:not(.tt-view) .tt-description .tt-btn-addtocart:hover:before,
  .tt-product-design02:not(.tt-view)
    .tt-description
    .tt-btn-addtocart:hover:before {
    color: #ffffff;
  }
}

@media (max-width: 1024px) and (max-width: 370px) {
  .tt-product:not(.tt-view) .tt-description .tt-btn-addtocart,
  .tt-product-design02:not(.tt-view) .tt-description .tt-btn-addtocart {
    padding: 6px 10px ;
  }

  .tt-product:not(.tt-view) .tt-description .tt-btn-addtocart:before,
  .tt-product-design02:not(.tt-view) .tt-description .tt-btn-addtocart:before {
    margin-right: 2px;
  }
}

@media (max-width: 1024px) {
  .tt-product:not(.tt-view) .tt-description .tt-btn-quickview,
  .tt-product-design02:not(.tt-view) .tt-description .tt-btn-quickview {
    font-size: 20px;
    line-height: 1;
    padding: 4px 6px;
    margin-left: 9px;
    margin-right: 9px;
    color: #191919;
    top: 5px;
    transition: color 0.2s linear;
    display: inline-block;
    position: relative;
  }

  .tt-product:not(.tt-view) .tt-description .tt-btn-quickview.active,
  .tt-product-design02:not(.tt-view) .tt-description .tt-btn-quickview.active {
    color: var(--yellow);
  }

  .tt-product:not(.tt-view) .tt-description .fa-heart,
  .tt-product-design02:not(.tt-view) .tt-description .fa-heart {
    font-size: 16px;
    line-height: 1;
    padding: 4px 6px;
    margin-left: 9px;
    margin-right: 9px;
    color: #191919;
    top: 7px;
    transition: color 0.2s linear;
    display: inline-block;
    position: relative;
  }

  .tt-product:not(.tt-view) .tt-description .fa-heart.active,
  .tt-product-design02:not(.tt-view) .tt-description .fa-heart.active {
    color: var(--yellow);
  }

  .tt-product:not(.tt-view) .tt-description .fa-cart-plus,
  .tt-product-design02:not(.tt-view) .tt-description .fa-cart-plus {
    font-size: 16px;
    line-height: 1;
    padding: 4px 6px;
    margin-left: 9px;
    margin-right: 9px;
    color: #191919;
    top: 7px;
    transition: color 0.2s linear;
    display: inline-block;
    position: relative;
  }

  .tt-product:not(.tt-view) .tt-description .fa-cart-plus.active,
  .tt-product-design02:not(.tt-view) .tt-description .fa-cart-plus.active {
    color: var(--yellow);
  }

  .tt-product:not(.tt-view) .tt-description > *:nth-child(1),
  .tt-product-design02:not(.tt-view) .tt-description > *:nth-child(1) {
    margin-top: 0;
  }
}

@media (max-width: 1024px) and (max-width: 370px) {
  .tt-product:not(.tt-view) .tt-description .fa-cart-plus,
  .tt-product:not(.tt-view) .tt-description .fa-heart,
  .tt-product:not(.tt-view) .tt-description .tt-btn-quickview,
  .tt-product-design02:not(.tt-view) .tt-description .fa-cart-plus,
  .tt-product-design02:not(.tt-view) .tt-description .fa-heart,
  .tt-product-design02:not(.tt-view) .tt-description .tt-btn-quickview {
    margin-left: 3px;
    margin-right: 3px;
  }
}

@media (max-width: 1024px) {
  .tt-product:not(.tt-view) > *:nth-child(1),
  .tt-product-design02:not(.tt-view) > *:nth-child(1) {
    margin-top: 0;
  }
}

@media (max-width: 1024px) {
  .tt-product-design02:not(.tt-view) .tt-description .tt-product-inside-hover {
    align-items: flex-start;
  }
}

@media (max-width: 1024px) {
  .tt-product:not(.tt-view):hover .tt-image-box .tt-img,
  .tt-product-design02:not(.tt-view):hover .tt-image-box .tt-img {
    opacity: 1;
  }

  .tt-product:not(.tt-view):hover .tt-image-box .tt-img-roll-over,
  .tt-product-design02:not(.tt-view):hover .tt-image-box .tt-img-roll-over {
    opacity: 0;
  }
}

/* countdown product item */
.tt-countdown_box {
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 5;
}

.tt-countdown_box .tt-countdown_inner {
  overflow: hidden;
  margin: 0 auto 0%;
  width: 100%;
  padding: 5px 0 10px;
}

.tt-countdown_box .countdown-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.tt-countdown_box .countdown-row .countdown-section {
  width: 25%;
  position: relative;
  margin: 0 2px;
  padding: 19px 0 13px;
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--yellow);
  border-radius: 6px;
}

.tt-countdown_box .countdown-row .countdown-section .countdown-amount {
  font-size: 16px;
  line-height: 15px;
  display: block;
  font-weight: 500;
}

.tt-countdown_box .countdown-row .countdown-section .countdown-period {
  display: block;
  padding-top: 1px;
}

@media (max-width: 1229px) {
  .tt-countdown_box .tt-countdown_inner {
    margin-bottom: 0%;
  }

  .tt-countdown_box .countdown-row .countdown-section {
    padding: 15px 0;
    margin: 0 2px;
    font-size: 13px;
    line-height: 16px;
  }

  .tt-countdown_box .countdown-row .countdown-section .countdown-amount {
    font-size: 15px;
  }

  .tt-countdown_box .countdown-row .countdown-section .countdown-period {
    padding-top: 0;
  }
}

@media (min-width: 1025px) {
  .tt-product-design02.tt-small:not(.tt-view) .tt-description {
    display: block;
    vertical-align: middle;
    padding-left: 13px;
    padding-right: 13px;
  }

  .tt-product-design02.tt-small:not(.tt-view)
    .tt-description
    .tt-btn-addtocart {
    width: 100%;
    display: block;
    padding: 5px 5px 5px 0;
    font-size: 0;
    text-align: left;
  }

  .tt-product-design02.tt-small:not(.tt-view)
    .tt-description
    .tt-btn-quickview {
    margin-top: 5px;
  }

  .tt-product-design02.tt-small:not(.tt-view) .tt-description > *:nth-child(1) {
    margin-top: 27px;
  }

  .tt-product-design02.tt-small:not(.tt-view).thumbprod-center
    .tt-description
    .tt-title {
    text-align: center;
  }

  .tt-product-design02.tt-small:not(.tt-view).thumbprod-center
    .tt-description
    .tt-price {
    justify-content: center;
  }

  .tt-product-design02.tt-small:not(.tt-view).thumbprod-center:not(.tt-small)
    .text-decoration {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
  }
}

@media (min-width: 1025px) {
  .tt-product-design02.thumbprod-center .tt-description {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .tt-product-design02.thumbprod-center .tt-description .tt-row {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .tt-product-design02.thumbprod-center .tt-description .tt-row .tt-add-info {
    width: 100%;
    display: block;
    text-align: center;
  }

  .tt-product-design02.thumbprod-center .tt-description .tt-btn-link {
    position: absolute;
    bottom: 8px;
    right: 20px;
  }

  .tt-product-design02.thumbprod-center
    .tt-description
    .tt-product-inside-hover {
    display: block;
    text-align: center;
  }

  .tt-product-design02.thumbprod-center
    .tt-description
    .tt-description.ps-container {
    height: 100%;
  }

  .tt-product-design02.thumbprod-center .tt-description .tt-options-swatch {
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .tt-product-design02.thumbprod-center .ps-scrollbar-x-rail {
    display: none !important;
  }

  .tt-product-design02.thumbprod-center .tt-description.ps-active-y,
  .tt-product-design02.thumbprod-center .tt-description.ps-active-x,
  .tt-product-design02.thumbprod-center .tt-description.ps-active-x .tt-rating,
  .tt-product-design02.thumbprod-center .tt-description.ps-active-y .tt-rating,
  .tt-product-design02.thumbprod-center .tt-description.ps-active-y > *,
  .tt-product-design02.thumbprod-center .tt-description.ps-active-x > * {
    display: block;
    width: 100%;
    text-align: center;
  }

  .tt-product-design02.thumbprod-center
    .tt-description.ps-active-y
    .tt-product-inside-hover
    .tt-btn-link,
  .tt-product-design02.thumbprod-center
    .tt-description.ps-active-x
    .tt-product-inside-hover
    .tt-btn-link {
    bottom: -5px;
    right: 9px;
  }

  .tt-product-design02.thumbprod-center:hover .tt-description {
    height: 100%;
  }

  .ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail,
  .ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail,
  .ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail,
  .ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail,
  .ps-container:hover > .ps-scrollbar-x-rail,
  .ps-container:hover > .ps-scrollbar-y-rail,
  .ps-container:hover > .ps-scrollbar-x-rail:hover,
  .ps-container:hover > .ps-scrollbar-y-rail:hover {
    background: transparent !important;
  }

  .tt-product-design02.thumbprod-center
    .tt-description.ps-active-y
    .tt-product-inside-hover,
  .tt-product-design02.thumbprod-center
    .tt-description.ps-active-x
    .tt-product-inside-hover,
  .tt-product-design02 .tt-description .tt-option-block,
  .tt-product-design02 .tt-description .description,
  .tt-product-design02 .tt-description .tt-price,
  .tt-product-design02 .tt-description .tt-title,
  .tt-product-design02 .tt-description .tt-row {
    position: relative;
    z-index: 31;
  }

  .tt-product-design02.thumbprod-center.tt-view .tt-description {
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }

  .tt-product-design02.thumbprod-center.tt-view
    .tt-description
    .tt-row
    .tt-add-info {
    text-align: left;
  }

  .tt-product-design02.thumbprod-center.tt-view
    .tt-description
    .tt-product-inside-hover {
    display: flex;
    text-align: left;
  }

  .tt-product-design02.thumbprod-center.tt-view .tt-options-swatch {
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 1024px) and (max-width: 575px) {
  .tt-product:not(.tt-view):not(.thumbprod-center) .tt-row,
  .tt-product-design02:not(.tt-view):not(.thumbprod-center) .tt-row {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .tt-product:not(.tt-view):not(.thumbprod-center) .tt-row .tt-add-info,
  .tt-product-design02:not(.tt-view):not(.thumbprod-center)
    .tt-row
    .tt-add-info {
    width: 100%;
    order: 2;
    margin-top: 4px;
  }

  .tt-product:not(.tt-view):not(.thumbprod-center) .tt-row .tt-rating,
  .tt-product-design02:not(.tt-view):not(.thumbprod-center) .tt-row .tt-rating {
    order: 1;
  }
}

@media (max-width: 1024px) {
  .tt-product:not(.tt-view):not(.thumbprod-center)
    .tt-description
    .tt-product-inside-hover,
  .tt-product-design02:not(.tt-view):not(.thumbprod-center)
    .tt-description
    .tt-product-inside-hover {
    align-items: flex-start;
  }
}

@media (min-width: 1025px) {
  .tt-product:not(.tt-view).product-nohover
    .tt-description
    .tt-product-inside-hover {
    position: relative;
    display: block;
    opacity: 1;
  }
}

@media (min-width: 1025px) {
  .tt-product:not(.tt-view).product-nohover
    .tt-description
    .tt-product-inside-hover {
    position: relative;
    display: block;
    opacity: 1;
  }
}

/*---------------------------------------*/
/*-------  13. Shopping Cart pages  -----*/
/*---------------------------------------*/

/*
	tt-shopcart-box-02 (*shopping_cart_02.html)
*/
.tt-shopcart-table table {
  width: 100%;
}

.tt-shopcart-table table tr {
  border-top: 1px solid #e9e7e7;
  position: relative;
}

.tt-shopcart-table table tr td {
  vertical-align: middle;
  padding: 20px 0;
}

.tt-shopcart-table table tr:last-child {
  border-bottom: 1px solid #e9e7e7;
}

.tt-shopcart-table01 .text-center{
  font-weight: bold;
  color: black;
}
.tt-shopcart-table .tt-btn-close {
  color: #191919;
  font-size: 15px;
  padding: 5px;
  margin-left: -5px;
  display: inline-block;
  transition: color 0.2s linear;
}

.tt-shopcart-table .tt-btn-close:hover {
  color: var(--yellow);
}
.tt-shopcart-table .tt-product-img {
  width: 100%;
  height: 250px;
}
.tt-shopcart-table .tt-product-img img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.tt-shopcart-table .tt-title {
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  font-family: "Chakra Petch", sans-serif;
  /* padding-left: 20px; */
  color: #191919;
}

.tt-shopcart-table .tt-title a {
  color: #191919;
}

.tt-shopcart-table .tt-title a:hover {
  color: var(--yellow);
}

.tt-shopcart-table .tt-list-description {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tt-shopcart-table .tt-list-description li {
  color: #777777;
  font-size: 14px;
}
.tt-shopcart-table .tablebody td {
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
}
.tt-shopcart-table .tt-price {
  color: #191919;
  font-size: 16px;
  font-weight: 500;
}

@media (min-width: 790px) {
  .tt-shopcart-table .tt-shopcart-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin-top: 32px;
  }

  .tt-shopcart-table .tt-shopcart-btn .col-left {
    flex: 2 1 auto;
  }

  .tt-shopcart-table .tt-shopcart-btn *[class^="btn"]:not(:first-child) {
    margin-left: 40px;
  }
}

@media (max-width: 789px) {
  .tt-shopcart-table td .tt-title,
  .tt-shopcart-table td p,
  .tt-shopcart-table td span,
  .tt-shopcart-table td a,
  .tt-shopcart-table td .tt-price {
    font-size: 0.7rem;
  }
  .tt-shopcart-table .tt-shopcart-btn {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 32px;
  }
  .tt-shopcart-table table td:nth-child(2) {
    min-width: 100px;
    text-align: left;
  }
  .tt-shopcart-table table td:nth-child(5) {
    width: 0%;
    display: none;
    min-width: 100px;
    text-align: left;
  }
  .tt-shopcart-table .tt-product-img {
    width: 120px;
    height: 150px;
    object-fit: cover;
  }
  .tt-shopcart-table .tt-product-img img {
    width: 120px;
    height: 130px;
    object-fit: cover;
  }
}

@media (min-width: 790px) {
  .tt-shopcart-table table td:nth-child(1) {
    width: 190px;
  }

  .tt-shopcart-table table td:nth-child(2) {
    width: auto;
    max-width: 20%;
  }

  .tt-shopcart-table table td:nth-child(3) {
    width: 6%;
    min-width: 100px;
  }

  .tt-shopcart-table table td:nth-child(4) {
    width: 17%;
    min-width: 132px;
  }

  .tt-shopcart-table table td:nth-child(5) {
    width: 10%;
    min-width: 100px;
    text-align: left;
  }

  .tt-shopcart-table table td:nth-child(6) {
    width: 5%;

    min-width: 28px;
    text-align: right;
  }
  .tt-shopcart-table table td:nth-child(6) .tt-btn-close {
    font-size: 1.2rem;
    color: #c23321;
  }
  .tt-shopcart-table table td:nth-child(6) .tt-btn-close:hover {
    color: #8b0000;
  }
  .tt-shopcart-table .tt-price:not(.subtotal) {
    margin-right: 24px;
  }

  .tt-shopcart-table .tt-price.subtotal {
    margin-left: 24px;
  }

  .tt-shopcart-table .tt-list-parameters {
    display: none;
  }
}

@media (max-width: 789px) {
  /* .tt-shopcart-table table td:nth-child(1) {
    width: 121px;
  }

  .tt-shopcart-table table td:nth-child(3) {
    width: auto;
  }

  .tt-shopcart-table table td:nth-child(3) {
    display: none;
  }

  .tt-shopcart-table table td:nth-child(4) {
    display: none;
  }

  .tt-shopcart-table table td:nth-child(5) {
    display: none;
  }

  .tt-shopcart-table td:nth-child(3)>*:nth-child(1) {
    margin-top: 0;
  }

  .tt-shopcart-table .tt-list-parameters {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: 11px;
  }

  .tt-shopcart-table .tt-list-parameters li:not(:first-child) {
    margin-top: 11px;
  } */
}

@media (min-width: 576px) {
  .tt-shopcart-wrapper {
    padding: 32px 39px 35px;
  }
}

@media (max-width: 575px) {
  .tt-shopcart-wrapper {
    padding: 25px 24px 23px;
  }
}

@media (max-width: 575px) {
  .tt-shopcart-wrapper .tt-shopcart-box .tt-title {
    font-size: 17px;
  }
}

.tt-shopcart-wrapper .tt-shopcart-box:not(:first-child) {
  margin-top: 25px;
}

@media (max-width: 1229px) {
  .tt-shopcart-wrapper {
    margin-top: 56px;
  }
}

@media (max-width: 1024px) {
  .tt-shopcart-wrapper {
    margin-top: 36px;
  }
}

@media (max-width: 575px) {
  .tt-shopcart-wrapper {
    margin-top: 20px;
  }
}

.tt-shopcart-col {
  margin-top: 56px;
}

@media (max-width: 1024px) {
  .tt-shopcart-col {
    margin-top: 36px;
  }
}

@media (max-width: 575px) {
  .tt-shopcart-col {
    margin-top: 20px;
  }
}

@media (min-width: 790px) {
  .tt-shopcart-col .form-control {
    height: 73px;
  }
}

.tt-shopcart-col .tt-shopcart-box {
  border: 1px solid #e9e7e7;
}

@media (min-width: 576px) {
  .tt-shopcart-col .tt-shopcart-box {
    padding: 32px 39px 35px;
  }
}

@media (max-width: 575px) {
  .tt-shopcart-col .tt-shopcart-box {
    padding: 17px 24px 20px;
  }

  .tt-shopcart-col .tt-shopcart-box .tt-title {
    font-size: 17px;
  }
}

.tt-shopcart-col .tt-shopcart-box {
  margin-top: 20px;
}

/*---------------------------------------*/
/*-------  15. Slider Revolution    -----*/
/*---------------------------------------*/

.slider-revolution .tp-dottedoverlay {
  z-index: 1;
}

.slider-revolution .tp-dottedoverlay.twoxtwo {
  background: none;
}

.slider-revolution .tp-bullets {
  bottom: 7% !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.slider-revolution .tp-bullets .bullet {
  width: 14px;
  height: 14px;
  background-color: #dadada;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
}

.slider-revolution .tp-bullets .bullet.selected {
  background-color: #191919;
}

@media (max-width: 575px) {
  .slider-revolution .tp-bullets {
    display: none;
  }
}

.slider-revolution.slider-revolution__dots-b-r .tp-bullets {
  margin-left: 0 !important;
  bottom: 20px !important;
  right: 20px !important;
  left: inherit !important;
}

.slider-revolution.slider-revolution__dots-color02 .tp-bullets .bullet {
  margin: 0 7px;
  background-color: #ffffff;
}

.slider-revolution.slider-revolution__dots-color02 .tp-bullets .bullet:hover,
.slider-revolution.slider-revolution__dots-color02
  .tp-bullets
  .bullet.selected {
  opacity: 0.5;
}

.slider-revolution.slider-revolution__arrows-none .tp-leftarrow,
.slider-revolution.slider-revolution__arrows-none .tp-rightarrow {
  display: none !important;
}

.slider-revolution .video-play a {
  font-size: 40px;
  line-height: 40px;
  color: #c2c2c2;
}

@media (max-width: 500px) {
  .slider-revolution .video-play a {
    font-size: 35px;
    line-height: 35px;
  }
}

.slider-revolution .video-play a:hover {
  color: var(--yellow);
}

.slider-revolution .video-play .btn-pause {
  display: none;
}

.slider-revolution .video-play.pause .btn-play {
  display: none;
}

.slider-revolution .video-play.pause .btn-pause {
  display: block;
}

.slider-revolution .tp-caption1 {
  text-align: center;
  color: #191919;
}

.slider-revolution .tp-caption1-wd-1 {
  font-weight: 500;
  font-family: "Chakra Petch", sans-serif;
}

.slider-revolution .tp-caption1-wd-2 {
  font-weight: 500;
  font-family: "Chakra Petch", sans-serif;
}

.slider-revolution .tp-caption1-wd-3 {
  font-weight: 300;
  font-family: "Chakra Petch", sans-serif;
}

.slider-revolution [class^="btn"] {
  position: inherit;
  background-color: var(--yellow);
  color: #ffffff;
}

.slider-revolution [class^="btn"]:hover {
  background-color: var(--yellow);
  color: #ffffff;
}

@media (min-width: 1230px) {
  .slider-revolution .tp-caption1-wd-1 {
    font-size: 50px;
    line-height: 1;
  }

  .slider-revolution .tp-caption1-wd-2 {
    font-size: 90px;
    line-height: 1;
    margin-top: 10px;
  }

  .slider-revolution .tp-caption1-wd-3 {
    font-size: 16px;
    line-height: 1;
    margin-top: 15px;
  }

  .slider-revolution .tp-caption1-wd-4 {
    margin-top: 36px;
  }
}

@media (min-width: 1025px) and (max-width: 1229px) {
  .slider-revolution .tp-caption1-wd-1 {
    font-size: 40px;
    line-height: 1;
  }

  .slider-revolution .tp-caption1-wd-2 {
    font-size: 70px;
    line-height: 1;
    margin-top: 5px;
  }

  .slider-revolution .tp-caption1-wd-3 {
    font-size: 16px;
    line-height: 1;
    margin-top: 18px;
  }

  .slider-revolution .tp-caption1-wd-4 {
    margin-top: 36px;
  }
}

@media (min-width: 790px) and (max-width: 1024px) {
  .slider-revolution .tp-caption1-wd-1 {
    font-size: 30px;
    line-height: 1;
  }

  .slider-revolution .tp-caption1-wd-2 {
    font-size: 50px;
    line-height: 1;
    margin-top: 10px;
  }

  .slider-revolution .tp-caption1-wd-3 {
    font-size: 15px;
    line-height: 1.2;
    margin-top: 10px;
  }

  .slider-revolution .tp-caption1-wd-4 {
    margin-top: 27px;
  }
}

@media (min-width: 576px) and (max-width: 789px) {
  .slider-revolution .tp-caption1-wd-1 {
    font-size: 25px;
    line-height: 1;
  }

  .slider-revolution .tp-caption1-wd-2 {
    font-size: 35px;
    line-height: 1;
    margin-top: 10px;
  }

  .slider-revolution .tp-caption1-wd-3 {
    font-size: 14px;
    line-height: 1.3;
    margin-top: 10px;
    max-width: 250px;
  }

  .slider-revolution .tp-caption1-wd-4 {
    margin-top: 17px;
  }
}

@media (min-width: 461px) and (max-width: 575px) {
  .slider-revolution .tp-caption1-wd-1 {
    font-size: 15px;
    line-height: 1.1;
  }

  .slider-revolution .tp-caption1-wd-2 {
    font-size: 25px;
    line-height: 1.1;
    margin-top: 5px;
  }

  .slider-revolution .tp-caption1-wd-3 {
    font-size: 14px;
    line-height: 1.3;
    max-width: 250px;
    margin-top: 7px;
  }

  .slider-revolution .tp-caption1-wd-4 {
    margin-top: 12px;
  }
}

@media (max-width: 460px) {
  .slider-revolution .tp-caption1-wd-1 {
    font-size: 15px;
    line-height: 1.1;
  }

  .slider-revolution .tp-caption1-wd-2 {
    font-size: 19px;
    line-height: 1.1;
    margin-top: 5px;
  }

  .slider-revolution .tp-caption1-wd-3 {
    font-size: 12px;
    line-height: 1.3;
    max-width: 184px;
    margin-top: 7px;
  }

  .slider-revolution .tp-caption1-wd-4 {
    margin-top: 10px;
  }

  .slider-revolution [class^="btn"] {
    height: 34px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.slider-revolution .tp-caption2 {
  text-align: center;
}

.slider-revolution .tp-caption2-wd-1 {
  font-weight: 500;
  font-family: "Chakra Petch", sans-serif;
}

.slider-revolution .tp-caption2-wd-2 {
  font-weight: 500;
  font-family: "Chakra Petch", sans-serif;
}

@media (min-width: 1230px) {
  .slider-revolution .tp-caption2-wd-1 {
    font-size: 50px;
    line-height: 1;
    letter-spacing: 0.03em;
  }

  .slider-revolution .tp-caption2-wd-2 {
    font-size: 90px;
    line-height: 1;
    margin-top: 5px;
    letter-spacing: 0.03em;
  }

  .slider-revolution .tp-caption2-wd-3 {
    font-size: 16px;
    line-height: 1;
    margin-top: 25px;
  }

  .slider-revolution .tp-caption2-wd-4 {
    margin-top: 37px;
  }
}

@media (min-width: 1025px) and (max-width: 1229px) {
  .slider-revolution .tp-caption2-wd-1 {
    font-size: 40px;
    line-height: 1;
  }

  .slider-revolution .tp-caption2-wd-2 {
    font-size: 70px;
    line-height: 1;
    margin-top: 10px;
  }

  .slider-revolution .tp-caption2-wd-3 {
    font-size: 16px;
    line-height: 1;
    margin-top: 18px;
  }

  .slider-revolution .tp-caption2-wd-4 {
    margin-top: 37px;
  }
}

@media (min-width: 790px) and (max-width: 1024px) {
  .slider-revolution .tp-caption2-wd-1 {
    font-size: 30px;
    line-height: 1;
  }

  .slider-revolution .tp-caption2-wd-2 {
    font-size: 50px;
    line-height: 1;
    margin-top: 10px;
  }

  .slider-revolution .tp-caption2-wd-3 {
    font-size: 15px;
    line-height: 1;
    margin-top: 10px;
  }

  .slider-revolution .tp-caption2-wd-4 {
    margin-top: 27px;
  }
}

@media (min-width: 576px) and (max-width: 789px) {
  .slider-revolution .tp-caption2-wd-1 {
    font-size: 25px;
    line-height: 1;
  }

  .slider-revolution .tp-caption2-wd-2 {
    font-size: 30px;
    line-height: 1;
    margin-top: 10px;
  }

  .slider-revolution .tp-caption2-wd-3 {
    font-size: 14px;
    line-height: 1.3;
    margin-top: 10px;
    max-width: 250px;
  }

  .slider-revolution .tp-caption2-wd-4 {
    margin-top: 17px;
  }
}

@media (min-width: 461px) and (max-width: 575px) {
  .slider-revolution .tp-caption2-wd-1 {
    font-size: 15px;
    line-height: 1;
  }

  .slider-revolution .tp-caption2-wd-2 {
    font-size: 25px;
    line-height: 1;
    margin-top: 5px;
  }

  .slider-revolution .tp-caption2-wd-3 {
    font-size: 14px;
    line-height: 1.3;
    max-width: 250px;
    margin-top: 7px;
  }

  .slider-revolution .tp-caption2-wd-4 {
    margin-top: 12px;
  }
}

@media (max-width: 460px) {
  .slider-revolution .tp-caption2-wd-1 {
    font-size: 15px;
    line-height: 1;
  }

  .slider-revolution .tp-caption2-wd-2 {
    font-size: 19px;
    line-height: 1;
    margin-top: 5px;
  }

  .slider-revolution .tp-caption2-wd-3 {
    font-size: 12px;
    line-height: 1.2;
    max-width: 184px;
    margin-top: 7px;
  }

  .slider-revolution .tp-caption2-wd-4 {
    margin-top: 7px;
  }

  .slider-revolution [class^="btn"] {
    height: 34px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.slider-revolution .tp-caption3 {
  text-align: center;
}

.slider-revolution .tp-caption3-wd-1 {
  font-weight: 600;
  font-family: "Chakra Petch", sans-serif;
}

.slider-revolution .tp-caption3-wd-2 {
  font-weight: 600;
  font-family: "Chakra Petch", sans-serif;
  margin-top: 14px;
}

.slider-revolution .tp-caption-list-btn > * {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 1230px) {
  .slider-revolution .tp-caption3-wd-1 {
    font-size: 30px;
    line-height: 1;
    letter-spacing: 0.03em;
  }

  .slider-revolution .tp-caption3-wd-2 {
    font-size: 50px;
    line-height: 1;
    letter-spacing: 0.03em;
  }

  .slider-revolution .tp-caption3-wd-4 {
    margin-top: 37px;
  }
}

@media (min-width: 1025px) and (max-width: 1229px) {
  .slider-revolution .tp-caption3-wd-1 {
    font-size: 40px;
    line-height: 1;
  }

  .slider-revolution .tp-caption3-wd-2 {
    font-size: 70px;
    line-height: 1;
    margin-top: 10px;
  }

  .slider-revolution .tp-caption3-wd-3 {
    font-size: 16px;
    line-height: 1;
    margin-top: 18px;
  }

  .slider-revolution .tp-caption3-wd-4 {
    margin-top: 37px;
  }
}

@media (min-width: 790px) and (max-width: 1024px) {
  .slider-revolution .tp-caption3-wd-1 {
    font-size: 30px;
    line-height: 1;
  }

  .slider-revolution .tp-caption3-wd-2 {
    font-size: 50px;
    line-height: 1;
    margin-top: 10px;
  }

  .slider-revolution .tp-caption3-wd-3 {
    font-size: 15px;
    line-height: 1;
    margin-top: 10px;
  }

  .slider-revolution .tp-caption3-wd-4 {
    margin-top: 27px;
  }
}

@media (min-width: 576px) and (max-width: 789px) {
  .slider-revolution .tp-caption3-wd-1 {
    font-size: 25px;
    line-height: 1;
  }

  .slider-revolution .tp-caption3-wd-2 {
    font-size: 30px;
    line-height: 1;
    margin-top: 10px;
  }

  .slider-revolution .tp-caption3-wd-3 {
    font-size: 14px;
    line-height: 1.3;
    margin-top: 10px;
    max-width: 250px;
  }

  .slider-revolution .tp-caption3-wd-4 {
    margin-top: 17px;
  }
}

@media (min-width: 461px) and (max-width: 575px) {
  .slider-revolution .tp-caption3-wd-1 {
    font-size: 15px;
    line-height: 1;
  }

  .slider-revolution .tp-caption3-wd-2 {
    font-size: 25px;
    line-height: 1;
    margin-top: 5px;
  }

  .slider-revolution .tp-caption3-wd-3 {
    font-size: 14px;
    line-height: 1.3;
    max-width: 250px;
    margin-top: 7px;
  }

  .slider-revolution .tp-caption3-wd-4 {
    margin-top: 12px;
  }
}

@media (max-width: 460px) {
  .slider-revolution .tp-caption3-wd-1 {
    font-size: 15px;
    line-height: 1;
  }

  .slider-revolution .tp-caption3-wd-2 {
    font-size: 19px;
    line-height: 1;
    margin-top: 5px;
  }

  .slider-revolution .tp-caption3-wd-3 {
    font-size: 12px;
    line-height: 1.2;
    max-width: 184px;
    margin-top: 7px;
  }

  .slider-revolution .tp-caption3-wd-4 {
    margin-top: 7px;
  }

  .slider-revolution [class^="btn"] {
    height: 34px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.slider-revolution .tp-caption4 {
  text-align: center;
}

.slider-revolution .tp-caption4-wd-1 {
  font-weight: 500;
  line-height: 1;
}

.slider-revolution .tp-caption4-wd-2 {
  font-weight: 600;
  line-height: 1.14;
}

.slider-revolution .tp-caption-list-btn > * {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 1025px) {
  .slider-revolution .tp-caption4-wd-1 {
    font-size: 16px;
  }

  .slider-revolution .tp-caption4-wd-2 {
    font-size: 36px;
    margin-top: 6px;
  }
}

@media (max-width: 1024px) {
  .slider-revolution .tp-caption4-wd-1 {
    font-size: 16px;
  }

  .slider-revolution .tp-caption4-wd-2 {
    font-size: 32px;
    margin-top: 6px;
  }
}

@media (max-width: 575px) {
  .slider-revolution .tp-caption4-wd-1 {
    font-size: 14px;
    line-height: 1.2;
  }

  .slider-revolution .tp-caption4-wd-2 {
    font-size: 27px;
    margin-top: 6px;
  }
}

.slider-revolution .tp-caption5-wd-1 {
  font-weight: 500;
  line-height: 1;
  color: #ffffff;
  padding-right: 20px;
}

@media (min-width: 1025px) {
  .slider-revolution .tp-caption5-wd-1 {
    font-size: 40px;
    line-height: 44px;
    max-width: 620px;
  }

  .slider-revolution .tp-caption5-btn {
    margin-top: 29px;
  }
}

@media (max-width: 1024px) {
  .slider-revolution .tp-caption5-wd-1 {
    font-size: 32px;
    line-height: 38px;
  }

  .slider-revolution .tp-caption5-btn {
    margin-top: 20px;
  }
}

@media (max-width: 789px) {
  .slider-revolution .tp-caption5-wd-1 {
    font-size: 27px;
    line-height: 37px;
  }

  .slider-revolution .tp-caption5-wd-1 br {
    display: none;
  }

  .slider-revolution .tp-caption5-btn {
    margin-top: 29px;
  }
}

@media (max-width: 575px) {
  .slider-revolution .tp-caption5-wd-1 {
    font-size: 18px;
    line-height: 27px;
  }

  .slider-revolution .tp-caption5-btn {
    margin-top: 9px;
  }
}

.slider-revolution .tp-caption-left-bottom {
  position: absolute;
  max-width: 360px;
  bottom: 20px !important;
  left: 20px !important;
  z-index: 5;
}

@media (max-width: 960px) {
  .slider-revolution .tp-caption-left-bottom {
    max-width: 280px;
  }
}

@media (max-width: 787px) {
  .slider-revolution .tp-caption-left-bottom {
    display: none;
  }
}

.slider-revolution__radius {
  border-radius: 6px;
}

@media (min-width: 790px) {
  .slider-revolution.revolution-default .tparrows.hidearrows {
    opacity: 1;
  }
}

@media (max-width: 789px) {
  .slider-revolution.revolution-default .tparrows {
    display: none;
  }
}

.slider-revolution.revolution-default .tp-leftarrow.default,
.slider-revolution.revolution-default .tp-rightarrow.default {
  background: none;
  font-size: 30px;
  line-height: 1;

  font-weight: 500;
  color: #dadada;
  transition: color 0.2s linear;
}

.slider-revolution.revolution-default .tp-leftarrow.default:hover,
.slider-revolution.revolution-default .tp-rightarrow.default:hover {
  color: #191919;
}

.slider-revolution.revolution-default .tp-leftarrow:before,
.slider-revolution.revolution-default .tp-rightarrow:before {
  position: relative;
  top: 5px;
}

.ie.gecko .fullcoveredvideo {
  -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}

/*
	shopping account page (account.html)
*/
.tt-shopping-layout .tt-title-border {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  padding: 0 0 21px 0;
  letter-spacing: 0.02em;
}

.tt-shopping-layout .tt-title {
  font-size: 20px;
  padding-bottom: 26px;
  letter-spacing: 0.02em;
  color: #191919;
}

.tt-shopping-layout table + *[class^="btn"],
.tt-shopping-layout .tt-table-responsive + *[class^="btn"] {
  margin-top: 30px;
}

.tt-shopping-layout .tt-link-back {
  font-family: "Hind", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #2879fe;
  margin-top: 20px;
  letter-spacing: 0.02em;
  display: inline-block;
  text-decoration: none;
  transition: color 0.2s linear;
}
.tt-shopping-layout .tt-link-back *[class^="icon-"] {
  font-size: 20px;
  top: 4px;
  position: relative;
  margin-right: 5px;
}
.tt-shopping-layout .tt-link-back:hover {
  color: #191919;
}

.tt-shopping-layout .tt-data {
  font-size: 15px;
  margin-top: 17px;
}

.tt-shopping-layout .tt-wrapper {
  margin-top: 55px;
}

.tt-shopping-layout .tt-title + .tt-wrapper {
  margin-top: 30px;
}

.tt-shopping-layout .tt-shop-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  margin-top: 23px;
  margin-left: -37px;
}
.tt-shopping-layout .tt-shop-btn *[class^="btn"] {
  margin-left: 37px;
}
.tt-shopping-layout .tt-shop-btn svg {
  fill: #2879fe;
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
  transition: fill 0.2s linear;
}
.tt-shopping-layout .tt-shop-btn:hover svg {
  fill: #191919;
}

.tt-table-shop-01 {
  border-bottom: 1px solid #e9e7e7;
  width: 100%;
}
.tt-table-shop-01 th,
.tt-table-shop-01 td {
  width: 20%;
  line-height: 17px;
}
.tt-table-shop-01 thead tr {
  border-top: 1px solid #e9e7e7;
}
.tt-table-shop-01 thead th {
  color: #191919;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
  padding: 12px 10px 10px 0;
  font-family: "Hind", sans-serif;
}
.tt-table-shop-01 tbody tr {
  border-top: 1px solid #e9e7e7;
}
.tt-table-shop-01 tbody td {
  padding-top: 11px;
  padding-bottom: 11px;
  padding-right: 10px;
}
.tt-table-shop-01 tbody td a {
  color: #2879fe;
  transition: color 0.2s linear;
}
.tt-table-shop-01 tbody td a:hover {
  color: #191919;
}

.tt-table-shop-02 {
  border-bottom: 1px solid #e9e7e7;
  width: 100%;
}
.tt-table-shop-02 thead tr {
  border-top: 1px solid #e9e7e7;
}
.tt-table-shop-02 thead th {
  color: #191919;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  padding: 13px 10px 7px 0;
  font-family: "Hind", sans-serif;
}
.tt-table-shop-02 tbody tr {
  border-top: 1px solid #e9e7e7;
}
.tt-table-shop-02 tbody td {
  padding-top: 15px;
  padding-bottom: 7px;
  padding-right: 10px;
}
.tt-table-shop-02 tbody td a {
  color: #2879fe;
  transition: color 0.2s linear;
}
.tt-table-shop-02 tbody td a:hover {
  color: #191919;
}
.tt-table-shop-02 tbody td:first-child {
  width: 20%;
  min-width: 134px;
  color: #191919;
  font-weight: 500;
  letter-spacing: 0.02em;
  font-family: "Hind", sans-serif;
  padding-top: 9px;
  padding-bottom: 8px;
}

.tt-table-shop-03 {
  width: 100%;
}
.tt-table-shop-03 tr {
  border-bottom: 1px solid #e9e7e7;
}
.tt-table-shop-03 tr td:nth-child(1),
.tt-table-shop-03 tr th:nth-child(1) {
  width: 35%;
  min-width: 150px;
}
.tt-table-shop-03 tr td:not(:nth-child(1)),
.tt-table-shop-03 tr th:not(:nth-child(1)) {
  width: 21%;
  min-width: 85px;
}
.tt-table-shop-03 td,
.tt-table-shop-03 th {
  vertical-align: top;
  padding: 9px 10px 8px 0;
}
.tt-table-shop-03 strong {
  color: #191919;
  font-weight: 500;
  font-family: "Hind", sans-serif;
}
.tt-table-shop-03 thead tr {
  border-top: 1px solid #e9e7e7;
}
.tt-table-shop-03 thead tr th {
  color: #191919;
  font-weight: 500;
  font-family: "Hind", sans-serif;
}

.tt-shop-info {
  max-width: 317px;
}
.tt-shop-info .tt-item .tt-title {
  font-size: 14px;
  color: #191919;
  font-family: "Hind", sans-serif;
  font-weight: 500;
  letter-spacing: 0.02em;
  margin: 0;
  padding: 0;
}
.tt-shop-info .tt-item .tt-title + .tt-description {
  margin-top: 17px;
}
.tt-shop-info .tt-item .tt-description strong {
  color: #2879fe;
}
.tt-shop-info .tt-item .tt-description p {
  margin-top: 0;
}
.tt-shop-info .tt-item .tt-description a {
  color: #2879fe;
  transition: color 0.2s linear;
}
.tt-shop-info .tt-item .tt-description a:hover {
  color: #191919;
}
.tt-shop-info .tt-item:not(:first-child) {
  margin-top: 47px;
}

/*---------------------------------------*/
/*-------    16. Slider Slick       -----*/
/*---------------------------------------*/

.fadeInLeftSm {
  -webkit-animation-name: fadeInLeftSm;
  animation-name: fadeInLeftSm;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.fadeInRightSm {
  -webkit-animation-name: fadeInRightSm;
  animation-name: fadeInRightSm;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.fadeInUpSm {
  -webkit-animation-name: fadeInUpSm;
  animation-name: fadeInUpSm;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.animated.fadeInLeftSm,
.animated.fadeInRightSm,
.animated.fadeInUpSm {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}

@-webkit-keyframes fadeInUpSm {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpSm {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeInLeftSm {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeftSm {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeInRightSm {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20%, 0, 0);
    transform: translate3d(20%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRightSm {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20%, 0, 0);
    transform: translate3d(20%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.loading-dots {
  margin-left: -1.5em;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-animation: loading-dots-fadein 0.5s linear forwards;
  animation: loading-dots-fadein 0.5s linear forwards;
}

.loading-dots i {
  width: 0.5em;
  height: 0.5em;
  display: inline-block;
  vertical-align: middle;
  background: var(--yellow);
  border-radius: 50%;
  margin: 0 0.125em;
  -webkit-animation: loading-dots-middle-dots 0.5s linear infinite;
  animation: loading-dots-middle-dots 0.5s linear infinite;
}

.loading-dots.dark-gray i {
  background: var(--yellow);
}

.loading-dots i:first-child {
  -webkit-animation: loading-dots-first-dot 0.5s infinite;
  animation: loading-dots-first-dot 0.5s linear infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: translate(-1em);
  transform: translate(-1em);
}

.loading-dots i:last-child {
  -webkit-animation: loading-dots-last-dot 0.5s linear infinite;
  animation: loading-dots-last-dot 0.5s linear infinite;
}

@-webkit-keyframes loading-dots-fadein {
  100% {
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
    filter: none;
  }
}

@keyframes loading-dots-fadein {
  100% {
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
    filter: none;
  }
}

@-webkit-keyframes loading-dots-first-dot {
  100% {
    -webkit-transform: translate(1em);
    transform: translate(1em);
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
    filter: none;
  }
}

@keyframes loading-dots-first-dot {
  100% {
    -webkit-transform: translate(1em);
    transform: translate(1em);
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
    filter: none;
  }
}

@-webkit-keyframes loading-dots-middle-dots {
  100% {
    -webkit-transform: translate(1em);
    transform: translate(1em);
  }
}

@keyframes loading-dots-middle-dots {
  100% {
    -webkit-transform: translate(1em);
    transform: translate(1em);
  }
}

@-webkit-keyframes loading-dots-last-dot {
  100% {
    -webkit-transform: translate(2em);
    transform: translate(2em);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@keyframes loading-dots-last-dot {
  100% {
    -webkit-transform: translate(2em);
    transform: translate(2em);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

/*---------------------------------------*/
/*-------   17. Loader module       -----*/
/*---------------------------------------*/
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 77;
  background-color: #ffffff;
  opacity: 1;
  display: block;
}

#loader-wrapper.loader-off,
body.loaded #loader-wrapper {
  opacity: 0;
  visibility: hidden;
  transition: all 500ms linear 0s;
}

#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  margin-top: -9px;
  margin-left: -100px;
  -webkit-perspective: 400px;
  perspective: 400px;
  transform-type: preserve-3d;
}

#loader .dot {
  -webkit-animation: loader ease-in-out 3s infinite;
  animation: loader ease-in-out 3s infinite;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  background: var(--yellow);
}

#loader .dot:nth-child(2) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

#loader .dot:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

#loader .dot:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

#loader .dot:nth-child(5) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

#loader .dot:nth-child(6) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

#loader .dot:nth-child(7) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

html:not(.ie) #loader .dot {
  border: 2px solid #ffffff;
}

@-webkit-keyframes loader {
  15% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  45% {
    -webkit-transform: translateX(235px);
    transform: translateX(235px);
  }

  65% {
    -webkit-transform: translateX(235px);
    transform: translateX(235px);
  }

  95% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes loader {
  15% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  45% {
    -webkit-transform: translateX(235px);
    transform: translateX(235px);
  }

  65% {
    -webkit-transform: translateX(235px);
    transform: translateX(235px);
  }

  95% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

/*---------------------------------------*/
/*----  25. Instagram shop  ----*/
/*---------------------------------------*/
/* font icon*/
/* menu slick slider */
.arrow-location-03 .slick-arrow {
  top: -27px;
}

.arrow-location-03 .slick-prev {
  left: inherit;
}

.arrow-location-03 .slick-next:before,
.arrow-location-03 .slick-prev:before {
  color: #777777;
  opacity: 1;
}

/*---------------------------------------*/
/*-------     26. Typography        -----*/
/*---------------------------------------*/
html.touch-device {
  overflow-x: hidden;
}

body {
  font-family: "Chakra Petch", sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
  color: #777777;
  background: #ffffff;
  margin: 0;
  overflow-x: hidden;
  background-position: center 0px;
  background-repeat: no-repeat;
}

a {
  text-decoration: none;
  outline: none;
  color: #777777;
}

a:hover {
  outline: none;
  text-decoration: none;
  outline: none;
}

a:active,
a:visited,
a:focus {
  text-decoration: none;
  outline: none;
}

#tt-pageContent .no-gutters.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.btn {
  background: var(--yellow);
  font-family: "Chakra Petch", sans-serif;
  border: none;
  color: #ffffff;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.03em;
  position: relative;
  outline: none;
  padding: 6px 31px 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 40px;
  cursor: pointer;
  border-radius: 6px;
  transition: color 0.2s linear, background-color 0.2s linear;
}

@media (max-width: 575px) {
  .btn {
    font-size: 12px;
    padding-left: 25px;
    padding-right: 25px;
    height: 39px;
  }
}

@media (min-width: 576px) {
  .btn:not(.tt-icon-right) [class^="icon-"] {
    font-size: 20px;
    margin-right: 10px;
  }
}

@media (max-width: 575px) {
  .btn:not(.tt-icon-right) [class^="icon-"] {
    font-size: 18px;
    margin-right: 10px;
  }
}

@media (min-width: 576px) {
  .btn.tt-icon-right [class^="icon-"] {
    font-size: 20px;
    margin-left: 10px;
    position: relative;
    top: -2px;
  }
}

@media (max-width: 575px) {
  .btn.tt-icon-right [class^="icon-"] {
    font-size: 18px;
    margin-left: 10px;
  }
}

.btn:active,
.btn:visited,
.btn:focus,
button:focus {
  outline: none;
  box-shadow: none;
}

.btn:hover {
  background: var(--yellow);
  color: #ffffff;
  outline: none;
}

.btn-lg {
  height: 50px;
  padding: 5px 31px 4px;
}

@media (min-width: 1101px) {
  .btn-xl {
    height: 50px;
    padding: 2px 41px 0px;
  }
}

@media (min-width: 790px) and (max-width: 1100px) {
  .btn-xl {
    height: 40px;
    padding-top: 2px;
    padding-bottom: 0;
  }
}

@media (max-width: 789px) {
  .btn-xl {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 2px;
    padding-bottom: 0;
  }
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.btn.btn-white {
  background: #ffffff;
  color: #191919;
}

.btn.btn-white:hover {
  background: #191919;
  color: #ffffff;
}

.btn.btn-dark {
  background: #191919;
  color: #ffffff;
}

.btn.btn-dark:hover {
  background: var(--yellow);
  color: #ffffff;
}

/* btn-type02 */
.btn-type02 {
  background: linear-gradient(180deg, #7ec0fe 0%, var(--yellow) 100%);
  border: 2px solid #000000;
  font-family: "Chakra Petch", sans-serif;
  color: #ffffff;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.03em;
  position: relative;
  outline: none;
  padding: 6px 31px 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 40px;
  cursor: pointer;
  border-radius: 6px;
  transition: color 0.2s linear;
  position: relative;
  overflow: hidden;
  box-shadow: 10px 20px 10px rgba(0, 0, 0, 0.2);
}

.btn-type02:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s linear;
  background: linear-gradient(180deg, var(--yellow) 0%, #7ec0fe 100%);
}

@media (max-width: 575px) {
  .btn-type02 {
    font-size: 12px;
    padding-left: 25px;
    padding-right: 25px;
    height: 39px;
  }
}

.btn-type02:active,
.btn-type02:visited,
.btn-type02:focus {
  outline: none;
  box-shadow: none;
}

.btn-type02:hover {
  color: #191919;
  outline: none;
}

.btn-type02:hover:before {
  opacity: 1;
}

.btn-type02 .btn-type02_text {
  position: relative;
  z-index: 1;
}

.btn-type02.btn-top {
  margin-top: 61px;
}

@media (max-width: 790px) {
  .btn-type02.btn-top {
    margin-top: 33px;
  }
}

.btn-type02.btn-top02 {
  margin-top: 49px;
}

@media (max-width: 790px) {
  .btn-type02.btn-top02 {
    margin-top: 33px;
  }
}

.btn-type02.btn-lg {
  height: 50px;
  padding-left: 56px;
  padding-right: 56px;
}

/* /btn-type02 */
.btn.btn-border {
  background: transparent;
  color: var(--main);
  border: 2px solid var(--main);
  height: 40px;
  padding-left: 29px;
  padding-right: 29px;
}

.btn.btn-border:hover {
  background: #191919;
  color: #ffffff;
  border-color: #191919;
}

.btn.btn-border01 {
  background: transparent;
  color: #999999;
  border: 2px solid #999999;
  height: 36px;
  padding-left: 29px;
  padding-right: 29px;
  pointer-events: none;
  border-radius: 6px;
}

.btn.btn-border01:hover {
  background: #191919;
  color: #ffffff;
  outline-color: #191919;
}

.btn-link,
.btn-link:focus {
  display: inline-block;
  font-size: 14px;
  color: var(--yellow);
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 0.02em;
  font-family: "Chakra Petch", sans-serif;
  transition: all 0.2s linear;
}

.btn-link *[class^="icon-"],
.btn-link:focus *[class^="icon-"] {
  margin-right: 7px;
  font-size: 20px;
  position: relative;
  top: 3px;
}

.btn-link:hover {
  text-decoration: none;
  color: #191919;
}

.btn-link-02,
.btn-link-02:focus {
  display: inline-block;
  font-size: 14px;
  color: var(--yellow);
  text-decoration: none;
  transition: all 0.2s linear;
}

.btn-link-02:hover {
  text-decoration: none;
  color: #191919;
}

.btn-underline {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 0.04em;
  font-family: "Chakra Petch", sans-serif;
  border-bottom: 1px solid var(--yellow);
  transition: all 0.2s linear;
}

.btn-underline:not([class$="color"]) {
  color: var(--yellow);
}

.btn-underline:hover {
  text-decoration: none;
  color: #191919;
  border-bottom-color: transparent;
}

.btn-underline.tt-white-color {
  color: #ffffff;
  border-color: #ffffff;
}

.btn-underline.tt-white-color:hover {
  color: #191919;
  border-color: transparent;
}

.disable {
  opacity: 0.3;
  pointer-events: none;
}

@media (min-width: 790px) {
  .btn-top {
    margin-top: 40px;
  }
}

@media (max-width: 789px) and (min-width: 576px) {
  .btn-top {
    margin-top: 30px;
  }
}

@media (max-width: 575px) {
  .btn-top {
    margin-top: 20px;
  }
}

.tt-link {
  color: var(--yellow);
  transition: all 0.2s linear;
}

.tt-link:hover {
  color: #191919;
}

.btn.btn-small {
  height: 35px;
  padding: 6px 29px 3px;
}

@media (min-width: 418px) {
  .btn.btn-small {
    padding-bottom: 5px;
  }
}

.btn.btn-small [class^="icon-"] {
  position: relative;
  top: -2px;
}

.btn-transparent {
  opacity: 0.9;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  position: relative;
  font-family: "Chakra Petch", sans-serif;
  color: #191919;
  margin-bottom: 0px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #191919;
  transition: color 0.2s linear, background-color 0.2s linear;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: var(--yellow);
}

h1:not(.tt-title-subpages):not([class^="tt-title"]):not(
    [class^="tt-collapse"]
  ):not([class^="tt-aside"]) {
  font-size: 46px;
  line-height: 60px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 17px;
}

@media (max-width: 1024px) {
  h1:not(.tt-title-subpages):not([class^="tt-title"]):not(
      [class^="tt-collapse"]
    ):not([class^="tt-aside"]) {
    font-size: 36px;
    line-height: 46px;
  }
}

@media (max-width: 575px) {
  h1:not(.tt-title-subpages):not([class^="tt-title"]):not(
      [class^="tt-collapse"]
    ):not([class^="tt-aside"]) {
    font-size: 24px;
    line-height: 34px;
    padding-bottom: 21px;
  }
}

h2:not(.small):not([class^="tt-title"]):not([class^="tt-collapse"]):not(
    [class^="tt-aside"]
  ) {
  font-size: 34px;
  line-height: 44px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 23px;
}

@media (max-width: 1024px) {
  h2:not(.small):not([class^="tt-title"]):not([class^="tt-collapse"]):not(
      [class^="tt-aside"]
    ) {
    font-size: 29px;
    line-height: 39px;
    padding-bottom: 18px;
  }
}

@media (max-width: 575px) {
  h2:not(.small):not([class^="tt-title"]):not([class^="tt-collapse"]):not(
      [class^="tt-aside"]
    ) {
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 10px;
  }
}

h3:not([class^="tt-title"]):not([class^="tt-collapse"]):not(
    [class^="tt-aside"]
  ),
h2.small:not([class^="tt-title"]):not([class^="tt-collapse"]):not(
    [class^="tt-aside"]
  ) {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  padding-bottom: 23px;
}

@media (max-width: 1024px) {
  h3:not([class^="tt-title"]):not([class^="tt-collapse"]):not(
      [class^="tt-aside"]
    ),
  h2.small:not([class^="tt-title"]):not([class^="tt-collapse"]):not(
      [class^="tt-aside"]
    ) {
    font-size: 26px;
    line-height: 36px;
    padding-bottom: 17px;
  }
}

@media (max-width: 575px) {
  h3:not([class^="tt-title"]):not([class^="tt-collapse"]):not(
      [class^="tt-aside"]
    ),
  h2.small:not([class^="tt-title"]):not([class^="tt-collapse"]):not(
      [class^="tt-aside"]
    ) {
    font-size: 19px;
    line-height: 29px;
    padding-bottom: 9px;
  }
}

h4:not([class^="tt-title"]):not([class^="tt-collapse"]):not(
    [class^="tt-aside"]
  ) {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 24px;
}

@media (max-width: 1024px) {
  h4:not([class^="tt-title"]):not([class^="tt-collapse"]):not(
      [class^="tt-aside"]
    ) {
    font-size: 24px;
    line-height: 34px;
    padding-bottom: 16px;
  }
}

@media (max-width: 575px) {
  h4:not([class^="tt-title"]):not([class^="tt-collapse"]):not(
      [class^="tt-aside"]
    ) {
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 8px;
  }
}

h5:not([class^="tt-title"]):not([class^="tt-collapse"]):not(
    [class^="tt-aside"]
  ) {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 15px;
}

@media (max-width: 1024px) {
  h5:not([class^="tt-title"]):not([class^="tt-collapse"]):not(
      [class^="tt-aside"]
    ) {
    font-size: 22px;
    line-height: 32px;
    padding-bottom: 15px;
  }
}

@media (max-width: 575px) {
  h5:not([class^="tt-title"]):not([class^="tt-collapse"]):not(
      [class^="tt-aside"]
    ) {
    font-size: 17px;
    line-height: 27px;
    padding-bottom: 7px;
  }
}

h6:not([class^="tt-title"]):not([class^="tt-collapse"]):not(
    [class^="tt-aside"]
  ) {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 15px;
}

@media (max-width: 575px) {
  h6:not([class^="tt-title"]):not([class^="tt-collapse"]):not(
      [class^="tt-aside"]
    ) {
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 6px;
  }
}

.tt-title-subpages:not(.noborder) {
  border-bottom: 1px solid #e9e7e7;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: 0.003em;
  font-weight: 500;
  padding: 0 0 50px 0;
  margin-bottom: 46px;
  margin-top: -10px;
}

@media (max-width: 1024px) {
  .tt-title-subpages:not(.noborder) {
    font-size: 33px;
    line-height: 43px;
    padding: 0 0 32px 0;
    margin-bottom: 28px;
  }
}

@media (max-width: 575px) {
  .tt-title-subpages:not(.noborder) {
    font-size: 24px;
    line-height: 34px;
    padding: 0 0 26px 0;
    margin-bottom: 22px;
  }
}

.tt-title-subpages.noborder {
  font-size: 34px;
  line-height: 44px;
  letter-spacing: 0.03em;
  font-weight: 500;
  padding: 0 0 50px 0;
  margin-top: -10px;
}

@media (max-width: 1024px) {
  .tt-title-subpages.noborder {
    font-size: 33px;
    line-height: 43px;
    padding: 0 0 32px 0;
  }
}

@media (max-width: 575px) {
  .tt-title-subpages.noborder {
    font-size: 24px;
    line-height: 34px;
    padding-bottom: 26px;
  }
}

.tt-title-subpages:not(.text-left):not(.text-right) {
  text-align: center;
}

.tt-title-border {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding: 0 0 12px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid #e9e7e7;
}

@media (max-width: 575px) {
  .tt-title-border {
    margin-bottom: 24px;
  }
}

p {
  margin: 16px 0 0 0px;
}

@media (max-width: 575px) {
  p {
    margin: 10px 0 0 0px;
  }
}

mark,
.mark {
  background: #191919;
  color: #ffffff;
  padding: 2px 4px;
  border-radius: 6px;
}

hr {
  border: 0;
  border-top: 1px solid #e9e7e7;
}

hr:not([class]) {
  margin-top: 53px;
  margin-bottom: 53px;
}

@media (max-width: 1024px) {
  hr:not([class]) {
    margin-top: 35px;
    margin-bottom: 34px;
  }
}

@media (max-width: 575px) {
  hr:not([class]) {
    margin-top: 27px;
    margin-bottom: 26px;
  }
}

hr.hr-01 {
  margin-top: 53px;
  margin-bottom: 46px;
}

@media (max-width: 1024px) {
  hr.hr-01 {
    margin-top: 35px;
    margin-bottom: 33px;
  }
}

@media (max-width: 575px) {
  hr.hr-01 {
    margin-top: 27px;
    margin-bottom: 26px;
  }
}

address {
  margin: 0;
}

audio {
  width: 100%;
  display: block;
}

audio:not(:first-child) {
  margin-top: 34px;
}

@media (max-width: 1229px) {
  audio:not(:first-child) {
    margin-top: 28px;
  }
}

@media (max-width: 575px) {
  audio:not(:first-child) {
    margin-top: 22px;
  }
}

@media (max-width: 575px) {
  .col-12-575width {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (max-width: 440px) {
  .col-12-440width {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

input::-ms-clear {
  display: none;
}

@media (max-width: 500px) {
  .tt-table-responsive {
    overflow-y: hidden;
    overflow-x: scroll;
  }
}

@media (max-width: 789px) {
  .tt-table-responsive-md {
    overflow-y: hidden;
    overflow-x: scroll;
  }
}

.nomargin,
#tt-pageContent .nomargin {
  margin-top: 0;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

@media (min-width: 790px) {
  .width-90 {
    width: 89%;
  }
}

.link {
  color: var(--yellow);
  transition: all 0.2s linear;
}

.link:hover {
  text-decoration: none;
  color: #191919;
}

.divider {
  height: 46px;
  clear: both;
  overflow: hidden;
  width: 100%;
}

@media (max-width: 1024px) {
  .divider {
    height: 40px;
  }
}

@media (max-width: 575px) {
  .divider {
    height: 32px;
  }
}

.divider-sm {
  height: 20px;
  clear: both;
  overflow: hidden;
  width: 100%;
}

.tt-logo {
  display: inline-block;
}

.tt-logo img {
  height: 100px;
  max-height: 100%;
}

/* tt-box-info */
.tt-box-info {
  line-height: 22px;
}

.tt-box-info p {
  margin-top: 6px;
}

.tt-box-info > *:nth-child(1) {
  margin-top: 0;
}

/* list */
.tt-list-dash {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tt-list-dash > li {
  font-size: 14px;
  position: relative;
  padding: 0 0 0 19px;
  color: #777777;
}

.tt-list-dash > li:not(:first-child) {
  margin-top: 6px;
}

.tt-list-dash > li:before {
  content: "";
  width: 9px;
  height: 1px;
  background: #777777;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

.tt-list-dash ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tt-list-dash ul > li {
  font-size: 14px;
  position: relative;
  padding: 6px 0 0 10px;
}

.tt-list-dash ul > li:before {
  content: "";
  width: 5px;
  height: 1px;
  background: #777777;
  display: inline-block;
  position: absolute;
  top: 16px;
  left: -3px;
}

.tt-list-dot {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tt-list-dot > li {
  font-size: 14px;
  position: relative;
  padding: 0 0 0 11px;
  color: #777777;
}

.tt-list-dot > li a {
  color: #191919;
  transition: all 0.2s linear;
}

.tt-list-dot > li a:hover {
  color: var(--yellow);
}

.tt-list-dot > li:not(:first-child) {
  margin-top: 6px;
}

.tt-list-dot > li:before {
  content: "";
  width: 3px;
  height: 3px;
  background: var(--yellow);
  display: inline-block;
  position: absolute;
  top: 8px;
  left: 0;
  border-radius: 50%;
}

.tt-list-dot ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0px 0 0 8px;
}

.tt-list-dot ul > li {
  font-size: 14px;
  position: relative;
  padding: 6px 0 0 9px;
}

.tt-list-dot ul > li:before {
  content: "";
  width: 3px;
  height: 3px;
  background: #191918;
  display: inline-block;
  position: absolute;
  top: 14px;
  left: -2px;
  border-radius: 50%;
}

.tt-list-dot.list-dot-large > li {
  padding-left: 13px;
  color: #191919;
}

.tt-list-dot.list-dot-large > li:before {
  width: 7px;
  height: 7px;
  top: 6px;
}

.tt-list-number {
  list-style: none;
  margin: 0;
  padding: 0;
  counter-reset: li;
}

.tt-list-number li {
  color: #777777;
}

.tt-list-number li:before {
  counter-increment: li;
  content: counters(li, ".") " ";
  color: #777777;
}

.tt-list-number li:not(:first-child) {
  margin-top: 6px;
}

.tt-list-number ol {
  padding: 6px 0 0px 24px;
  margin: 0;
  counter-reset: li;
  list-style: none;
}

.tt-list-number ol li:before {
  color: #777777;
}

/* blockquote */
.tt-blockquote {
  background: #f7f8fa;
  padding: 29px 25px 26px;
  margin: 0;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
}

.tt-blockquote .tt-icon {
  font-size: 36px;
  line-height: 26px;
  color: var(--yellow);
}

.tt-blockquote .tt-title {
  font-size: 30px;
  line-height: 40px;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  font-style: italic;
  color: #191919;
  display: inline-block;
  margin-top: 15px;
  transition: all 0.2s linear;
}

.tt-blockquote .tt-title-description {
  font-size: 12px;
  display: block;
  margin-top: 14px;
  color: #999999;
}

.tt-blockquote .tt-title-description span {
  color: #191919;
}

.tt-blockquote > *:nth-child(1) {
  margin-top: 0;
}

@media (max-width: 1024px) {
  .tt-blockquote {
    padding-left: 15px;
    padding-right: 15px;
  }

  .tt-blockquote .tt-icon {
    font-size: 34px;
  }

  .tt-blockquote .tt-title {
    font-size: 24px;
    line-height: 34px;
  }
}

@media (max-width: 575px) {
  .tt-blockquote .tt-icon {
    font-size: 33px;
  }

  .tt-blockquote .tt-title {
    font-size: 21px;
    line-height: 31px;
  }
}

.tt-blockquote.add-hover:hover .tt-title {
  color: var(--yellow);
}

.tt-blockquote:not(:first-child) {
  margin-top: 21px;
}

.tt-blockquote:not(.text-left):not(.text-right) {
  text-align: center;
}

.tt-blockquote-02 {
  position: relative;
  padding: 0 0 0 50px;
  margin: 0;
}

.tt-blockquote-02 .tt-icon {
  position: absolute;
  top: 1px;
  left: -2px;
  font-size: 36px;
  line-height: 36px;
}

.tt-blockquote-02 .tt-title {
  font-size: 30px;
  line-height: 40px;
  font-family: "Chakra Petch", sans-serif;
  font-style: italic;
  font-weight: 500;
}

.tt-blockquote-02 .tt-title-description {
  font-size: 14px;
  display: inline-block;
  margin-top: 9px;
  font-weight: 400;
  letter-spacing: 0.01em;
}

@media (max-width: 575px) {
  .tt-blockquote-02 {
    padding: 0;
  }

  .tt-blockquote-02 .tt-icon {
    position: relative;
  }

  .tt-blockquote-02 .tt-title {
    margin-top: 3px;
  }

  .tt-blockquote-02 .tt-title-description {
    margin-top: 6px;
  }
}

.tt-blockquote-02 > *:nth-child(1) {
  margin-top: 0;
}

/* table */
.tt-table-01 {
  width: 100%;
  border-top: 1px solid #e9e7e7;
  border-bottom: 1px solid #e9e7e7;
}

.tt-table-01 thead tr th {
  font-weight: 500;
  color: #191919;
  font-size: 14px;
  line-height: 17px;
  font-family: "Chakra Petch", sans-serif;
  letter-spacing: 0.03em;
  padding: 12px 0 10px;
}

.tt-table-01 thead tr th:first-child {
  width: 12%;
  max-width: 68px;
  padding-right: 10px;
}

.tt-table-01 thead tr th:not(:first-child) {
  width: 44%;
  padding-right: 10px;
}

.tt-table-01 tbody tr {
  border-top: 1px solid #e9e7e7;
}

.tt-table-01 tbody tr td {
  padding: 11px 0 11px;
  line-height: 17px;
}

.tt-table-01 tbody tr td:first-child {
  width: 12%;
  max-width: 68px;
  padding-right: 10px;
}

.tt-table-01 tbody tr td:not(:first-child) {
  width: 44%;
  padding-right: 10px;
}

/* table */
.tt-table-02 {
  width: 100%;
  border-top: 1px solid #e9e7e7;
  border-bottom: 1px solid #e9e7e7;
}

.tt-table-02 tbody tr {
  border-top: 1px solid #e9e7e7;
}

.tt-table-02 tbody tr td:first-child {
  color: #191919;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-right: 10px;
}

.tt-table-02 tbody tr td {
  line-height: 17px;
}

.tt-table-02 tbody tr td:first-child {
  width: 31%;
  max-width: 179px;
  padding: 12px 9px 10px 0;
}

.tt-table-02 tbody tr td:not(:first-child) {
  padding-right: 10px;
  padding: 13px 0 9px;
}

/* tt-table-03 (product-variable.html)*/
.tt-table-03 {
  width: 100%;
}

.tt-table-03 tbody tr td:first-child {
  color: #191919;
  width: 74px;
  padding: 0 10px 0 0;
}

.tt-table-03 tbody tr td {
  padding-top: 3px;
  padding-bottom: 3px;
}

.tt-table-03 tbody tr:last-child td {
  padding-bottom: 0;
}

/*
Content
*/
#tt-pageContent > .container-indent0:not(.nomargin):not([class^="tt-offset"]) {
  margin-top: 32px;
}

@media (max-width: 1024px) {
  #tt-pageContent
    > .container-indent0:not(.nomargin):not([class^="tt-offset"]) {
    margin-top: 43px;
  }
}

@media (max-width: 575px) {
  #tt-pageContent
    > .container-indent0:not(.nomargin):not([class^="tt-offset"]) {
    margin-top: 33px;
  }
}

#tt-pageContent > .container-indent:not(.nomargin):not([class^="tt-offset"]) {
  margin-top: 52px;
}

@media (max-width: 1024px) {
  #tt-pageContent > .container-indent:not(.nomargin):not([class^="tt-offset"]) {
    margin-top: 43px;
  }
}

@media (max-width: 575px) {
  #tt-pageContent > .container-indent:not(.nomargin):not([class^="tt-offset"]) {
    margin-top: 33px;
  }
}

#tt-pageContent > .container-indent1:not(.nomargin):not([class^="tt-offset"]) {
  margin-top: 63px;
}

@media (max-width: 1024px) {
  #tt-pageContent
    > .container-indent1:not(.nomargin):not([class^="tt-offset"]) {
    margin-top: 43px;
  }
}

@media (max-width: 575px) {
  #tt-pageContent
    > .container-indent1:not(.nomargin):not([class^="tt-offset"]) {
    margin-top: 33px;
  }
}

#tt-pageContent > .container-inner {
  padding-top: 53px;
  padding-bottom: 53px;
}

#tt-pageContent > .tt-offset-small {
  margin-top: 20px;
}

#tt-pageContent .del-img {
  width: 100%;
  height: auto;
}

#tt-pageContent .del-item-top {
  margin-top: 33px;
}

@media (max-width: 1024px) {
  #tt-pageContent .del-item-top {
    margin-top: 28px;
  }
}

@media (max-width: 575px) {
  #tt-pageContent .del-item-top {
    margin-top: 23px;
  }
}

#tt-pageContent .container-fluid {
  padding-right: 10px;
  padding-left: 10px;
}

@media (max-width: 575px) {
  .container-fluid-custom:not(.container-fluid-custom-mobile-padding) {
    padding-right: 0;
    padding-left: 0;
    overflow: hidden;
  }

  #tt-pageContent .container-fluid-custom-mobile-padding {
    padding-right: 20px;
    padding-left: 20px;
  }

  .container-fluid-custom-mobile-padding-02 {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.container-fluid-custom {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
}

@media (max-width: 1024px) {
  .container-fluid-lg {
    max-width: 100% !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

@media (max-width: 1024px) and (max-width: 575px) {
  .container-fluid-lg {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/* tt-block-title */
.tt-block-title {
  text-align: center;
  padding-bottom: 32px;
}

.tt-block-title .tt-title {
  font-size: 24px;
  line-height: 31px;
  font-weight: 500;
  letter-spacing: 0.04em;
  margin: 0;
  padding: 0;
  color: #191919;
}

.tt-block-title .tt-title a {
  color: var(--yellow);
  text-decoration: none;
  display: inline-block;
  position: relative;
  transition: border 0.2s linear;
}

.tt-block-title .tt-title a:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 2px;
  height: 2px;
  width: 100%;
  background: var(--yellow);
}

.tt-block-title .tt-title a:hover {
  border-color: transparent;
  color: var(--yellow);
}

.tt-block-title .tt-title a:hover:before {
  background: transparent;
}

.tt-block-title .tt-title-small {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: #191919;
}

.tt-block-title .tt-title-small a {
  color: var(--yellow);
  text-decoration: none;
  display: inline-block;
  border-bottom: 2px solid var(--yellow);
  transition: border 0.2s linear;
}

.tt-block-title .tt-title-small a:hover {
  color: var(--yellow);
  border-color: transparent;
}

.tt-block-title .tt-description {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.04em;
  font-family: "Chakra Petch", sans-serif;
  color: #191919;
  margin: 0px 0 0 0;
  padding: 0;
}

@media (max-width: 575px) {
  .tt-block-title {
    padding-bottom: 23px;
  }
}

/* tt-title-sub */
.tt-title-sub {
  font-size: 16px;
  padding-bottom: 34px;
  margin-top: 4px;
  font-weight: 500;
  letter-spacing: 0.01em;
}

@media (max-width: 575px) {
  .tt-title-sub {
    padding-bottom: 22px;
  }
}

/* box-faq */
.tt-box-faq-listing {
  margin-top: -26px;
}

.tt-box-faq-listing .tt-title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 15px;
}

.tt-box-faq-listing .tt-box-faq {
  margin-top: 47px;
  letter-spacing: 0.0063em;
}

.tt-box-faq-listing .tt-box-faq .tt-title {
  color: #191919;
}

.tt-box-faq-listing .tt-box-faq .tt-title a {
  color: #191919;
}

.tt-box-faq-listing .tt-box-faq .tt-title a:hover {
  color: var(--yellow);
}

.tt-box-faq-listing .tt-box-faq p {
  margin-top: 16px;
}

@media (min-width: 790px) {
  .tt-box-faq-listing .tt-box-faq {
    padding-right: 35px;
  }
}

.tt-box-faq-listing .tt-box-faq:last-child {
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .tt-box-faq-listing {
    margin-top: -19px;
  }

  .tt-box-faq-listing .tt-box-faq {
    margin-top: 24px;
  }
}

/* demo-typography */
.demo-typography {
  padding-top: 7px;
}

.demo-typography h1 {
  margin-top: 38px;
}

@media (max-width: 1024px) {
  .demo-typography h1 {
    margin-top: 23px;
  }
}

@media (max-width: 575px) {
  .demo-typography h1 {
    margin-top: 19px;
  }
}

.demo-typography .tt-box-text {
  margin-top: 43px;
}

@media (max-width: 1024px) {
  .demo-typography .tt-box-text {
    margin-top: 25px;
  }
}

@media (max-width: 575px) {
  .demo-typography .tt-box-text {
    margin-top: 23px;
  }
}

.demo-typography .tt-blockquote {
  margin-top: 33px;
  margin-bottom: 33px;
}

@media (max-width: 1024px) {
  .demo-typography .tt-blockquote {
    margin-top: 28px;
    margin-bottom: 28px;
  }
}

@media (max-width: 575px) {
  .demo-typography .tt-blockquote {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

/* .tt-about-box */
@media (min-width: 791px) {
  .tt-about-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
    color: #ffffff;
    padding: 208px 0 216px 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .tt-about-box .img-mobile {
    display: none;
  }

  .tt-about-box .tt-title {
    color: #ffffff;
    font-size: 34px;
    line-height: 44px;
    letter-spacing: 0.02em;
    font-weight: 500;
    padding-bottom: 0;
  }

  .tt-about-box p {
    margin-top: 23px;
    max-width: 75%;
  }

  .tt-about-box .tt-blockquote-02 {
    margin-top: 44px;
    color: #ffffff;
  }

  .tt-about-box .tt-blockquote-02 .tt-icon {
    color: #ffffff;
  }

  .tt-about-box .tt-blockquote-02 .tt-title {
    font-size: 28px;
    line-height: 40px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #ffffff;
  }
}

@media (min-width: 791px) and (max-width: 1229px) {
  .tt-about-box {
    padding: 110px 0 118px 0;
  }

  .tt-about-box .tt-title {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.02em;
  }

  .tt-about-box p {
    max-width: 100%;
  }

  .tt-about-box .tt-blockquote-02 .tt-title {
    font-size: 22px;
    line-height: 27px;
  }
}

@media (max-width: 790px) {
  .tt-about-box {
    background: none !important;
  }

  .tt-about-box > .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .tt-about-box .img-mobile {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 32px;
  }

  .tt-about-box .tt-title {
    font-size: 24px;
    line-height: 34px;
    margin-top: 32px;
    letter-spacing: 0.02em;
    padding-bottom: 4px;
  }

  .tt-about-box .tt-blockquote-02 {
    margin-top: 25px;
    color: #191919;
  }

  .tt-about-box .tt-blockquote-02 .tt-icon {
    color: var(--yellow);
  }

  .tt-about-box .tt-blockquote-02 .tt-title {
    margin-top: 0px;
    padding-bottom: 0;
    font-size: 23px;
  }
}

.tt-about-box div[class^="col-"] > *:nth-child(1) {
  margin-top: 0;
}

@media (min-width: 576px) {
  html.tt-boxed .tt-about-box .container {
    padding-right: 40px;
    padding-left: 40px;
  }
}

.tt-about-col-list {
  margin-top: -52px;
}

.tt-about-col-list .tt-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 15px;
}

.tt-about-col-list div[class^="col"] {
  margin-top: 52px;
}

@media (max-width: 1024px) {
  .tt-about-col-list div[class^="col"] {
    margin-top: 42px;
  }
}

@media (max-width: 575px) {
  .tt-about-col-list div[class^="col"] {
    margin-top: 25px;
  }
}

@media (max-width: 1024px) {
  .tt-about-col-list {
    margin-top: -42px;
  }
}

@media (max-width: 790px) {
  .tt-about-col-list {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 575px) {
  .tt-about-col-list {
    margin-top: -25px;
  }
}

.tt-about02-col-list {
  margin-top: -52px;
}

.tt-about02-col-list .tt-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 15px;
}

.tt-about02-col-list div[class^="col"] {
  margin-top: 52px;
}

@media (max-width: 1024px) {
  .tt-about02-col-list div[class^="col"] {
    margin-top: 42px;
  }
}

@media (max-width: 575px) {
  .tt-about02-col-list div[class^="col"] {
    margin-top: 25px;
  }
}

@media (max-width: 1024px) {
  .tt-about02-col-list {
    margin-top: -42px;
  }
}

@media (max-width: 575px) {
  .tt-about02-col-list {
    margin-top: -25px;
  }
}

/* tt-about-box-02 */
.tt-about-box-02 .tt-title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  letter-spacing: 0em;
  margin-top: -4px;
}

@media (max-width: 1229px) {
  .tt-about-box-02 .tt-title {
    font-size: 25px;
    line-height: 35px;
  }
}

@media (max-width: 789px) {
  .tt-about-box-02 .tt-title {
    font-size: 22px;
    line-height: 32px;
  }
}

/* tt-col-img */
.tt-col-img {
  margin-top: -20px;
}

.tt-col-img img {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

/* tt-contact-box */
@media (min-width: 791px) {
  .tt-contact-box {
    /* background-image: url("../images/custom/contact-img-01.jpg"); */
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    text-align: center;
    color: red;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 302px 15px 320px;
    background-size: cover;
  }

  .tt-contact-box a {
    color: #ffffff;
  }

  .tt-contact-box a:hover {
    color: var(--yellow);
  }

  .tt-contact-box .tt-title {
    color: #ffffff;
    font-size: 34px;
    line-height: 44px;
    margin: 0;
    padding: 0;
    font-weight: 500;
    letter-spacing: 0.03em;
  }

  .tt-contact-box address {
    color: #ffffff;
    margin: 24px 0 0 0;
  }
}

@media (min-width: 791px) and (max-width: 1229px) {
  .tt-contact-box {
    padding-top: 202px;
    padding-bottom: 220px;
  }

  .tt-contact-box .tt-title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (min-width: 791px) {
  .tt-contact-box .img-mobile {
    display: none;
  }
}

@media (max-width: 790px) {
  .tt-contact-box {
    background: none !important;
  }

  .tt-contact-box .img-mobile {
    width: 100%;
    height: auto;
    margin-bottom: 32px;
  }

  .tt-contact-box .tt-title {
    font-size: 24px;
    line-height: 34px;
  }

  .tt-contact-box address {
    margin-top: 14px;
  }

  .tt-contact-box .container > *:nth-child(1) {
    margin-top: 0;
  }
}

.tt-contact-box div[class^="col-"] > *:nth-child(1) {
  margin-top: 0;
}

.tt-contact-col-list {
  margin-top: -51px;
}

.tt-contact-col-list .tt-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 15px;
}

.tt-contact-col-list div[class^="col"] {
  margin-top: 51px;
}

@media (max-width: 1024px) {
  .tt-contact-col-list div[class^="col"] {
    margin-top: 42px;
  }
}

@media (max-width: 790px) {
  .tt-contact-col-list div[class^="col"] {
    margin-top: 24px;
  }
}

@media (max-width: 1024px) {
  .tt-contact-col-list {
    margin-top: -42px;
  }
}

@media (max-width: 575px) {
  .tt-contact-col-list {
    margin-top: -25px;
  }
}

.tt-contact02-col-list {
  margin-top: -51px;
}

.tt-contact02-col-list div[class^="col"] {
  margin-top: 51px;
}

@media (max-width: 1024px) {
  .tt-contact02-col-list div[class^="col"] {
    margin-top: 42px;
  }
}

@media (max-width: 575px) {
  .tt-contact02-col-list div[class^="col"] {
    margin-top: 25px;
  }
}

@media (max-width: 1024px) {
  .tt-contact02-col-list {
    margin-top: -42px;
  }
}

@media (max-width: 575px) {
  .tt-contact02-col-list {
    margin-top: -25px;
  }
}

/* tt-contact-info */
.tt-contact-info:not(.text-left):not(.text-right) {
  text-align: center;
}

.tt-contact-info i {
  font-size: 55px;
  line-height: 1.1;
  color: var(--main);
}

.tt-contact-info .tt-title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.03em;
  color: #191919;
  margin: 15px 0 0 0;
  padding-bottom: 0;
}
.contact .socials {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact .socials ul li a i {
  color: var(--main);
  margin: 0 1rem;
  font-size: 2.8rem;
}
.tt-contact-info address {
  font-size: 1rem;
  margin: 15px 0 0 0;
  letter-spacing: 0.01em;
}

@media (max-width: 1229px) {
  .tt-contact-info .tt-icon {
    font-size: 55px;
  }

  .tt-contact-info .tt-title {
    font-size: 20px;
    line-height: 30px;
    margin: 15px 0 0 0;
    padding-bottom: 0;
  }
}

@media (max-width: 575px) {
  .tt-contact-info {
    padding-bottom: 0px;
  }

  .tt-contact-info .tt-icon {
    font-size: 48px;
  }

  .tt-contact-info .tt-title {
    font-size: 18px;
    line-height: 28px;
    margin: 5px 0 0 0;
  }

  .tt-contact-info address {
    margin-top: 8px;
  }
}

.tt-contact-info > *:nth-child(1) {
  margin-top: 0;
}

/* map */
.contact-map {
  width: 100%;
}

.contact-map #map {
  height: 450px;
  width: 100%;
}

/* form */
::-webkit-input-placeholder {
  color: #777777;
}

::-moz-placeholder {
  color: #777777;
}

:-moz-placeholder {
  color: #777777;
}

:-ms-input-placeholder {
  color: #777777;
}

input:not(.tt-search-input):focus::-webkit-input-placeholder {
  color: transparent;
}

input:not(.tt-search-input):focus:-moz-placeholder {
  color: transparent;
}

input:not(.tt-search-input):focus::-moz-placeholder {
  color: transparent;
}

input:not(.tt-search-input):focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

/* form-default */
.form-default .form-group {
  margin-bottom: 20px;
}

.form-default .form-group label:not(.error) {
  font-size: 14px;
  color: #191919;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  padding-bottom: 4px;
  letter-spacing: 0.02em;
}

.form-default .form-control {
  background: #f7f8fa;
  font-size: 14px;
  border: 1px solid #f7f8fa;
  padding: 11px 12px 10px 15px;
  font-family: "Chakra Petch", sans-serif;
  box-shadow: none;
  outline: none;
  color: #777777;
  font-weight: 300;
  border-radius: 6px;
  transition: all 0.2s linear;
}

.form-default .form-control:not(textarea) {
  height: 40px;
}

@media (max-width: 575px) {
  .form-default .form-control:not(textarea) {
    height: 39px;
  }
}

.form-default input.form-control,
.form-default textarea.form-control {
  -webkit-appearance: none;
}

.form-default select.form-control {
  padding: 10px 12px 11px 15px;
  cursor: pointer;
}

.form-default select.form-control:not([size]):not([multiple]) {
  height: auto;
}

.form-default .form-control.error {
  border-color: red;
}

.form-default .form-control:focus {
  border-color: var(--yellow);
  background: #ffffff;
}

.form-default .tt-required {
  display: inline-block;
  float: right;
  color: #777777;
  padding-left: 5px;
}

.list-form-inline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0 0 0 -15px;
}

.list-form-inline li {
  margin-left: 15px;
}

.list-form-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0 0 0 -15px;
}

.list-form-column li {
  margin-left: 15px;
}

/* checkbox-group */
.checkbox-group {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.checkbox-group input[type="checkbox"] {
  display: none;
}

.checkbox-group label {
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px;
  color: #191919;
  transition: all 0.2s linear;
}

.checkbox-group label span {
  display: block;
  position: absolute;
  left: 0;
  transition-duration: 0.12s;
}

.checkbox-group label .box {
  background: #f7f8fa;
  border-radius: 2px;
  height: 17px;
  width: 17px;
  z-index: 9;
  margin-top: 2px;
  border: 1px solid #cdcdcd;
}

.checkbox-group label .check {
  top: 1px;
  left: 0px;
  opacity: 0;
  z-index: 10;
  -webkit-transform: scale(0);
  transform: scale(0);
  background: var(--yellow);
  border-radius: 2px;
  width: 17px;
  height: 17px;
  line-height: 1;
}

.checkbox-group label .check:before {
  color: #ffffff;
  border-radius: 2px;
  font-size: 15px;
  line-height: 1;
  position: relative;
  left: 1px;
  top: 1px;

  font-style: normal;
  font-weight: 400;
}

.checkbox-group:hover label {
  color: var(--yellow);
}

input[type="checkbox"]:checked ~ label .check {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* radio-group */
.radio {
  display: block;
  cursor: pointer;
  margin: 0 0 8px;
  transition: all 0.15s linear;
  color: #777777;
}

.radio input {
  width: 1px;
  height: 1px;
  opacity: 0;
}

.radio input:focus + .outer .inner {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  background-color: var(--yellow);
}

.radio .outer {
  width: 16px;
  height: 16px;
  display: block;
  float: left;
  margin: 3px 9px 0 0;
  background-color: #f7f8fa;
  border: 1px solid #cdcdcd;
  border-radius: 50%;
}

.radio .inner {
  width: 10px;
  height: 10px;
  top: 2px;
  left: 2px;
  display: block;
  background-color: var(--yellow);
  opacity: 0;
  position: relative;
  -webkit-transform: scale(0);
  transform: scale(0);
  border-radius: 50%;
  transition: all 0.2s linear;
}

.radio input:checked + .outer .inner {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.radio:hover {
  color: var(--yellow);
}

.radio:hover .inner {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* contact-form */
.contact-form {
  margin-top: 39px;
}

.contact-form textarea {
  height: 160px;
}

.contact-form .btn {
  margin-top: 20px;
}

@media (max-width: 575px) {
  .contact-form {
    margin-top: 33px;
  }
}

.contact-form p {
  padding-bottom: 20px;
}

.contact-form .form-group {
  position: relative;
}

.contact-form label.error {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
  position: absolute;
  top: 56px;
  left: 0;
  padding: 8px 19px 6px 43px;
  background: #f8353e;
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
  z-index: 1;
}

.contact-form label.error:before {
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  position: absolute;
  top: 7px;
  left: 19px;
  color: #ffffff;
}

.contact-form label.error:after {
  content: "";
  position: absolute;
  top: -12px;
  left: 50%;
  border: 6px solid transparent;
  border-bottom: 6px solid #f8353e;
}

/* tt-box-thumb (services.html)*/
.tt-box-thumb-listing {
  margin-top: -53px;
}

@media (max-width: 1024px) {
  .tt-box-thumb-listing {
    margin-top: -35px;
  }
}

@media (max-width: 1024px) {
  .tt-box-thumb-listing {
    margin-top: -28px;
  }
}

.tt-box-thumb {
  margin-top: 53px;
  text-align: center;
}

.tt-box-thumb .tt-img {
  overflow: hidden;
  display: block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.tt-box-thumb .tt-img img {
  width: 100%;
  height: auto;
}

.tt-box-thumb .tt-title {
  margin-top: 22px;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.03em;
  line-height: 30px;
  padding: 0;
  color: #191919;
}

.tt-box-thumb .tt-title a {
  color: #191919;
}

.tt-box-thumb .tt-title a:hover {
  color: var(--yellow);
}

.tt-box-thumb p {
  margin-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

@media (max-width: 1024px) {
  .tt-box-thumb {
    margin-top: 35px;
  }

  .tt-box-thumb .tt-title {
    font-size: 18px;
    line-height: 28px;
    margin-top: 19px;
  }

  .tt-box-thumb p {
    margin-top: 11px;
  }
}

@media (max-width: 575px) {
  .tt-box-thumb {
    margin-top: 23px;
  }

  .tt-box-thumb .tt-title {
    font-size: 16px;
    line-height: 26px;
    margin-top: 16px;
  }

  .tt-box-thumb p {
    margin-top: 7px;
  }
}

.tt-box-thumb > *:nth-child(1) {
  margin-top: 0;
}

.tt-page404 {
  background: #ffffff;
  color: #191919;
  text-align: center;
  padding: 79px 20px 103px;
}

.tt-page404 img {
  max-width: 100%;
  height: auto;
}

.tt-page404 .tt-title {
  color: #191919;
  padding: 0;
  margin: 42px 0 0 0;
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 0.03em;
  line-height: 44px;
}

.tt-page404 p {
  margin-top: 2px;
  font-size: 15px;
}

.tt-page404 .btn {
  margin-top: 34px;
}

@media (max-width: 1024px) {
  .tt-page404 img {
    max-width: 400px;
  }

  .tt-page404 .tt-title {
    font-size: 28px;
    line-height: 40px;
  }
}

@media (max-width: 789px) {
  .tt-page404 {
    padding: 35px 20px 59px;
  }

  .tt-page404 img {
    width: 350px;
    max-width: 70%;
  }

  .tt-page404 .tt-title {
    font-size: 25px;
    line-height: 34px;
  }
}

@media (max-width: 575px) {
  .tt-page404 .tt-title {
    font-size: 23px;
    line-height: 32px;
    margin: 28px 0 0 0;
  }

  .tt-page404 .btn {
    margin-top: 25px;
  }
}

.tt-page404 > *:nth-child(1) {
  margin-top: 0;
}

/* tt-empty-wishlist*/
.tt-empty-wishlist {
  text-align: center;
  padding: 102px 20px 102px;
  color: #777777;
}

.tt-empty-wishlist img {
  max-width: 100%;
  height: auto;
}

.tt-empty-wishlist .icon-svg {
  margin: 17px 0 0 0;
}

.tt-empty-wishlist .icon-svg svg {
  width: 58px;
  height: 58px;
}

.tt-empty-wishlist .tt-title {
  color: #191919;
  padding: 0;
  margin: 13px 0 0 0;
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 0.03em;
  line-height: 44px;
}

.tt-empty-wishlist p {
  color: #777777;
  font-size: 26px;
  line-height: 1.3;
  font-weight: 400;
}

.tt-empty-wishlist .btn {
  margin-top: 34px;
}

@media (max-width: 1024px) {
  .tt-empty-wishlist {
    padding: 71px 20px 82px;
  }

  .tt-empty-wishlist .tt-title {
    font-size: 28px;
    line-height: 38px;
  }
}

@media (max-width: 575px) {
  .tt-empty-wishlist {
    padding: 51px 15px 62px;
  }

  .tt-empty-wishlist .tt-title {
    font-size: 24px;
    line-height: 34px;
  }

  .tt-empty-wishlist p {
    font-size: 22px;
  }
}

.tt-empty-wishlist > *:nth-child(1) {
  margin-top: 0;
}

/* tt-empty-cart*/
.tt-empty-cart {
  text-align: center;
  padding: 102px 20px 102px;
  color: #777777;
}

.tt-empty-cart img {
  max-width: 100%;
  height: auto;
}

.tt-empty-cart .tt-icon {
  font-size: 150px;
  line-height: 1.1;
  color: #ebecee;
}

.tt-empty-cart .tt-title {
  color: #191919;
  padding: 0;
  margin: 13px 0 0 0;
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 0.03em;
  line-height: 44px;
}

.tt-empty-cart p {
  margin-top: 2px;
  font-size: 15px;
}

.tt-empty-cart .btn {
  margin-top: 34px;
}

@media (max-width: 1024px) {
  .tt-empty-cart {
    padding: 71px 20px 82px;
  }

  .tt-empty-cart .tt-icon {
    font-size: 130px;
  }

  .tt-empty-cart .tt-title {
    font-size: 28px;
    line-height: 38px;
  }
}

@media (max-width: 575px) {
  .tt-empty-cart {
    padding: 51px 15px 62px;
  }

  .tt-empty-cart .tt-icon {
    font-size: 120px;
  }

  .tt-empty-cart .tt-title {
    font-size: 24px;
    line-height: 34px;
  }
}

.tt-empty-cart > *:nth-child(1) {
  margin-top: 0;
}

/* tt-empty-search*/
.tt-empty-search {
  text-align: center;
  padding: 106px 20px 102px;
  color: #777777;
}

.tt-empty-search img {
  max-width: 100%;
  height: auto;
}

.tt-empty-search .tt-icon {
  font-size: 150px;
  line-height: 1.1;
  color: #ebecee;
}

.tt-empty-search .tt-title {
  color: #191919;
  padding: 0;
  margin: 9px 0 0 0;
  font-size: 34px;
  font-weight: 500;
  line-height: 44px;
}

.tt-empty-search p {
  margin-top: 2px;
  font-size: 15px;
}

.tt-empty-search .btn {
  margin-top: 34px;
}

@media (max-width: 1024px) {
  .tt-empty-search {
    padding: 71px 20px 82px;
  }

  .tt-empty-search .tt-icon {
    font-size: 130px;
  }

  .tt-empty-search .tt-title {
    font-size: 28px;
    line-height: 38px;
  }
}

@media (max-width: 575px) {
  .tt-empty-search {
    padding: 51px 15px 62px;
  }

  .tt-empty-search .tt-icon {
    font-size: 120px;
  }

  .tt-empty-search .tt-title {
    font-size: 24px;
    line-height: 34px;
  }
}

.tt-empty-search > *:nth-child(1) {
  margin-top: 0;
}

/* tt-coming-soon */
.tt-coming-soon {
  padding: 27px 0 90px 0;
  overflow: auto;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  text-align: center;
}

@media (min-width: 734px) {
  .tt-coming-soon {
    height: 100vh;
  }
}

.tt-coming-soon form .form-group {
  display: inline-flex;
}

.tt-coming-soon form .form-control {
  min-width: 254px;
  margin-top: 20px;
  -webkit-border-top-right-radius: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-radius-topright: 0px;
  -moz-border-radius-bottomright: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.tt-coming-soon form .btn {
  margin-top: 20px;
  -webkit-border-top-left-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  -moz-border-radius-bottomleft: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.tt-coming-soon h1.tt-title {
  font-size: 34px;
  line-height: 47px;
  margin: 54px 0 0 0;
  padding: 0;
  font-weight: 500;
  letter-spacing: 0.03em;
  color: #191919;
}

.tt-coming-soon h2.tt-title {
  font-size: 16px;
  line-height: 26px;
  margin: 31px 0 0 0;
  padding: 0 0 2px 0;
  font-weight: 500;
  color: #191919;
  width: 100%;
}

.tt-coming-soon .description {
  max-width: 584px;
  margin: 21px auto 47px;
}

.tt-coming-soon .tt-coming-soon-content > *:nth-child(1) {
  margin-top: 0;
}

@media (max-width: 1229px) {
  .tt-coming-soon {
    padding-top: 17px;
  }

  .tt-coming-soon h1.tt-title {
    font-size: 30px;
    line-height: 40px;
  }

  .tt-coming-soon .description {
    margin-bottom: 37px;
  }

  .tt-coming-soon form {
    margin-top: 0px;
  }

  .tt-coming-soon h2.tt-title {
    margin-top: 21px;
  }
}

@media (min-width: 1025px) {
  .tt-coming-soon {
    /* background: url("../images/custom/comingsoon-img-01.jpg") center top
      no-repeat; */
    background-size: cover;
  }
}

@media (max-width: 1024px) {
  .tt-coming-soon {
    padding-top: 15px;
    padding-bottom: 27px;
  }

  .tt-coming-soon h1.tt-title {
    font-size: 29px;
    line-height: 39px;
    margin-top: 35px;
  }

  .tt-coming-soon .description {
    margin-bottom: 27px;
  }

  .tt-coming-soon form .form-control,
  .tt-coming-soon form .btn {
    margin-top: 13px;
  }

  .tt-coming-soon form .form-group {
    margin-bottom: 13px;
  }
}

@media (max-width: 575px) {
  .tt-coming-soon {
    padding-bottom: 10px;
  }

  .tt-coming-soon h1.tt-title {
    font-size: 23px;
    line-height: 33px;
    margin-top: 15px;
  }

  .tt-coming-soon .description {
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .tt-coming-soon form .form-group {
    display: block;
  }

  .tt-coming-soon form .form-control {
    margin-top: 13px;
  }

  .tt-coming-soon form .btn {
    margin-top: 13px;
  }

  .tt-coming-soon form .form-group {
    margin-bottom: 7px;
  }

  .tt-coming-soon form .form-control {
    border-radius: 6px;
  }

  .tt-coming-soon form .btn {
    border-radius: 6px;
  }
}

.coming-soon-bottom {
  position: fixed;
  bottom: 72px;
  width: 100%;
  left: 0;
}

.coming-soon-bottom p {
  font-size: 12px;
}

.coming-soon-bottom .tt-social-icon {
  justify-content: center;
  margin-top: 11px;
}

@media (max-width: 1229px) {
  .coming-soon-bottom {
    bottom: 52px;
  }
}

@media (max-width: 1024px) {
  .coming-soon-bottom {
    bottom: 22px;
  }

  .coming-soon-bottom .tt-social-icon {
    margin-top: 5px;
  }
}

@media (max-width: 789px) {
  .coming-soon-bottom {
    position: inherit;
    bottom: inherit;
    margin-top: 25px;
  }
}

/* social-icon */
.tt-social-icon {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tt-social-icon li a {
  font-size: 1.7rem;
  line-height: 26px;
  color: #d8d8d8;
  padding: 4px;
  transition: all 0.2s linear;
}

.tt-social-icon li a:hover {
  color: #595959;
}

.tt-social-icon li:first-child {
  margin-right: 5px;
}

.tt-social-icon li:last-child {
  margin-left: 5px;
}

.tt-social-icon li:not(:last-child):not(:first-child) {
  margin: 0 5px;
}

/* login-form-box */
.tt-login-form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}

.tt-login-form .tt-item {
  border: 1px solid #e9e7e7;
  padding: 32px 39px 20px;
  height: 100%;
}

.tt-login-form .tt-item .tt-title {
  font-size: 20px;
  line-height: 30px;
  padding: 0 0 14px 0;
  font-weight: 500;
}

.tt-login-form .tt-item p {
  margin-top: 0;
}

.tt-login-form .tt-item .form-top {
  margin-top: 17px;
}

.tt-login-form .tt-item .btn:not(.btn-top) {
  margin-top: 4px;
  margin-bottom: 5px;
}

.tt-login-form .tt-item .btn-top {
  margin-top: 25px;
}

.tt-login-form .tt-item .btn {
  margin-right: 12px;
}

.tt-login-form .tt-item .additional-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
}

.tt-login-form .tt-item .additional-links a {
  color: var(--yellow);
  transition: all 0.2s linear;
}

.tt-login-form .tt-item .additional-links a:hover {
  color: #191919;
}

@media (max-width: 789px) {
  .tt-login-form {
    display: block;
    align-items: flex-start;
    margin-top: -20px;
  }

  .tt-login-form .tt-item:first-child {
    margin-top: 20px;
    height: auto;
  }
}

@media (max-width: 440px) {
  .tt-login-form .tt-item {
    padding: 20px 20px 10px;
  }
}

/*
	slick
*/
/*tt-carousel-products*/
.tt-carousel-products.slick-slider .slick-slide[class^="col-"] {
  max-width: inherit;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-animated-show-js {
  opacity: 0;
  transition: opacity 0.2s linear;
}

.slick-animated-show-js.slick-initialized {
  opacity: 1;
}

.slick-track {
  margin: auto;
}

.tt-slick-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
}

/* (blog-single-post-gallery.html) */
.tt-slick-button {
  font-size: 0;
  line-height: 0;
}

.tt-slick-button .slick-arrow {
  z-index: 7;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  background: none;
  border: none;
  width: 38px;
  height: 38px;
  background: #f7f8fa;
  color: #191919;
  font-weight: 500;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.tt-slick-button .slick-arrow:hover {
  background: var(--yellow);
  color: #ffffff;
}

.tt-slick-button .slick-arrow:before {
  font-size: 20px;
  line-height: 1;
}

.tt-slick-button .slick-next {
  margin-left: 4px;
}

/* slick (product.html) */
.tt-slick-button-vertical {
  font-size: 0;
  line-height: 0;
}

.tt-slick-button-vertical .slick-arrow {
  z-index: 7;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  background: none;
  border: none;
  width: 38px;
  height: 38px;
  background: #f7f8fa;
  color: #191919;
  font-weight: 500;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.tt-slick-button-vertical .slick-arrow:hover {
  background: var(--yellow);
  color: #ffffff;
}

.tt-slick-button-vertical .slick-arrow:before {
  font-size: 20px;
  line-height: 1;
}

.tt-slick-button-vertical .slick-next {
  left: 42px;
}

.tt-slick-quantity {
  font-size: 14px;
  line-height: 1;
  color: #989898;
  font-family: "Chakra Petch", sans-serif;
}

.tt-slick-quantity .account-number {
  font-size: 20px;
  color: #191919;
}

/* arrow-location-01 (blog listing pages)*/
.arrow-location-01 .slick-arrow {
  position: absolute;
  bottom: 10px;
  z-index: 7;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  background: none;
  border: none;
  width: 38px;
  height: 38px;
  background: #ffffff;
  color: #191919;
  font-weight: 500;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.arrow-location-01 .slick-arrow:hover {
  background: var(--yellow);
  color: #ffffff;
}

.arrow-location-01 .slick-arrow:before {
  font-size: 20px;
  line-height: 1;
}

.arrow-location-01 .slick-prev {
  right: 57px;
}

.arrow-location-01 .slick-next {
  right: 10px;
}

/* arrow-location-02 (product-02.html)*/
.arrow-location-02 .slick-arrow {
  position: absolute;
  bottom: -58px;
  z-index: 7;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  background: none;
  border: none;
  width: 38px;
  height: 38px;
  background: #f7f8fa;
  color: #191919;
  font-weight: 500;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.arrow-location-02 .slick-arrow:hover {
  background: var(--yellow);
  color: #ffffff;
}

.arrow-location-02 .slick-arrow:before {
  font-size: 20px;
  line-height: 1;
}

.arrow-location-02 .slick-prev {
  left: 0px;
}

.arrow-location-02 .slick-next {
  left: 47px;
}

/* arrow-location-03 (desktop menu)*/
.arrow-location-03 .slick-arrow {
  position: absolute;
  top: -46px;
  z-index: 7;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  background: none;
  border: none;
  width: 38px;
  height: 38px;
  font-weight: 500;
  color: #191919;
  background: transparent;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.arrow-location-03 .slick-arrow:hover {
  color: var(--yellow);
  background: transparent;
}

.arrow-location-03 .slick-arrow:before {
  font-size: 20px;
  line-height: 1;
}

.arrow-location-03 .slick-prev {
  right: 20px;
}

.arrow-location-03 .slick-next {
  right: -4px;
}

/* arrow-location-tab (index pages) */
.arrow-location-tab .slick-arrow {
  position: absolute;
  z-index: 7;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  background: none;
  border: none;
  width: 38px;
  height: 38px;
  color: #191919;
  background: #f7f8fa;
  font-weight: 500;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.arrow-location-tab .slick-arrow:hover {
  background: var(--yellow);
  color: #ffffff;
}

.arrow-location-tab .slick-arrow:before {
  font-size: 20px;
  line-height: 1;
}

.arrow-location-tab .slick-prev:before {
  margin-right: 2px;
}

.arrow-location-tab .slick-next:before {
  margin-left: 2px;
}

@media (min-width: 1025px) {
  .arrow-location-tab.arrow-location-tab01 .slick-arrow {
    top: -43px;
  }

  .arrow-location-tab .slick-arrow {
    top: -33px;
  }

  .arrow-location-tab .slick-prev {
    right: 57px;
  }

  .arrow-location-tab .slick-next {
    right: 10px;
  }
}

@media (max-width: 1024px) {
  .arrow-location-tab .slick-arrow {
    top: 50%;
  }

  .arrow-location-tab .slick-prev {
    left: 0px;
  }

  .arrow-location-tab .slick-next {
    right: 0px;
  }
}

/* arrow-location-right-top (product single pages)*/
.arrow-location-right-top .slick-arrow {
  position: absolute;
  z-index: 7;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  background: none;
  border: none;
  width: 38px;
  height: 38px;
  background: #f7f8fa;
  color: #191919;
  font-weight: 500;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.arrow-location-right-top .slick-arrow:hover {
  background: var(--yellow);
  color: #ffffff;
}

.arrow-location-right-top .slick-arrow:before {
  font-size: 20px;
  line-height: 1;
}

@media (min-width: 1025px) {
  .arrow-location-right-top .slick-arrow {
    top: -28px;
  }

  .arrow-location-right-top .slick-prev {
    right: 57px;
  }

  .arrow-location-right-top .slick-next {
    right: 10px;
  }
}

@media (max-width: 1024px) {
  .arrow-location-right-top .slick-arrow {
    top: 50%;
  }

  .arrow-location-right-top .slick-prev {
    left: 0px;
  }

  .arrow-location-right-top .slick-next {
    right: 0px;
  }
}

/* arrow-location-center (quickViewModal)*/
.arrow-location-center .slick-arrow,
.arrow-location-center .slick-arrow:active {
  position: absolute;
  top: 50%;
  margin-top: -19px;
  z-index: 7;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  background: none;
  border: none;
  width: 38px;
  height: 38px;
  color: #dadada;
  font-weight: 500;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.arrow-location-center .slick-arrow:hover {
  color: #191919;
}

.arrow-location-center .slick-arrow:before {
  font-size: 20px;
  line-height: 1;
}

.arrow-location-center .slick-prev {
  left: 0px;
}

.arrow-location-center .slick-next {
  right: 0px;
}

/* arrow-location-center-02 (index-07.html)*/
.arrow-location-center-02 .slick-arrow,
.arrow-location-center-02 .slick-arrow:active {
  position: absolute;
  top: 50%;
  margin-top: -19px;
  z-index: 7;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  background: none;
  border: none;
  width: 38px;
  height: 38px;
  background: #f7f8fa;
  color: #191919;
  font-weight: 500;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.arrow-location-center-02 .slick-arrow:hover {
  color: #ffffff;
  background: var(--yellow);
}

.arrow-location-center-02 .slick-arrow:before {
  font-size: 20px;
  line-height: 1;
}

.arrow-location-center-02 .slick-prev {
  left: 0px;
}

.arrow-location-center-02 .slick-prev:before {
  margin-right: 2px;
}

.arrow-location-center-02 .slick-next {
  right: 0px;
}

.arrow-location-center-02 .slick-next:before {
  margin-left: 2px;
}

/* arrow-location-center-02 (index-07.html)*/
.arrow-location-center-03 .slick-arrow,
.arrow-location-center-03 .slick-arrow:active {
  position: absolute;
  top: 50%;
  z-index: 7;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  background: none;
  border: none;
  width: 38px;
  height: 38px;
  background: #f7f8fa;
  color: #191919;
  font-weight: 500;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.arrow-location-center-03 .slick-arrow:hover {
  color: #ffffff;
  background: var(--yellow);
}

.arrow-location-center-03 .slick-arrow:before {
  font-size: 20px;
  line-height: 1;
}

.arrow-location-center-03 .slick-prev {
  left: 0px;
}

.arrow-location-center-03 .slick-prev:before {
  margin-right: 2px;
}

.arrow-location-center-03 .slick-next {
  right: 0px;
}

.arrow-location-center-03 .slick-next:before {
  margin-left: 2px;
}

@media (min-width: 1025px) and (max-width: 1229px) {
  .arrow-location-center-03 .slick-arrow {
    top: 92px !important;
  }
}

@media (min-width: 1230px) {
  .arrow-location-center-03 .slick-arrow {
    top: 118px !important;
  }
}

body:not(.touch-device) .arrow-location-center-02.tt-arrow-hover .slick-arrow {
  opacity: 0;
}

body:not(.touch-device)
  .arrow-location-center-02.tt-arrow-hover:hover
  .slick-arrow {
  opacity: 1;
}

/* slick - style */
.slick-slider .slick-dots {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 22px;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  align-items: flex-start;
}

.slick-slider .slick-dots li {
  margin: 0 3px;
  padding: 0 2px;
  cursor: pointer;
}

.slick-slider .slick-dots li button {
  width: 14px;
  height: 14px;
  background: #dadada;
  border: none;
  border-radius: 50%;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
}

.slick-slider .slick-dots li:hover button {
  background: #191919;
}

.slick-slider .slick-dots .slick-active button {
  background: #191919;
}

/* (blog masonry col) */
.tt-arrow-type-02.slick-slider .slick-arrow {
  background: #f7f8fa;
  color: #dadada;
}

.tt-arrow-type-02.slick-slider .slick-arrow:hover {
  background: #f7f8fa;
  color: #191919;
}

.tt-arrow-type-02.slick-slider .slick-next {
  right: 20px;
}

.tt-arrow-type-02.slick-slider .slick-prev {
  left: 20px;
}

.wrapper-social-icon {
  background: #f7f8fa;
  padding: 12px 5px 10px;
}

/* tt-gift-layout (gift-cart.html)*/
.tt-gift-layout {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  padding: 82px 0;
}

.tt-gift-layout .tt-title {
  margin-top: 81px;
  font-size: 34px;
  line-height: 1.1;
  color: #191919;
}

.tt-gift-layout .tt-gift-box {
  margin-top: 53px;
  max-width: 434px;
  overflow: hidden;
  position: relative;
  border-radius: 9px;
}

.tt-gift-layout .tt-gift-box img {
  max-width: 100%;
  height: auto;
}

.tt-gift-layout .tt-gift-box .tt-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}

.tt-gift-layout .tt-gift-box .tt-description .tt-sum {
  color: #ffffff;
  font-size: 32px;
  font-family: "Chakra Petch", sans-serif;
  position: absolute;
  top: 17px;
  right: 15px;
  font-weight: 500;
  letter-spacing: 0.02em;
}

@media (max-width: 575px) {
  .tt-gift-layout .tt-gift-box .tt-description .tt-sum {
    font-size: 27px;
  }
}

.tt-gift-layout .tt-gift-box .tt-description .tt-code {
  background-color: #ffffff;
  border: 7px solid #ffffff;
  margin: 20px;
  position: relative;
  border-radius: 3px;
}

.tt-gift-layout .tt-gift-box .tt-description .tt-code span {
  display: block;
  position: relative;
  padding: 13px 14px 11px;
  font-size: 25px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #191919;
}

.tt-gift-layout .tt-gift-box .tt-description .tt-code span:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  /* background: url("../images/custom/tt-gift-box-border2.png") left top repeat-y; */
}

.tt-gift-layout .tt-gift-box .tt-description .tt-code span:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  /* background: url("../images/custom/tt-gift-box-border2.png") right top repeat-y; */
}

.tt-gift-layout .tt-gift-box .tt-description .tt-code:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  /* background: url("../images/custom/tt-gift-box-border1.png") left top repeat-x; */
}

.tt-gift-layout .tt-gift-box .tt-description .tt-code:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  /* background: url("../images/custom/tt-gift-box-border1.png") left top repeat-x; */
}

.tt-gift-layout p {
  margin-top: 33px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.tt-gift-layout .tt-gift-dissembled {
  margin-top: 35px;
}

.tt-gift-layout .tt-gift-dissembled img {
  max-width: 100%;
  height: auto;
}

.tt-gift-layout .btn-border {
  margin-top: 62px;
}

.tt-gift-layout .btn-link {
  margin-top: 32px;
}

@media (max-width: 1024px) {
  .tt-gift-layout {
    padding: 42px 0;
  }

  .tt-gift-layout .tt-title {
    margin-top: 41px;
    font-size: 31px;
  }

  .tt-gift-layout .tt-gift-box {
    margin-top: 44px;
    max-width: 380px;
  }

  .tt-gift-layout .tt-gift-box .tt-description .tt-code span {
    font-size: 22px;
    letter-spacing: 0.04em;
  }

  .tt-gift-layout .tt-gift-dissembled {
    margin-top: 31px;
  }

  .tt-gift-layout .btn-border {
    margin-top: 40px;
  }

  .tt-gift-layout .btn-link {
    margin-top: 23px;
  }
}

@media (max-width: 575px) {
  .tt-gift-layout {
    padding: 22px 0;
  }

  .tt-gift-layout .tt-title {
    margin-top: 35px;
    font-size: 26px;
  }

  .tt-gift-layout .tt-gift-box {
    margin-top: 36px;
    max-width: 290px;
  }

  .tt-gift-layout .tt-gift-box .tt-description .tt-code {
    margin: 15px;
  }

  .tt-gift-layout .tt-gift-box .tt-description .tt-code span {
    font-size: 18px;
    letter-spacing: 0.02em;
  }

  .tt-gift-layout p {
    margin-top: 25px;
    line-height: 19px;
  }

  .tt-gift-layout .tt-gift-dissembled {
    margin-top: 24px;
  }

  .tt-gift-layout .btn-border {
    margin-top: 31px;
  }

  .tt-gift-layout .btn-link {
    margin-top: 18px;
  }
}

.tt-gift-layout > *:nth-child(1) {
  margin-top: 0;
}

/* tt-back-to-top */
@media (min-width: 790px) {
  .tt-back-to-top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    transition: 0.4s;
    width: 62px;
    height: 62px;
    background-color: rgb(0 0 0 / 61%);
    color: #ffffff;
    font-size: 0;
    line-height: 0;
    position: fixed;
    bottom: -94px;
    right: 5px;
    z-index: 20;
    border-radius: 6px;
  }

  .tt-back-to-top:before {
    display: block;
    font-size: 20px;
    line-height: 1;
    position: relative;
    top: -1px;
    transition: top 0.2s linear;
  }

  .tt-back-to-top:hover {
    background-color: #303030;
    color: #ffffff;
  }

  .tt-back-to-top:hover:before {
    top: -4px;
  }

  .tt-back-to-top.tt-show {
    bottom: 100px;
  }
}

@media (max-width: 789px) {
  .tt-back-to-top {
    background: #ffffff;
    color: #191919;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-family: "Chakra Petch", sans-serif;
    font-weight: 500;
    padding: 5px 0 33px 0;
    transition: all 0.2s linear;
  }

  .tt-back-to-top.tt-align-center {
    padding: 20px 0 18px 0;
  }

  .tt-back-to-top:before {
    display: block;
    font-size: 20px;
    line-height: 1;
  }

  .tt-back-to-top:hover {
    background: #ffffff;
    color: var(--yellow);
  }
}

/* tt-breadcrumb */
.tt-breadcrumb {
  background: #f7f8fa;
}

.tt-breadcrumb ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.tt-breadcrumb ul li {
  font-size: 12px;
  padding: 4px 6px 4px 11px;
  position: relative;
  color: #777777;
}

.tt-breadcrumb ul li a {
  color: #777777;
  transition: color 0.2s linear;
}

.tt-breadcrumb ul li a:hover {
  color: var(--yellow);
}

/* .tt-breadcrumb ul li:not(:first-child):before {
  content: "/";
  position: absolute;
  display: inline-block;
  left: 0;
  top: 4px;
} */
.tt-table-shop-01 {
  width: 100%;
  border-collapse: collapse;
}

.tt-table-shop-01 th,
.tt-table-shop-01 td {
  border: 2px solid #ddd;
  padding: 8px;
  text-align: start;
}

.tt-table-shop-01 th {
  padding: 12px 10px !important;
  background-color: #f2f2f2;
  font-weight: bold;
}
.tt-breadcrumb ul li:first-child {
  padding-left: 0;
}

@media (max-width: 575px) {
  .tt-breadcrumb {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* tt-services-block */
.tt-layout-02 {
  background: #f7f8fa;
}

.tt-services-listing.tt-layout-02 .tt-services-block {
  margin-top: 16px;
  padding-bottom: 14px;
}

.tt-services-listing:not(.tt-layout-02) {
  margin-top: -30px;
}

.tt-services-listing:not(.tt-layout-02) .tt-services-block {
  margin-top: 30px;
}

@media (max-width: 575px) {
  .tt-services-listing:not(.tt-layout-02) {
    margin-top: -35px;
  }

  .tt-services-listing:not(.tt-layout-02) .tt-services-block {
    margin-top: 37px;
  }
}

@media (min-width: 791px) {
  .tt-services-listing-aligment {
    margin-bottom: -5px;
  }
}

@media (max-width: 790px) and (min-width: 576px) {
  .tt-services-listing-aligment {
    margin-bottom: -12px;
  }
}

@media (max-width: 575px) {
  .tt-services-listing-aligment {
    margin-bottom: -10px;
  }
}

.tt-services-block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.tt-services-block .tt-col-icon {
  align-self: flex-start;
  font-size: 50px;
  color: var(--yellow);
}

.tt-services-block .tt-col-icon [class^="icon-"] {
  position: relative;
  top: -5px;
}

@media (min-width: 576px) {
  .tt-services-block .tt-col-icon + .tt-col-description {
    padding-left: 21px;
  }
}

@media (max-width: 575px) {
  .tt-services-block .tt-col-icon + .tt-col-description {
    padding-left: 11px;
  }
}

.tt-services-block .tt-col-description {
  flex: 2 1 auto;
  line-height: 1.3;
  color: #777777;
}

.tt-services-block .tt-col-description .tt-title {
  padding: 0;
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.03em;
  font-weight: 500;
  color: #191919;
  transition: all 0.2s linear;
}

.tt-services-block .tt-col-description p {
  margin-top: 7px;
  font-size: 14px;
  line-height: 22px;
}

.tt-services-block .tt-col-description > *:nth-child(1) {
  margin-top: 0;
}

.tt-services-block.tt-services-block-vertical {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.tt-services-block.tt-services-block-vertical .tt-col-icon {
  align-self: center;
}

.tt-services-block.tt-services-block-vertical .tt-col-description {
  text-align: center;
}

.tt-services-block:hover {
  text-decoration: none;
}

.tt-services-block:hover .tt-title {
  color: var(--yellow);
}

.tt-product-single-aside > *:not(:first-child) {
  margin-top: 30px;
}

@media (min-width: 1025px) and (max-width: 1229px) {
  .tt-row-custom-01.tt-responsive-lg {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .tt-row-custom-01.tt-responsive-lg .col-item:not(:first-child) {
    margin-top: 15px;
  }
}

/* tt-tabs-default */
.tt-block-title + .tt-tab-wrapper {
  position: relative;
}

@media (min-width: 1025px) {
  .tt-block-title + .tt-tab-wrapper {
    margin-top: -65px;
  }
}

.tt-tab-wrapper {
  position: relative;
}

@media (min-width: 1025px) {
  .tt-tab-wrapper {
    margin-top: -1px;
  }
}

@media (min-width: 1025px) {
  .arrow-location-tab {
    margin-top: 90px;
  }

  .tt-block-title + .arrow-location-tab.tt-carousel-products {
    margin-top: -38px;
  }

  .tab-content .arrow-location-tab {
    margin-top: -51px;
  }

  .tt-block-title + .tab-content .arrow-location-tab.tt-carousel-products {
    margin-top: -52px;
  }
}

/* tt-text-box01 */
.tt-text-box01 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  max-width: 862px;
  margin: 0 auto;
}

.tt-text-box01 > *:nth-child(1) {
  margin-top: 0;
}

.tt-slider-fullwidth {
  position: relative;
}

.tt-slider-fullwidth.arrow-location-center-02:after,
.tt-slider-fullwidth.arrow-location-center-02:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
}

@media (min-width: 401px) {
  .tt-slider-fullwidth.arrow-location-center-02:after,
  .tt-slider-fullwidth.arrow-location-center-02:before {
    width: 70px;
  }
}

@media (max-width: 400px) {
  .tt-slider-fullwidth.arrow-location-center-02:after,
  .tt-slider-fullwidth.arrow-location-center-02:before {
    width: 55px;
  }
}

.container-bg {
  background-size: cover;
}

.container-bg-01 {
  /* background: url("../images/custom/wrapper-section-01.jpg") center center
    no-repeat; */
  color: #ffffff;
}

.container-bg-01 .tt-title {
  color: #ffffff;
}

.container-bg-01 p {
  color: #ffffff;
}

.container-bg-01 .tt-content-info {
  color: #ffffff;
}

.container-bg-01 .tt-content-info .tt-subscription .tt-text-large {
  color: #ffffff;
}

.container-bg-01 .tt-content-info .tt-subscription .tt-text-small {
  color: #ffffff;
}

.container-bg-01 .tt-content-info:hover .tt-title {
  color: #191919;
}

/*
	Btn Img
*/

@media (min-width: 1440px) {
  .col-9-inrow-lg {
    flex: 0 0 11.11%;
    max-width: 11.11%;
  }
}

@media (min-width: 1440px) {
  .col-8-inrow-lg {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media (min-width: 1025px) {
  .col-5-inrow-lg {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-5_end-inrow-lg {
    flex: 0 0 80%;
    max-width: 80%;
  }
}

@media (min-width: 1025px) {
  .col-5-inrow-lg {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

/* tab */
.tab-pane:not(.active) {
  display: none;
}

/* tt-loader (quickViewModal)*/
.tt-loader {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  top: 0;
}

.tt-loader svg path,
.tt-loader svg rect {
  fill: var(--yellow);
}

/*
	v-tabs-img-nav
*/
.v-tabs-img-nav .tt-title-small {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2px;
  color: #191919;
}

.v-tabs-img-nav .tt-title-large {
  font-weight: 500;
  font-size: 28px;
  line-height: 1.2px;
  color: #191919;
}

.v-tabs-img-nav .nav-tabs {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* max oc */
@media (min-width: 576px) {
  .tt-modal-newsletter .input-group input[type="email"] {
    padding-top: 3px;
    padding-bottom: 1px;
    line-height: 2.9;
  }

  footer
    .tt-footer-default
    .tt-newsletter
    .form-default
    .form-group
    .form-control {
    padding-top: 3px;
    padding-bottom: 1px;
    line-height: 2.9;
  }

  .form-default .form-control[type="text"],
  .form-default .form-control[type="email"],
  .form-default .form-control[type="password"] {
    padding-top: 3px;
    padding-bottom: 1px;
    line-height: 2.9;
  }
}

@media (max-width: 575px) {
  .tt-modal-newsletter .input-group input[type="email"] {
    padding-top: 1px;
    padding-bottom: 1px;
    line-height: 2.4;
  }

  footer
    .tt-footer-default
    .tt-newsletter
    .form-default
    .form-group
    .form-control {
    padding-top: 1px;
    padding-bottom: 1px;
    line-height: 2.4;
  }

  .form-default .form-control[type="text"],
  .form-default .form-control[type="email"],
  .form-default .form-control[type="password"] {
    padding-top: 1px;
    padding-bottom: 1px;
    line-height: 2.4;
  }
}

/* ie */
@media (min-width: 576px) {
  html.ie.win .tt-modal-newsletter .input-group input[type="email"] {
    padding-top: 2px;
    padding-bottom: 1px;
    line-height: 2.6;
  }

  html.ie.win
    footer
    .tt-footer-default
    .tt-newsletter
    .form-default
    .form-group
    .form-control {
    padding-top: 2px;
    padding-bottom: 1px;
    line-height: 2.6;
  }

  html.ie.win .form-default .form-control[type="text"],
  html.ie.win .form-default .form-control[type="email"],
  html.ie.win .form-default .form-control[type="password"] {
    padding-top: 2px;
    padding-bottom: 1px;
    line-height: 2.6;
  }
}

@media (max-width: 575px) {
  html.ie.win .tt-modal-newsletter .input-group input[type="email"] {
    padding-top: 1px;
    padding-bottom: 1px;
    line-height: 2.6;
  }

  html.ie.win
    footer
    .tt-footer-default
    .tt-newsletter
    .form-default
    .form-group
    .form-control {
    padding-top: 1px;
    padding-bottom: 1px;
    line-height: 2.6;
  }

  .form-default .form-control[type="text"],
  .form-default .form-control[type="email"],
  .form-default .form-control[type="password"] {
    padding-top: 1px;
    padding-bottom: 1px;
    line-height: 2.6;
  }
}

.tt-radius-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

@media (max-width: 575px) {
  .tt-radius-left {
    border-radius: 5px;
  }
}

.tt-radius-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media (max-width: 575px) {
  .tt-radius-right {
    border-radius: 5px;
  }
}

.tt-offset-35 {
  margin-top: 35px;
}

@media (max-width: 575px) {
  .tt-offset-35 {
    margin-top: 24px;
  }
}

.tt-offset-20 {
  margin-top: 20px;
}

.tt-offset-16 {
  margin-top: 16px;
}

.tt-offset-7 {
  margin-top: 7px;
}

/*
	visibility of elements
 */
/* hidden */
@media (min-width: 1230px) {
  .hidden-lg-up {
    display: none;
  }
}

@media (max-width: 1230px) and (min-width: 1025px) {
  .hidden-md-up {
    display: none;
  }
}

@media (max-width: 1025px) and (min-width: 790px) {
  .hidden-sm-up {
    display: none;
  }
}

@media (max-width: 789px) and (min-width: 575px) {
  .hidden-sm {
    display: none;
  }
}

@media (max-width: 575px) {
  .hidden-xs {
    display: none;
  }
}

/* visibility */
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg,
.visible-xl {
  display: none;
}

@media (min-width: 1230px) {
  .visible-xl {
    display: block;
  }
}

@media (max-width: 1230px) and (min-width: 1025px) {
  .visible-lg {
    display: block;
  }
}

@media (max-width: 1025px) and (min-width: 789px) {
  .visible-md {
    display: block;
  }
}

@media (max-width: 789px) and (min-width: 575px) {
  .visible-sm {
    display: block;
  }
}

@media (max-width: 575px) {
  .visible-xs {
    display: block;
  }
}

.hide {
  display: none !important;
}

@media (max-width: 1024px) {
  .tt-hidden-mobile {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .tt-hidden-desctope {
    display: none !important;
  }
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.no-radius {
  border-radius: 0 !important;
}

/* color text */
.tt-base-color {
  color: var(--yellow);
}

.tt-base-dark-color {
  color: #191919;
}

.tt-white-color {
  color: #ffffff;
}

.tt-light-green-color {
  color: #08da97;
}

/* bg color */
.tt-color-bg-01 {
  background-color: #add8e6;
}

.tt-color-bg-02 {
  background-color: #fe9001;
}

.tt-color-bg-03 {
  background-color: #808080;
}

.tt-color-bg-04 {
  background-color: #0000ff;
}

.tt-color-bg-05 {
  background-color: #00ff00;
}

.tt-color-bg-06 {
  background-color: #46d3bb;
}

.tt-color-bg-07 {
  background-color: #f66c47;
}

.tt-color-bg-08 {
  background-color: #ffffff;
}

.tt-color-bg-09 {
  background-color: #e5e5e5;
}

.tt-color-bg-10 {
  background-color: #fed533;
}

.tt-color-bg-11 {
  background-color: #f36b26;
}

.tt-color-bg-12 {
  background-color: #7bba3c;
}

.tt-color-bg-13 {
  background-color: #1790c8;
}

.tt-color-bg-14 {
  background-color: #d82a90;
}

.tt-color-bg-15 {
  background-color: #7f7f00;
}

.tt-color-bg-16 {
  background-color: #e7352b;
}

.tt-color-bg-17 {
  background-color: #8d429f;
}

.tt-color-bg-18 {
  background-color: #777878;
}

@media (min-width: 1440px) {
  .col-8-inrow-lg {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

.tt-img-fluid {
  max-width: 100%;
  height: auto;
}

/* Layout Box 03 */
.container-inner {
  padding: 52px 0;
}

.container-inner-large {
  padding: 155px 0 160px;
}

@media (max-width: 575px) {
  .container-inner-large {
    padding: 52px 0;
  }
}

.tt-bg-fullwidth {
  background-position: center bottom;
  background-repeat: no-repeat;
  overflow: hidden;
}

.tt-bg-fullwidth.tt-bg-bottom-indent {
  padding-bottom: 90px;
}

.tt-bg-sizecover {
  background-size: cover;
}

/*
	Main Promo
*/

.tt-mainpromo {
  width: 100%;
  background-color: #0d0d0d;
  background-repeat: no-repeat;
  padding-bottom: 49px;
}

.tt-mainpromo .tt-bg-img {
  text-align: center;
  margin-bottom: -135px;
  z-index: 1;
}

.tt-mainpromo .tt-bg-img img {
  max-width: 100%;
  height: auto;
}

.tt-mainpromo .tt-layout-box {
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
}

.tt-mainpromo .tt-layout-box .tt-text-large {
  font-size: 212px;
  line-height: 1;
}

.tt-mainpromo .tt-layout-box .tt-text-small {
  font-size: 82px;
  line-height: 1;
  color: #fabf00;
}

.tt-mainpromo .tt-layout-col {
  position: relative;
  z-index: 2;
  font-size: 40px;
  line-height: 1.2;
  text-align: center;
  color: #fabf00;
  margin-top: 56px;
}

.tt-mainpromo .tt-layout-col .tt-title {
  color: #ffffff;
  font-family: "Chakra Petch", sans-serif;
  font-size: 40px;
  line-height: 1;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

.tt-mainpromo .tt-layout-col address {
  line-height: 1;
}

.tt-mainpromo .tt-description {
  padding: 103px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #ffffff;
}

.tt-mainpromo .tt-description .container {
  position: relative;
}

@media (max-width: 1229px) {
  .tt-mainpromo .tt-bg-img {
    margin-bottom: -125px;
  }

  .tt-mainpromo .tt-layout-box .tt-text-large {
    font-size: 180px;
  }

  .tt-mainpromo .tt-layout-box .tt-text-small {
    font-size: 72px;
  }

  .tt-mainpromo .tt-layout-col {
    font-size: 35px;
  }

  .tt-mainpromo .tt-layout-col .tt-title {
    font-size: 35px;
  }
}

@media (max-width: 1024px) {
  .tt-mainpromo .tt-description {
    padding-top: 0px;
  }

  .tt-mainpromo .tt-bg-img {
    margin-bottom: -115px;
  }

  .tt-mainpromo .tt-layout-box .tt-text-large {
    font-size: 140px;
  }

  .tt-mainpromo .tt-layout-box .tt-text-small {
    font-size: 55px;
  }

  .tt-mainpromo .tt-layout-col {
    font-size: 28px;
    line-height: 1.3;
    margin-top: 40px;
  }

  .tt-mainpromo .tt-layout-col .tt-title {
    font-size: 28px;
    line-height: 1.3;
  }
}

@media (max-width: 790px) {
  .tt-mainpromo .tt-bg-img {
    margin-bottom: -85px;
  }

  .tt-mainpromo .tt-description {
    padding: 0px 20px 20px;
  }

  .tt-mainpromo .tt-layout-box .tt-text-large {
    font-size: 100px;
  }

  .tt-mainpromo .tt-layout-box .tt-text-small {
    font-size: 45px;
  }

  .tt-mainpromo .tt-layout-col {
    font-size: 28px;
    line-height: 1.3;
    margin-top: 0px;
  }

  .tt-mainpromo .tt-layout-col .tt-title {
    font-size: 28px;
    line-height: 1.3;
  }

  .tt-mainpromo .tt-layout-col [class^="col"] {
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .tt-mainpromo {
    height: inherit;
  }

  .tt-mainpromo .tt-description {
    position: relative;
    padding: 0;
  }

  .tt-mainpromo .tt-bg-img {
    margin-bottom: -45px;
  }

  .tt-mainpromo .tt-bg-img img {
    max-width: 320px;
  }

  .tt-mainpromo .tt-layout-box .tt-text-large {
    font-size: 67px;
  }

  .tt-mainpromo .tt-layout-box .tt-text-small {
    font-size: 30px;
  }

  .tt-mainpromo .tt-layout-col {
    font-size: 28px;
    line-height: 1.3;
  }

  .tt-mainpromo .tt-layout-col .tt-title {
    font-size: 28px;
    line-height: 1.3;
  }
}

.tt-box-map {
  height: 740px;
  background-position: 0 0;
  background-size: cover;
  position: relative;
}

.tt-box-map .tt-title {
  color: #fabf00;
  font-family: "Chakra Petch", sans-serif;
  font-size: 82px;
  line-height: 1;
  position: absolute;
  right: 21.5%;
  bottom: 39%;
  font-weight: 400;
}

@media (max-width: 1229px) {
  .tt-box-map .tt-title {
    right: 13%;
    bottom: 49%;
    font-size: 79px;
  }
}

@media (min-width: 1025px) {
  .tt-box-map {
    height: 740px;
  }
}

@media (max-width: 1024px) {
  .tt-box-map {
    height: 540px;
  }

  .tt-box-map .tt-title {
    right: 5%;
    bottom: 29%;
    font-size: 59px;
  }
}

@media (max-width: 575px) {
  .tt-box-map {
    height: 400px;
  }
}

/*
	tt-btn-info
*/
.tt-btn-info {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  width: 100%;
  font-weight: bold;
  height: 50px;
  background-color: transparent;
  color: #191919;
  transition: all 0.2s linear;
  border-radius: 6px;
}

.tt-btn-info .tt-title {
  font-size: 16px;
  font-weight: 400;
}

.tt-btn-info:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #e9e7e7;
  transition: all 0.2s;
  border-radius: 6px;
  overflow: hidden;
}

.tt-btn-info:hover {
  background-color: #191919;
  color: #ffffff;
}

.tt-btn-info:hover:after {
  border-color: #191919;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

body.loaded .hatch {
  animation-name: hatch;
  -webkit-animation-name: hatch;
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  visibility: visible !important;
}

@keyframes hatch {
  0% {
    -webkit-transform: rotate(0deg) scaleY(0.6);
    transform: rotate(0deg) scaleY(0.6);
  }

  20% {
    -webkit-transform: rotate(-2deg) scaleY(1.05);
    transform: rotate(-2deg) scaleY(1.05);
  }

  35% {
    -webkit-transform: rotate(2deg) scaleY(1);
    transform: rotate(2deg) scaleY(1);
  }

  50% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  65% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }

  80% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-webkit-keyframes hatch {
  0% {
    -webkit-transform: rotate(0deg) scaleY(0.6);
  }

  20% {
    -webkit-transform: rotate(-2deg) scaleY(1.05);
  }

  35% {
    -webkit-transform: rotate(2deg) scaleY(1);
  }

  50% {
    -webkit-transform: rotate(-2deg);
  }

  65% {
    -webkit-transform: rotate(1deg);
  }

  80% {
    -webkit-transform: rotate(-1deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 1025px) {
  body.loaded .fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
  }
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 1025px) {
  body.loaded .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 1025px) {
  body.loaded .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

body.loaded .zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

#zoom-mobile__slider {
  position: relative;
}

#zoom-mobile__layout {
  background-color: #ffffff;
  position: fixed;
  z-index: 140;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 0.2s ease-in-out;
}

#zoom-mobile__layout:not(.active) {
  pointer-events: none;
}

#zoom-mobile__layout.active {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

#zoom-mobile__layout .zoom-mobile__close {
  position: absolute;
  z-index: 2;
  left: 15px;
  top: 15px;
}

#zoom-mobile__layout .fotorama__fullscreen-icon {
  opacity: 0;
}

.fotorama img {
  width: 100%;
  height: auto;
}

#tt-fotorama {
  height: 100vh;
}

#tt-fotorama .fotorama__stage .fotorama__img {
  height: calc(568px - 85px) !important;
  width: 100% !important;
  -o-object-fit: cover;
  object-fit: cover;
}

#tt-fotorama .fotorama__stage {
  height: calc(568px - 85px) !important;
}

#tt-fotorama .fotorama__nav-wrap {
  height: 75px;
}

#tt-fotorama .fotorama__arr--prev,
#tt-fotorama .fotorama__arr--next {
  background: none;
}

#tt-fotorama .fotorama__arr--prev:hover,
#tt-fotorama .fotorama__arr--next:hover {
  color: #191919;
}

#tt-fotorama .fotorama__arr--prev:before,
#tt-fotorama .fotorama__arr--next:before {
  font-family: wokiee;
  font-size: 20px;
  line-height: 1;
}

/*
	Product Single page(*Section indent, Section Title)
*/

.section-wrapper01 {
  background-color: #fff5da;
}

@media (min-width: 1025px) {
  .container-inner01 {
    padding: 101px 0;
  }
}

@media (max-width: 1024px) {
  .container-inner01 {
    padding: 70px 0;
  }
}

@media (max-width: 575px) {
  .container-inner01 {
    padding: 50px 0;
  }
}

.tt-bg-fill {
  background-size: cover;
  background-position: center center;
}

html:not(.mac):not(.touch-device) .tt-bg-fill {
  background-attachment: fixed;
}

.tt-block-title02 {
  padding: 0 0 41px;
}

.tt-block-title02:not(.text-right),
.tt-block-title02:not(.text-left) {
  text-align: center;
}

.tt-block-title02 .tt-title {
  font-size: 48px;
  line-height: 53px;
  color: #191919;
  font-weight: 600;
}

.tt-block-title02 .tt-description {
  font-size: 24px;
  line-height: 33px;
  color: #777777;
  margin-top: 26px;
}

.tt-block-title02 .tt-description + .tt-title {
  margin-top: 17px;
}

@media (min-width: 1025px) {
  .tt-block-title02 + .tt-tab-wrapper {
    margin-top: -6px;
  }
}

@media (max-width: 1024px) {
  .tt-block-title02 {
    padding: 0 0 36px;
  }

  .tt-block-title02 .tt-title {
    font-size: 40px;
    line-height: 45px;
  }

  .tt-block-title02 .tt-description {
    font-size: 21px;
    line-height: 31px;
    margin-top: 18px;
  }
}

@media (max-width: 790px) {
  .tt-block-title02 {
    padding: 0 0 30px;
  }

  .tt-block-title02 .tt-title {
    font-size: 36px;
    line-height: 41px;
  }

  .tt-block-title02 .tt-description {
    font-size: 20px;
    line-height: 30px;
    margin-top: 13px;
  }
}

.tt-block-title02 > *:nth-child(1) {
  margin-top: 0;
}

.tt-block-title02 > *:nth-child(1).tt-description {
  margin-top: -18px;
}

@media (max-width: 790px) {
  .container-fluid-md {
    width: 100%;
    max-width: inherit;
    padding-left: 10px;
    padding-right: 10px;
  }

  .container-fluid-md .tt-tab-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/*
	Product Single page(*New layout Obj Data)
*/

.obj-data-listcol {
  margin-top: -20px;
}

.obj-data-listcol [class^="col-"] {
  margin-top: 20px;
}

@media (max-width: 790px) {
  .js-objdata-slider .slick-slide {
    padding-left: 20px;
    padding-right: 20px;
  }

  .js-objdata-slider .obj-data {
    margin-bottom: 27px;
  }
}

@media (max-width: 575px) {
  .js-objdata-slider .obj-data {
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;
  }
}

.js-objdata-slider.slick-slider .slick-dots {
  position: relative;
  margin-top: 0;
}

.obj-data {
  background: #ffffff;
  border-radius: 10px;
  height: 100%;
  box-shadow: 10px 20px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 0 0 36px 0;
}

.obj-data .obj-data__icon {
  padding: 35px 25px 0;
}

.obj-data .obj-data__icon img {
  max-width: 100%;
  width: auto;
  height: auto;
  display: inline-block;
}

.obj-data .obj-data__img img {
  width: 100%;
}

.obj-data .obj-data__img + .obj-data__content {
  padding-top: 36px;
  padding-bottom: 5px;
}

.obj-data .obj-data__content {
  padding: 29px 25px 0px;
  font-size: 18px;
  line-height: 24px;
}

.obj-data .obj-data__content > *:nth-child(1) {
  margin-top: 0;
}

.obj-data .tt-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #191919;
  padding: 0 0 13px 0;
}

.obj-data .tt-title a {
  color: #333333;
}

.obj-data .tt-title a:hover {
  color: var(--yellow);
}

.obj-data .tt-title + * {
  margin-top: 0;
}

.obj-data:not(.text-right),
.obj-data:not(.text-left) {
  text-align: center;
}

.obj-data.text-left .obj-data__icon {
  text-align: left;
}

.obj-data.text-right .obj-data__icon {
  text-align: right;
}

.obj-data:not(.no-border) {
  border: 5px solid #000000;
}

.obj-data:not(.no-border) .obj-data__img {
  border-bottom: 5px solid #000000;
}

@media (max-width: 1024px) {
  .obj-data {
    padding: 0 0 25px 0;
  }

  .obj-data .obj-data__icon {
    padding-top: 29px;
  }

  .obj-data .obj-data__icon,
  .obj-data .obj-data__content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .obj-data .obj-data__content {
    font-size: 16px;
    line-height: 22px;
    padding-top: 20px;
  }

  .obj-data .tt-title {
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 7px;
  }
}

/* ////////// */
/*
	Product Single page(*New layout tabs)
*/
.nav-tabs__default02 {
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0 0 7px 0;
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
}

.nav-tabs__default02 li {
  margin: 0px 3px 3px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}

.nav-tabs__default02 li > a {
  color: #191919;
  transition: all 0.2s linear;
  padding: 7px 11px 4px;
  display: inline-block;
  position: relative;
  background: linear-gradient(180deg, #7ec0fe 0%, var(--yellow) 100%);
  box-shadow: 10px 20px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.nav-tabs__default02 li > a:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, var(--yellow) 0%, #7ec0fe 100%);
  opacity: 0;
  transition: opacity 0.2s linear;
  border-radius: 5px;
}

.nav-tabs__default02 li > a span {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.nav-tabs__default02 li > a:hover {
  color: #ffffff;
}

.nav-tabs__default02 li > a.active {
  color: #ffffff;
}

.nav-tabs__default02 li > a.active:before {
  opacity: 1;
}

.nav-tabs__default02:not(.no-border) li > a {
  border: 2px solid #000000;
}

.nav-tabs__default02.text-left {
  text-align: left;
  justify-content: flex-start;
}

.nav-tabs__default02.text-left li:first-child {
  margin-left: 0;
}

.nav-tabs__default02.text-right {
  text-align: right;
  justify-content: flex-end;
  padding-right: 0;
}

.nav-tabs__default02.text-right li:first-child {
  margin-right: 0;
}

.tab-content__default02 {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 10px 20px 10px rgba(0, 0, 0, 0.2);
}

@media (min-width: 1025px) {
  .tab-content__default02 {
    padding: 52px 45px 45px;
  }
}

@media (max-width: 1024px) {
  .tab-content__default02 {
    padding: 35px;
  }
}

@media (max-width: 790px) {
  .tab-content__default02 {
    padding: 30px 20px;
  }
}

.tab-content__default02:not(.no-border) {
  border: 5px solid #000000;
}

@media (max-width: 1024px) {
  .tab-content__default02:not(.no-border) {
    border-width: 4px;
  }
}

@media (max-width: 790px) {
  .tab-content__default02:not(.no-border) {
    border-width: 3px;
  }
}

/*
	tt-layout01
*/
.tt-layout01 .tt-layout01__content {
  font-size: 18px;
  line-height: 24px;
}

@media (min-width: 1025px) {
  .tt-layout01 {
    min-height: 301px;
  }

  .tt-layout01 .tt-layout01__content p {
    margin-top: 24px;
  }
}

.tt-layout01 .tt-title {
  font-size: 24px;
  line-height: 33px;
  font-weight: 500;
  color: #191919;
  margin: 0;
  padding: 0 0 23px 0;
}

.tt-layout01 .tt-title + * {
  margin-top: 0;
}

.tt-layout01.text-center .tt-layout01__content {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
}

.tt-layout01 .tt-title + *,
.tt-layout01 .tt-title + p {
  margin-top: 0;
}

.tt-layout01 [class^="col-"] > *:nth-child(1) {
  margin-top: 0;
}

@media (max-width: 1024px) {
  .tt-layout01 .tt-title {
    font-size: 22px;
    line-height: 30px;
    padding: 0 0 7px 0;
  }

  .tt-layout01 .tt-layout01__content {
    font-size: 16px;
    line-height: 22px;
  }

  .tt-layout01 .tt-layout01__content:not(:first-child) {
    padding: 0px 0 0 30px;
  }
}

@media (min-width: 791px) {
  .tt-layout01 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }

  .tt-layout01 .tt-layout01__img {
    flex: 0 0 46.3%;
    max-width: 46.3%;
  }

  .tt-layout01 .tt-layout01__img img {
    width: 100%;
    height: auto;
  }

  .tt-layout01 .tt-layout01__content {
    flex: 2 1 auto;
  }

  .tt-layout01 .tt-layout01__content:not(:first-child) {
    padding: 0px 0 0 50px;
  }
}

@media (max-width: 790px) {
  .tt-layout01 .tt-layout01__img {
    width: 100%;
    text-align: center;
    padding-bottom: 30px;
  }

  .tt-layout01 .tt-layout01__img img {
    max-width: 320px;
    height: auto;
    width: 100%;
  }

  .tt-layout01 .tt-layout01__content:not(:first-child) {
    padding: 0px 0 0 0px;
  }
}

/*
	Product Single page(*mobile slider - dots)
*/

.tt-mobile-product-slider .slick-dots {
  position: relative;
  margin-top: 7px;
  padding: 0 19px;
}

.tt-mobile-product-slider .slick-dots li {
  margin: 0 0px;
  padding: 2px 2px;
  line-height: 0;
}

.tt-mobile-product-slider .slick-dots li button {
  width: 10px;
  height: 10px;
  padding: 0;
  background: #dadada;
  border: 1px solid #dadada;
  transition: all 0.2s linear;
  position: relative;
}

.tt-mobile-product-slider .slick-dots li button:before {
  content: "";
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 50%;
  position: absolute;
  left: -1px;
  top: -1px;
  border: 1px solid #191919;
  background: #191919;
  border-right: 50%;
  opacity: 0;
  transition: all 0.2s linear;
}

.tt-mobile-product-slider .slick-dots li:hover button {
  background: #f7f8fa;
  border-color: #e9e7e7;
}

.tt-mobile-product-slider .slick-dots .slick-active {
  pointer-events: none;
}

.tt-mobile-product-slider .slick-dots .slick-active button {
  background: #191919;
}

.tt-mobile-product-slider .slick-dots .slick-active button:before {
  opacity: 1;
}

.slick-dots-02 .slick-dots li button {
  background: transparent;
  border-color: #191919;
}

.slick-dots-02 .slick-dots li button:before {
  background: transparent;
  border-color: #417dfb;
  border-width: 1px;
}

.slick-dots-02 .slick-dots li:hover button {
  border-color: #417dfb;
}

.slick-dots-02 .slick-dots .slick-active button {
  background: transparent;
}

.slick-dots-02 .slick-dots .slick-active button:before {
  opacity: 1;
  border-width: 2px;
}

.social-wraper {
  display: inline-block;
  width: 100%;
  padding: 0;
  max-width: 100%;
}

.social-wraper a {
  display: block;
  width: 100%;
  font-size: 16px;
  color: #fff;
  padding: 10px;
  margin: 5px 0;
  text-align: center;
}

.social-wraper span:last-child {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 20px;
  border-radius: 0px 0px 0px 0px;
  display: inline-block;
  float: right;
}

.social-wraper .face {
  border-color: #2d5073;
  background-color: #3b5998;
}

.social-wraper .google {
  border-color: #c23321;
  background-color: #dd4b39;
}

.social-wraper .twitter {
  border-color: #2795e9;
  background-color: #55acee;
}

/* checkout section */

.checkout-section ul {
  margin: 0;
  padding: 0;
}

.checkout-section li {
  list-style: none;
  display: inline-block;
  margin: 0 5px;
}

.checkout-section .form-check {
  width: 18px;
  height: 18px;
}

.checkout-section .form-check-label {
  font-size: 16px;
}

.checkout-section a.main-btn {
  background-color: #000;
  padding: 10px 20px;
  border-radius: 8px;
  color: #fff;
}

.checkout-section .order {
  background-color: #f9f9f9;
}

.checkout-section .apply {
  background-color: #7d7c7c;
  color: #fff;
}

.checkout-section .your-orders .order-img {
  margin-right: 10px;
}

.checkout-section .your-orders .order-img img {
  max-width: 70px;
}

.checkout-section .checkout-form form .form-title h3 {
  padding-left: 50px;
  margin-bottom: 25px;
  position: relative;
}

.order-complete-section .bg-content {
  padding: 270px 0;
}

/* branches Section */
.branch-info {
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  padding: 10px 20px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 35px;
}

.branch-info:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 1px solid var(--yellow);
}

.branches h2 {
  font-size: 34px;
  line-height: 44px;
  letter-spacing: 0.03em;
  font-weight: 500;
  margin-top: 40px;
  padding: 0;
  text-align: center;
}

.branch-info h4 {
  padding: 10px 0;
  text-transform: uppercase;
}

.branch-info .branch-img img {
  width: 100%;
}

.branch-info a {
  background-color: #fff;
  border: 1px solid #a9a9a9;
  color: #a9a9a9;
  padding: 10px;
  border-radius: 10px;
  margin: 0 10px;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.branch-info .branch:hover a {
  background-color: var(--yellow);
  border: 1px solid var(--yellow);
  color: #fff;
}

.branch-info span {
  font-size: 17px;
  color: #000;
  display: inline-block;
}

.branch-info .branch:hover span {
  text-decoration: underline;
}

input {
  cursor: pointer;
}

.m-m {
  margin: 0 20px;
}

.show-address {
  display: none;
  background-color: #f0efef;
  padding: 13px 0 0px;
}

#new-address:checked ~ .show-address {
  display: inline-flex;
}

/*  Rating styles */

.rating-stars {
  display: block;
  width: 50vmin;
  padding: 1.75vmin 10vmin 2vmin 3vmin;
  background: linear-gradient(90deg, #ffffff90 40vmin, #fff0 40vmin 100%);
  border-radius: 5vmin;
  position: relative;
}

.rating-stars input {
  display: none;
}

.rating-stars label {
  width: 25px;
  height: 25px;
  display: inline-flex;
  cursor: pointer;
  transition: all 1s ease 0s;
  clip-path: polygon(
    50% 0%,
    66% 32%,
    100% 38%,
    78% 64%,
    83% 100%,
    50% 83%,
    17% 100%,
    22% 64%,
    0 38%,
    34% 32%
  );
}

.rating-stars label[for="rs0"] {
  display: none;
}

.rating-stars label:before {
  width: 90%;
  height: 90%;
  content: "";
  background: orange;
  z-index: -1;
  display: block;
  margin-left: 5%;
  margin-top: 5%;
  clip-path: polygon(
    50% 0%,
    66% 32%,
    100% 38%,
    78% 64%,
    83% 100%,
    50% 83%,
    17% 100%,
    22% 64%,
    0 38%,
    34% 32%
  );
  background: linear-gradient(
    90deg,
    yellow,
    orange 30% 50%,
    #a8a8a8 50%,
    70%,
    #a8a8a8 100%
  );
  background-size: 205% 100%;
  background-position: 0 0;
}

.rating-stars label:hover:before {
  transition: all 0.25s ease 0s;
}

.rating-stars input:checked + label ~ label:before {
  background-position: 100% 0;
  transition: all 0.25s ease 0s;
}

.rating-stars input:checked + label ~ label:hover:before {
  background-position: 0% 0;
}

#rs1:checked ~ .rating-counter:before {
  content: "1";
}

#rs2:checked ~ .rating-counter:before {
  content: "2";
}

#rs3:checked ~ .rating-counter:before {
  content: "3";
}

#rs4:checked ~ .rating-counter:before {
  content: "4";
}

#rs5:checked ~ .rating-counter:before {
  content: "5";
}

.rating-stars label + input:checked ~ .rating-counter:before {
  color: #ffab00 !important;
  transition: all 0.25s ease 0s;
}

.rating-stars label:hover ~ .rating-counter:before {
  color: #9aacc6 !important;
  transition: all 0.5s ease 0s;
  animation: pulse 1s ease 0s infinite;
}

@keyframes pulse {
  50% {
    font-size: 6.25vmin;
  }
}

.rating-stars label[for="rs1"]:hover ~ .rating-counter:before {
  content: "1" !important;
}

.rating-stars label[for="rs2"]:hover ~ .rating-counter:before {
  content: "2" !important;
}

.rating-stars label[for="rs3"]:hover ~ .rating-counter:before {
  content: "3" !important;
}

.rating-stars label[for="rs4"]:hover ~ .rating-counter:before {
  content: "4" !important;
}

.rating-stars label[for="rs5"]:hover ~ .rating-counter:before {
  content: "5" !important;
}

.rating-stars input:checked:hover ~ .rating-counter:before {
  animation: none !important;
  color: #ffab00 !important;
}

/* tables */

.checkout-form h4 {
  font-family: system-ui;
}

.checkout-form table {
  font-family: system-ui;
  font-size: 14px;
  line-height: 26px;
  border: 1px solid #ccc;
  width: 100%;
  font-size: 15px;
  border-radius: 10px;
}

.checkout-form table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.checkout-form table tbody tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}

.checkout-form table th,
.checkout-form table td {
  padding: 0.625em;
  text-align: center;
}

.checkout-form table th {
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .checkout-form table {
    border: 0;
  }

  .checkout-form table caption {
    font-size: 1.3em;
  }

  .checkout-form table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .checkout-form table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .checkout-form table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .checkout-form table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .checkout-form table td:last-child {
    border-bottom: 0;
  }
}

/* pagination */
.pagination ul,
li {
  list-style: none;
  display: inline;
  padding-left: 0px;
}

.pagination li {
  counter-increment: pagination;
}

.pagination li:hover a {
  color: #fdfdfd;
  background-color: var(--yellow);
  border: solid 1px var(--yellow);
}

.pagination li.active a {
  color: #fdfdfd;
  background-color: var(--yellow);
  border: solid 1px var(--yellow);
}

.pagination li:first-child {
  float: left;
}

.pagination li:first-child a:after {
  content: "Previous";
}

.pagination li:nth-child(2) {
  counter-reset: pagination;
}

.pagination li:last-child {
  float: right;
}

.pagination li:last-child a:after {
  content: "Next";
}

.pagination li a {
  border: solid 1px var(--yellow);
  border-radius: 0.2rem;
  color: #7d7d7d;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  padding: 0.5rem 0.9rem;
}

.pagination li a:after {
  content: " " counter(pagination) " ";
}

/* ------------ About ------------- */

.about {
  padding: 50px 0;
}

.about p {
  color: #303030;
  font-size: 16px;
  line-height: 1.7;
  letter-spacing: 1px;
}

/* ------------ Categories ------------ */

.category {
  background: #eaeaea;
  padding: 50px 0;
}

.category .single-category {
  /* clip-path: path('M 10,30 A 20,20 0,0,1 50,30 A 20,20 0,0,1 90,30 Q 90,60 50,90 Q 10,60 10,30 z'); */
  background-color: #e76f434f;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}

.category .single-category:hover {
  transform: scale(1.3);
}

@media (max-width: 768px) {
  .category .choose {
    margin: 30px auto;
    text-align: center;
    width: fit-content;
    background: #fff;
    border-radius: 50%;
    padding: 20px;
  }
}

.category #pointer {
  width: 200px;
  height: 40px;
  position: relative;
  background: #e76f434f;
}

.category #pointer:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #ea531a;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.category #pointer:before {
  content: "";
  position: absolute;
  right: 0px;
  bottom: 0;
  width: 0;
  transform: rotate(180deg);
  height: 0;
  border-left: 20px solid #ea531a;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.side-links {
  position: fixed;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 100;
}

.side-links .tt-social-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side-links .tt-social-icon li {
  margin: 5px 0;
}

.side-links .tt-social-icon a {
  color: #fff;
  background-color: #ea531a;
  font-size: 22px;
}

.side-links .tt-social-icon li:not(:last-child):not(:first-child) {
  margin: 5px 0 !important;
}

/* ================================================== */

.price__wrapper__main {
  height: 100px;
}

.multi-range-slider {
  position: relative;
  width: 80%;
  height: 26px;
  display: block;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  z-index: 10;
  height: 100%;
  margin: 0;
  pointer-events: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-webkit-slider-thumb {
  pointer-events: all;
  -webkit-appearance: none;
  cursor: pointer;
  border: 0 none;
  width: 15px;
  height: 15px;
  background-color: #2698d9;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(38, 152, 217, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  pointer-events: all;
  cursor: pointer;
  border: 0 none;
  width: 15px;
  height: 15px;
  background-color: #2698d9;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(38, 152, 217, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

input[type="range"]::-moz-focus-outer {
  border: 0;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  pointer-events: all;
  border: 0 none;
  cursor: pointer;
  width: 15px;
  height: 15px;
  background-color: #2698d9;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(38, 152, 217, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

input[type="range"].hover::-webkit-slider-thumb {
  box-shadow: 0 0 0 10px rgba(38, 152, 217, 0.1);
}

input[type="range"].hover::-moz-range-thumb {
  box-shadow: 0 0 0 10px rgba(38, 152, 217, 0.1);
}

input[type="range"].hover::-ms-thumb {
  box-shadow: 0 0 0 10px rgba(38, 152, 217, 0.1);
}

input[type="range"].active::-webkit-slider-thumb {
  box-shadow: 0 0 0 20px rgba(38, 152, 217, 0.2);
}

input[type="range"].active::-moz-range-thumb {
  box-shadow: 0 0 0 20px rgba(38, 152, 217, 0.2);
}

input[type="range"].active::-ms-thumb {
  box-shadow: 0 0 0 20px rgba(38, 152, 217, 0.2);
}

input[type="range"]::-webkit-slider-runnable-track {
  background: transparent;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: transparent;
}

input[type="range"]::-moz-range-track {
  background: transparent;
}

input[type="range"]::-ms-track {
  background: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: transparent;
}

input[type="range"]:focus::-ms-fill-lower {
  background: transparent;
}

input[type="range"]::-ms-fill-upper {
  background: transparent;
}

input[type="range"]:focus::-ms-fill-upper {
  background: transparent;
}

.slider {
  position: absolute;
  width: 100%;
  top: 11px;
  z-index: 1;
  height: 6px;
}

.slider > .track {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #e3e3e3;
}

.slider > .range {
  position: absolute;
  z-index: 2;
  left: 25%;
  right: 25%;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: var(--yellow);
}

.price__wrapper {
  display: flex;
  justify-content: space-between;
  /* font-size: 13px; */
  margin-top: 5px;
  width: 80%;
}

.price__wrapper > label {
  color: var(--yellow);
  width: 50%;
  margin: 5px;
  font-size: 15px;
  font-weight: 400;
}

.price__wrapper > label > input {
  width: 80%;
  border: 1px solid #b7b7b7;
  padding: 5px;
  border-radius: 5px;
}
.main_logo img {
  width: 120px;
  height: auto;
}
.navLink {
  font-size: 1.2rem !important;
  text-transform: uppercase;
  font-weight: 400 !important;
  /* border-bottom: 1px solid #ddd; */
}
.slider-revolution {
  height: 80vh !important;
}
.slider-revolution img {
  width: 100% !important;

  min-height: 100%;
  height: auto !important;
  object-fit: cover !important;
}

.slider-revolution .tp-banner-container {
  position: relative;
}
.slider-revolution .tp-banner-container .main-btn,
.tt-promo-box .main-btn {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: var(--main);
  color: white;
}
.tt-promo-box {
  position: relative;
  width: 100%;
  height: 50vh !important;
  overflow: hidden;
}
.tt-promo-box img {
  width: 100%;
 
  height: auto !important;
  object-fit: cover !important;
}
.tt-promo-box img:hover {
  transition: all 1s ease-in-out;
  transform: scale(1.06);
}
@media (max-width: 1024px) {
  .slider-revolution {
    height: auto !important;
    overflow: hidden;
  }
  .slider-revolution img {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    height: auto !important;
    object-fit: cover;
  }

  .tt-promo-box {
    height: auto !important;
    overflow: hidden;
  }
  .tt-promo-box img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
.main-btn {
  font-size: 1.2rem;
  padding: 1.2rem 1.8rem;
  border-radius: 4px !important;
  border-bottom: 2px solid #ddd;
  outline: none;
  background-color: black;
  transition: all 0.4s ease-in-out;
  /* box-shadow: 0px 1px 9px #aaa; */
}
.main-btn:hover {
  border-bottom: 2px solid #1a1a1a;
  background-color: white !important;
  color: black !important;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.arrivals .tt-product {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.arrivals .tt-product .tt-img-roll-over img,
.arrivals .tt-product .tt-img img {
  width: 370px;
  height: 555px !important;
  object-fit: cover !important;
}
@media (max-width: 1250px) and (min-width: 1029px) {
  .arrivals .tt-product .tt-img-roll-over img,
  .arrivals .tt-product .tt-img img {
    width: 300px;
    height: 380px !important;
    object-fit: cover !important;
  }
}

.slide-track {
  animation: scroll 60s linear infinite;
  display: flex;
  font-size: 0.8rem;
  color: #000;
  font-weight: 100;
  width: calc(250px * 10);
}

.featured-section .container {
  padding-bottom: 3rem;
  border-bottom: 1px solid black;
}
.featured-section img {
  width: 100%;
  height: 100%;
}
.featured-section .cate-item {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-bottom: 10px;
  overflow: hidden;
  align-items: center;
  width: 100%;
  text-align:center;
}
.featured-section .cate-item img {
  width: 100%;
  height: 400px;
  object-fit: cover !important;
  transition: all 0.4s ease-in-out;
}
.featured-section .cate-item img:hover {
  transform: scale(1.04);
  cursor: pointer;
}
.featured-section .cate-item .title {
  margin-top: 1rem;
  text-transform: uppercase;
  font-weight: bold !important;
  /* text-align: center !important; */
}
.featured-section .cate-item .main-btn {
  border-bottom: none;
  background-color: var(--main);
  color: white;
  font-weight: 100 !important;
  border: 1px solid white;
  transition: all 0.3s ease-in-out;
}
.featured-section .cate-item .main-btn:hover {
  background-color: white;

  border: 1px solid black;
}

.offer {
  width: 90%;
  height: 90vh !important;
  margin: auto;
}
.offer img {
  width: 100%;
  height: 90vh !important;
  object-fit: cover !important;
}
.gallery .gallery-item {
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  width: 100%;
  height: 350px;
}

.gallery .gallery-item img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
}
.gallery .gallery-item img:hover {
  cursor: pointer;
  transform: scale(1.07);
}
#tt-footer {
  background-color: #242424 !important ;
  color: white !important;
}

#tt-footer .tt-social-icon .footer-icon i {
  font-size: 1.7rem;
  color: white;
}
/* single-product  */
.tt-product-single-img .single-product .main-img {
  width: 100% !important;
  height: 100vh !important;
  
}
.single-product .main-img img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 4px;
   object-fit: contain; 
}
.cust-review {
  color: var(--yellow);
}
.single-product .small-Card {
  display: flex;
  justify-content: start;
  margin: auto 10%;
  align-items: center;
  margin-top: 15px;
  gap: 12px;
}
.single-product .small-Card img {
  width: 104px !important;
  height: 104px !important;
  object-fit: contain;
  border-radius: 4px;
  cursor: pointer;
}
.single-product .small-Card img:active {
  border: 1px solid #17696a;
}
.single-product .sm-card {
  border: 2px solid #8b0000;
}
.single-product .main-text {
  font-family: "Century Gothic";
  font-size: 0.9rem;
  font-weight: 400;
}
.center-about .head,
.middle-par p {
  font-weight: 600;
  color: var(--black);
}
.single-product .main-text h5 {
  color: #333;
  padding-bottom: 2rem;
  font-weight: 700;
}
@media screen and (max-width: 770px) {
  .product-container {
    flex-direction: row;
  }
  .small-Card img {
    width: 80px !important;
  }
  .single-product .small-Card {
    margin-bottom: 1rem;
    flex-direction: row;
  }
  .tt-product-vertical-layout .tt-product-single-img {
    float: right;
    width: 95.7%;
    position: relative;
  }
}
.black-btn {
  background-color: var(--main) !important;
  border-radius: 2px !important;
  color: white !important;
}
.black-text {
  color: black !important;
}
.black-text:hover {
  color: #414141 !important;
}
/* related products */
.related .tt-title-large {
  font-size: 2rem;
}
@media (min-width: 1025px) {
  .related .tt-product:not(.tt-view) .tt-image-box img {
    width: 100%;
    height: 550px !important;
    object-fit: cover;
  }
}
/*about */
.about .description {
  font-size: 1.2rem !important;
}
#tt-pageContent .tt-product:not(.tt-view) {
  position: relative !important;
  overflow: hidden !important;
}

#tt-pageContent .tt-product-inside-hover {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  transition: top 0.2s ease !important;
}

#tt-pageContent .tt-product-inside-hover {
  opacity: 0 !important;
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
  transition: opacity 0.4s ease !important;
}

#tt-pageContent .tt-countdown_box {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  transition: bottom 0.2s ease !important;
}

#tt-pageContent .tt-product:not(.tt-view):hover .tt-product-inside-hover {
  top: -100% !important;
}

.tt-product:not(.tt-view):hover .tt-product-inside-hover {
  opacity: 1 !important;
}

.tt-product:not(.tt-view):hover .tt-countdown_box {
  bottom: 100% !important;
}
/*shop*/
#shop .tt-img img,
#shop .tt-img-roll-over img {
  width: 100%;
  height: 100%;
  min-height: 450px !important;
  object-fit: cover;
}

#shop .col-md-6 .tt-img img,#shop .col-md-6 .tt-img-roll-over img{
  width: 100%;
  height: 100%;
  min-height: 450px !important;
  height: 580px;
  object-fit: cover;
}
#shop .shop-offer {
  width: 100%;
  height: 50vh !important;
  /* object-fit: cover; */
}
#shop .shop-offer img {
  width: 100%;
  max-height: 100% !important;
  /* object-fit: cover; */
}
.tt-product-inside-hover .tt-row-btn:nth-child(2) .tt-btn-addtocart {
  background-color: transparent !important;
}
.tt-product-inside-hover .tt-row-btn:nth-child(2) .tt-btn-addtocart::before {
  color: black !important;
}
.custom-padding {
  padding: 0 2.5rem !important;
}
@media (max-width: 775px) {
  .custom-padding {
    padding: 0 0.5rem !important;
  }
}
.owl-item {
  padding: 0 !important;
}
.tt-product .tt-image-box {
  padding: 0 !important;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9999999;
  top: 0;
  background-color: white;
  overflow-x: hidden;
  padding-top: 0px;
  transition: 0.5s;
  right: 0;
}
.side-icon,
.sidenav a,
.sidenav .nav-link {
  padding: 1rem 8px 1rem 50px;
  text-decoration: none;
  list-style: none;
  font-size: 20px;
  color: #2a2a2a;
  display: block;
  transition: 0.3s;
}
.sidenav .nav-link:hover {
  background-color: var(--main);
}

.side-icon-ltr {
  padding: 1rem 0.5rem !important;
  position: relative;
  top: 20%;
}

.side-icon li a {
  padding-left: 1.7rem;
}
.side-icon-rtl {
  padding: 1rem 0.5rem !important;
  position: relative;
  top: 20%;
}

/* .side-icon-rtl li a {
  padding-right: 1.7rem;
} */
.offcanvas a:focus,
.sidenav a:hover {
  color: #999;
}
.sidenav .closebtn {
  position: absolute;
  color: var(--main) !important;
  cursor: pointer;
  top: 20px;
  left: 10px;
  font-size: 50px;
  /* margin-left: 50px; */
}

#main {
  transition: margin-left 0.5s;
  padding: 20px;
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
.sticky-top {
  position: sticky !important;
  top: 0 !important;
  z-index: 1000;
}
.hide {
  display: none !important;
}
.box {
  height: auto;
  transition: 0.5s linear;
  position: relative;
  display: block;
  overflow: hidden;
  padding: 5px 10px;
  text-align: center;
  margin: 0 5px;
  background: transparent;
  text-transform: uppercase;
  font-weight: 900;
}

.box:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 4px;
  width: 100%;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  box-sizing: border-box;
  transform: translateX(100%);
}

.box:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  border-top: 1px solid transparent;
  border-right: 2px solid transparent;
  box-sizing: border-box;
  transform: translateX(-100%);
}

.box:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.box:hover:before {
  border-color: #262626;
  height: 100%;
  transform: translateX(0);
  transition: 0.2s transform linear, 0.2s height linear 0.2s;
}

.box:hover:after {
  border-color: #262626;
  height: 100%;
  transform: translateX(0);
  transition: 0.3s transform linear, 0.3s height linear 0.5s;
}

.language {
  color: black;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
}
.search-show {
  top: 159px !important;
  visibility: visible !important;
}
.search-container {
  display: block !important;
}
/* side shopping cart */
.shopping-cart {
  width: 750px;
  height: 85%;
  margin: 80px auto;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.cartWidth {
  width: 750px;
}
.cartBody {
  display: flex;
  justify-content: center;
  align-items: center;
}
.shopping-cart .title {
  height: 60px;
  
  /* border-bottom: 2px solid #2a2a2a; */
  padding: 40px 30px;
  color: #2e6977;
  font-size: 1.3rem;
  font-weight: 500;
}
.shopping-cart .bottomTitle{

width: 50%;
height: 2px;
margin: 10px auto 0;
background-color: #000;
}
.shopping-cart .total-cart{
  color: black;
  margin-top: 1rem;
  font-weight: 300;
  font-size: 1.6rem;
  border: 1px solid black;
  /* border-top: none; */
  background-color: #3a3a3a;
  color: white;
  border-radius: 2px;
}
.shopping-cart .total-cart .bold{
font-weight: 200;
}
.shopping-cart .item {
  padding: 20px 30px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shopping-cart .item:not(last-child) {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.shopping-cart .buttons {
  position: relative;
  padding-top: 30px;
  margin-right: 30px;
}
.shopping-cart .delete-btn {
  cursor: pointer;
}
.shopping-cart .delete-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shopping-cart .delete-btn i {
  font-size: 1.3rem;
}
.shopping-cart .delete-btn i:hover {
  color: darkred;
}
.shopping-cart .is-active {
  animation-name: animate;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  animation-timing-function: steps(28);
  animation-fill-mode: forwards;
}

@keyframes animate {
  0% {
    background-position: left;
  }
  50% {
    background-position: right;
  }
  100% {
    background-position: right;
  }
}
.shopping-cart .item .image {
  width: 150px;
  height: 100%;
  object-fit: cover;
  margin-right: 50px;
}

.shopping-cart .description {
  padding-top: 10px;
  margin-right: 60px;
  width: 115px;
}

.shopping-cart .description span {
  display: block;
  font-size: 1.3rem;
  color: #212121 !important;
  font-weight: 400;
}

.shopping-cart .description span:first-child {
  margin-bottom: 5px;
}
.shopping-cart .description span:last-child {
  font-weight: 300;
  margin-top: 8px;
  color: #86939e;
}
.shopping-cart .quantity {
  padding-top: 20px;
  margin-right: 60px;
}
.shopping-cart .quantity input {
  -webkit-appearance: none;
  border: none;
  text-align: center;
  width: 32px;
  font-size: 16px;
  color: #43484d;
  font-weight: 300;
}
.shopping-cart .buttons {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
}
.shopping-cart button[class*="btn"] {
  width: 30px;
  height: 30px;
  background-color: #e1e8ee;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}
.shopping-cart .minus-btn i {
  margin-bottom: 3px;
}
.shopping-cart .plus-btn i {
  margin-top: 2px;
}

.shopping-cart button:focus,
.shopping-cart input:focus {
  outline: 0;
}
.shopping-cart .total-price {
  width: 83px;
  padding-top: 27px;
  text-align: center;
  font-size: 16px;
  color: #43484d;
  font-weight: 300;
}
.shopping-cart .total-price .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.shopping-cart .image {
  width: 150px;
  height: 120px;
  object-fit: cover;
  width: 50%;
}
.shopping-cart .item .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.shopping-cart .actions {
  margin-top: 10%;
  padding: 20px 30px;
  display: flex;
  gap: 20px !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.shopping-cart .actions button {
  border: none;
  width: 70%;
  outline: none;
  background-color: var(--main);
  border: 1px solid white;
  transition: all 0.4s ease-in-out;
}
.shopping-cart .actions button:nth-child(1) {
  width: 67%;
}
.shopping-cart .actions button a {
  color: #eee !important;
}
.shopping-cart .actions button:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}
.shopping-cart .actions button:hover a {
  color: black !important;
}
.shopping-cart .total-price {
  /* padding-top: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@media (max-width: 775px) {
  .shopping-cart .shopping-cart {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .shopping-cart .item {
    padding: 20px 10px;
  }
  .shopping-cart .item {
    height: auto;
    justify-content: center;
  }
  .shopping-cart .image {
    height: 100%;
    object-fit: cover;
    margin-right: 5px !important;
  }
  .shopping-cart .total-price .buttons {
    margin-bottom: 0px;
  }
  .shopping-cart .image,
  .shopping-cart .quantity,
  .shopping-cart .description {
    width: 100%;
    text-align: center;
  }
  .shopping-cart .buttons {
    margin-right: 20px;
  }
  .cartWidth {
    width: 390px;
  }
  .shopping-cart .total-price {
    width: 100% !important;
    text-align: center;
  }
  .shopping-cart {
    width: 370px;
  }
  .shopping-cart .item {
    display: flex;
    height: auto;
  }
  .shopping-cart .cartBody div {
    text-align: center;
    margin-right: 0 !important;
  }
  .shopping-cart .cartBody {
    width: 70%;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
  }

  .shopping-cart .item .image {
    width: 30% !important;
    display: flex;
    align-items: center;
  }
  .shopping-cart .cartBody div,
  .shopping-cart .cartBody span {
    font-size: 0.7rem !important;
    margin-top: 0px;
    padding-top: 1px;
  }

  .shopping-cart .item .image img {
    width: 100px;
    height: 120px;
  }
  .shopping-cart .actions button {
    width: 95%;
  }
  .shopping-cart .actions button a {
    padding: 0.5rem !important;
  }
  .shopping-cart .actions button:nth-child(1) {
    width: 93%;
  }

  .shopping-cart .delete-btn {
    padding-top: 7px !important;
  }
  .shopping-cart .quantity {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .shopping-cart .quantity button {
    width: 20px;
    height: 20px;
  }
}
/* contact */
.contact .banner {
  width: 100%;
  max-height: 60vh;
}
.contact .banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.form-group .main-btn {
  font-size: 1rem !important;
}
.wishbanner img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.wishtable {
  border-top: 2px solid #4a4a4a;
  border-bottom: 8px solid #4a4a4a;
}
.wishtable .tt-col-btn {
  display: flex;
  align-items: center;
}
.tt-btn-quickview {
  border: none !important;
  outline: none !important;
}
button {
  outline: none;
  border: none;
}
#main-home {
  min-height: 90vh !important;
}
.branch .branch-item {
  padding: 20px;
  /* background-color: #f9f9f9; */
}
.branch .branch-item p {
  color: black;
  line-height: 10px;
}
.profile .tt-table-shop-02 {
  width: 70%;
}
.profile-deta .tt-title {
  /* padding:1rem !important; */
  width: 100%;
  /* box-shadow: 64px 64px 104px 5px #ddd; */
}

.profile-deta .tt-table-shop-02 {
  padding: 2rem;
  box-shadow: 0px 150px 150px 2px #eee;
  border-bottom: 4px solid #111;
}
.profile .profile-user {
  width: 250px;
  border-radius: 15px;
  height: 320px;
  object-fit: cover;
  /* box-shadow: 1px 1px 1px #000; */
}
.profile .profile-user img {
  width: 100%;
  border-radius: 7px;
  /* box-shadow: 64px 64px 94px 5px #ddd; */
  height: 320px;
  object-fit: cover;
}
.tt-table-shop-02 tbody tr,
.tt-table-shop-01 tbody tr {
  box-shadow: 0px 1px 1px 0px #00000011;
  text-align: justify;
}
.tt-table-shop-02 thead tr,
.tt-table-shop-01 thead tr {
  font-weight: bold;
  text-align: justify;
}
@media (max-width: 775px) {
  .arrivals .tt-product .tt-img-roll-over img,
  .arrivals .tt-product .tt-img img {
    /* width: 370px; */
    height: 300px !important;
    object-fit: cover !important;
  }
  .featured-section .cate-item img {
    height: 240px;
    object-fit: cover !important;
    transition: all 0.4s ease-in-out;
  }
  .offer {
    width: 100%;
    height: 30vh !important;
  }
  .offer img {
    width: 100%;
    height: 30vh !important;
  }
  .gallery .gallery-item {
    height: 150px;
  }
  .gallery .gallery-item img {
    height: 150px;
  }
  .wishbanner img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  #shop .tt-img img,
  #shop .tt-img-roll-over img {
    width: 100%;
    height: 100%;
    height: 250px !important;
    min-height: 250px !important;
    object-fit: cover;
  }
  .profile .tt-table-shop-02 {
    width: 100%;
  }
  .search-results {
    width: 140% !important;
  }
}

/* Composant */
.tabs-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 10px;
}
.tabs-wrapper::before,
.tabs-wrapper::after {
  position: absolute;
  bottom: 0;
  content: "";
  height: 2px;
  background: #eee;
  opacity: 0.6;
  border-radius: 4px;
}
.tabs-wrapper::after {
  width: 100%;
  opacity: 1;
  transform: translateX(0);
  transition: width 0.2s ease, transform 0.2s ease;
}
.tabs-wrapper .tab {
  padding: 0.5rem 2rem;
  font-family: Arial;
  font-size: 18px;
  color: #aaa;
  /* background-color: #eee; */
  cursor: pointer;
  user-select: none;
}
.tabs-wrapper .active {
  color: white;
  background: #2a2a2a;
  outline: none;
  border-radius: 2px 2px 0 0;
}
.valid-error {
  color: red;
}
.loader {
  position: absolute;
  width: 70%;
  height: 60vh;
  margin: auto;
  top: 75%;
  left: 0;
  right: 0;
  transform: translateY(-75%);
  /* border: 2px solid #fff; */
  padding: 15px;
  background-color: #f7f7f7;
  overflow: hidden;
}

.loader:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    110deg,
    rgba(227, 227, 227, 0) 0%,
    rgba(227, 227, 227, 0) 40%,
    rgba(227, 227, 227, 0.5) 50%,
    rgba(227, 227, 227, 0) 60%,
    rgba(227, 227, 227, 0) 100%
  );
  animation: gradient-animation_2 1.2s linear infinite;
}

.loader .wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.loader .wrapper > div {
  background-color: #cacaca;
}

/* .loader .circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
} */

.loader .button {
  display: inline-block;
  height: 32px;
  width: 75px;
}

.loader .line-1 {
  position: absolute;
  top: 10%;
  left: 3%;
  height: 5%;
  width: 70%;
}

.loader .line-2 {
  position: absolute;
  top: 25%;
  left: 8%;
  height: 5%;
  width: 85%;
}

.loader .line-3 {
  position: absolute;
  top: 40%;
  left: 13%;
  height: 5%;
  width: 82%;
}

.loader .line-4 {
  position: absolute;
  top: 55%;
  left: 18%;
  height: 5%;
  width: 80%;
}
.loader .line-5 {
  position: absolute;
  top: 70%;
  left: 23%;
  height: 5%;
  width: 75%;
}

@keyframes gradient-animation_2 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
#policy-title {
  margin-top: 1rem;
  font-size: 2rem !important;
  color: var(--yellow) !important;
  font-weight: bold !important;
}
#policy-desc {
  word-wrap: break-word !important;
}
.active-img {
  border-radius: 10px;
  border: 3px solid var(--yellow);
}
.tt-title {
  text-transform: capitalize;
}
.search-results {
  z-index: 999999999999;
  display: inline-flex;
  width: 100% !important;
  position: fixed !important;
  height: auto !important;
  padding: 2rem auto;
  overflow-y: scroll ;
  overflow-x: hidden ;
   max-height: 85vh !important; 
  top: 80% !important;
  left: 0% !important;
}
.search-results .search-list {
  flex-wrap: wrap !important;
}
.search-results .search-list li {
  margin-top: 10px;
  width: 100%;
  height: 250px;
}
.search-results .search-list li a {
  width: 100% !important;
  height: 150px !important;
}
.search-results li .thumbnail img {
  width: 100% !important;
  height: 150px !important;
  object-fit: contain !important;
}
.active-fav {
  background-color: var(--main) !important;
  color: white !important;
}
.orders .tt-wrapper .tt-table-shop-03 thead tr th {
  text-align: start;
}
.orders {
  border-bottom: 4px solid black;
}
Toaster {
  z-index: 99999999999999;
}
header .cart-img {
  width: 25px;
  height: 25px;
}
.main-side {
  position: relative;
  /* display: flex; */
  height: 100%;
  flex-flow: column;
  overflow-x: hidden;
  justify-content: space-between;
}
.main-side .sub-btn{
  display: block;
}
.main-side .sideLogo {
  position: absolute;
  top: 10%;
  left: 20%;
}
@media (min-width: 1350px) {
  .tt-product:not(.tt-view) .tt-image-box .fa-heart {
    right: 17px;
  }
  .tt-product:not(.tt-view) .tt-image-box .fa-cart-plus {
    right: 17px;
  }

  .tt-product:not(.tt-view) .tt-image-box .tt-label-location {
    left: 10px !important;
  }
}
.filter-price {
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
}

.price-title {
  position: relative;
  color: #fff;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 400;
  background: #d58e32;
  padding: 10px;
}

.price-container {
  display: flex;
  border: 1px solid #ccc;
  padding: 5px;

  width: 100%;
}

.price-field {
  position: relative;
  width: 100%;
  height: 55px;
  box-sizing: border-box;
  padding-top: 15px;
  padding-left: 0px;
}

.price-field input[type="range"] {
  position: absolute;
}

.price-field input[type="range"] {
  width: 100%;
  height: 18px;
  outline: 0;
  box-sizing: border-box;
  border-radius: 5px;
  pointer-events: none;
  -webkit-appearance: none;
}

.price-field input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.price-field input[type="range"]:active,
.price-field input[type="range"]:focus {
  outline: 0;
}

.price-field input[type="range"]::-ms-track {
  width: 188px;
  height: 2px;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  border-radius: 5px;
  pointer-events: none;
  background: #d58e32;

  border-color: transparent;
  color: red;
  border-radius: 5px;
}

.price-field input[type="range"]::-webkit-slider-thumb {
  position: relative;
  -webkit-appearance: none;
  margin: 0;
  border: 0;
  outline: 0;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin-top: -10px;
  background-color: #d58e32;

  cursor: pointer;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
}

.price-field input[type="range"]::-moz-range-thumb {
  position: relative;
  appearance: none;
  margin: 0;
  border: 0;
  outline: 0;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-top: -5px;
  background-color: #fff;
  cursor: pointer;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
}

.price-field input[type="range"]::-ms-thumb {
  position: relative;
  appearance: none;
  margin: 0;
  border: 0;
  outline: 0;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-top: -5px;
  background-color: #242424;
  cursor: pointer;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
}

.price-field input[type="range"]::-webkit-slider-runnable-track {
  width: 188px;
  height: 2px;
  cursor: pointer;
  background: #555;
  border-radius: 5px;
}

.price-field input[type="range"]::-moz-range-track {
  width: 188px;
  height: 2px;
  cursor: pointer;
  background: #242424;
  border-radius: 5px;
}

.price-field input[type="range"]::-ms-track {
  width: 188px;
  height: 2px;
  cursor: pointer;
  background: #242424;
  border-radius: 5px;
}

.price-wrap {
  display: flex;
  color: #242424;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 400;
  margin-bottom: 0px;
}

.price-wrap-1,
.price-wrap-2 {
  display: flex;
  margin-left: 0px;
}

.price-title {
  margin-right: 5px;
}

.price-wrap_line {
  margin: 6px 0px 5px 5px;
}

.price-wrap #one,
.price-wrap #two {
  width: 30px;
  text-align: right;
  margin: 0;
  padding: 0;
  margin-right: 2px;
  background: 0;
  border: 0;
  outline: 0;
  color: #242424;
  font-family: "Karla", "Arial", sans-serif;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 400;
}

.price-wrap label {
  text-align: right;
  margin-top: 6px;
  padding-left: 5px;
}

.price-field input[type="range"]:hover::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.5px #242424;
  transition-duration: 0.3s;
}

.price-field input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.5px #242424;
  transition-duration: 0.3s;
}
.tt-options-swatch .opt-color {
  font-size: 0.8rem;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid var(--yellow);
  color: black;
  cursor: pointer;
}
.tt-options-swatch .opt-color:hover {
  background-color: var(--main);
  color: white;
}
.active-search {
  background-color: var(--main) !important;
  color: white !important;
}
.slick-slider .slick-dots {
  bottom: -30px !important;
}
.slick-slider .slick-dots li {
  margin: 3px 3px !important;
  color: var(--yellow) !important;
  padding: 5px 5px !important;
  cursor: pointer !important;
}
.slick-slider .slick-dots li button {
  width: 17px;
  height: 17px;
  background: #ddd;
  border: none;
  border-radius: 50%;
  font-size: 0;
  line-height: 1;
  cursor: pointer;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: white;
  -webkit-font-smoothing: antialiased;
}
.tt-input-counter.style-01 {
  position: relative;
  max-width: 132px;
  min-width: 132px;
}
.tt-input-counter.style-01 input {
  background: #f7f8fa;
  display: block;
  width: 100%;
  height: 50px;
  font-size: 14px;
  line-height: 1em;
  text-align: center;
  border: none;
  outline: none;
  font-family: "Hind", sans-serif;
  font-weight: 500;
  color: #191919;
  border-radius: 6px;
}
.tt-input-counter.style-01 i {
  display: inline-block;
  font-size: 27px;
  line-height: 1em;
  cursor: pointer;
  position: absolute;
  width: 49px;
  height: 100%;
  text-align: center;
  top: 0;
  color: #777777;
}
.tt-input-counter.style-01 i:hover {
  color: #191919;
}
.tt-input-counter.style-01 i.minus-btn {
  left: 0px;
}
.tt-input-counter.style-01 i.plus-btn {
  right: 0px;
}
.tt-input-counter.style-01 .minus-btn:before,
.tt-input-counter.style-01 .plus-btn:before {
  display: block;
  font-size: 12px;
  line-height: 50px;
}

@media (max-width: 767px) {
  .container-mobile-airSticky .tt-product-single-info {
    padding-top: 0;
  }
  .container-mobile-airSticky
    .tt-row-custom-01
    .col-item
    .tt-input-counter.style-01 {
    max-width: 100%;
  }
}
.main-side .item {
  position: relative;
  cursor: pointer;
}
.main-side .main-ul{
  width: 100%;
  overflow: hidden;
  position: inherit;
  top:30%;
}
.main-side .sideICons{
  top:50% !important;
}
.main-side .item i {
  margin-right: 15px;
}

.main-side .item .sub-menu {
  background: #f8f8f8;
  overflow: hidden;
  transition: max-height 0.5s linear;
}

.dropShow {
  max-height: 500px; 
}

.dropHide {
  max-height: 0;
}


.main-side .item .sub-menu a {
  padding-left: 80px;
}
.rotate-bottom {
  transform: rotate(90deg);
  transition: all 0.5s linear;
}
.rotate {
  transform: rotate(270deg);
  transition: all 0.5s linear;
}
.bg-done {
  background-color: #00695c !important;
}
.bg-reject {
  background-color: #b71c1c !important;
}
.swiper-button-prev,
.swiper-button-next {
  background-color: var(--main) !important;
  padding: 19px !important;
  min-height: 66 px !important;
  color: white !important;
}
.tabEdit {
  position: relative;
  top: 15px;
  display: flex;
  align-items: end;
  justify-content: space-between;
}
.tabEdit i {
  cursor: pointer;
  font-size: 1.1rem;
}

.editSave {
  padding: 0.9rem;
  background-color: var(--main);
  color: white;
  font-size: 0.8rem;
}
.footer-image{
  width:100px;
  height: 50px;
}
@media (max-width: 767px) {
  .tabEdit {
    top: 0;
  }
  .tabs-wrapper {
    flex-direction: column;
  }
  option {
    white-space: wrap !important;
    width: 70% !important;
  }
  .footer-image{
  width:50px;
  height: 50px;
}
.main-side .main-ul{
  top:20%;
}
}
@media (min-width: 767px) {

  .footer-image{
  width:50px;
  height: 50px;
}
 
}
@media screen and (min-width: 992px) and (max-width: 1660px) {
  .tt-product:not(.tt-view) .tt-image-box .tt-label-location,
  .tt-product:not(.tt-view) [class^="tt-label-"] {
    margin-left: 35px;
  }
  .tt-product:not(.tt-view) .tt-image-box .fa-cart-plus {
    right: 65px;
  }
  .tt-product:not(.tt-view) .tt-image-box .fa-heart {
    right: 65px;
  }
}
.lostpass {
  background-color: white;
  color: var(--main);
  font-size: 1rem;
  border-radius: 3px;
  cursor: pointer;
}
.pagination li a:after {
  display: none !important ;
}
.btn:hover,
button:hover {
  cursor: pointer;
}

.filter-grid{
  width: 30px;
  height: 30px;
  cursor: pointer;
}
@media (max-width: 1024px){
.main_side {
  left: 0 !important;
  margin-top: 0 !important;
  transition: width 0.3s ease-in-out !important; 
}
.closebtnfilter{
  position: relative;
  color: white !important;
  cursor: pointer;
  margin-bottom: 3rem;
  top: 20px;
  text-align: right;
  background-color: var(--main) !important;
  left: 90%;
  font-size: 30px;

}
.main-side .sideLogo {
  position: absolute;
  top: 5%;
  left: 20%;
}
}
.cartBtn {
  position: absolute;
  padding-top: 1.8rem !important;
  padding-bottom: 1.8rem !important;
  width: 100%;
  background-color: var(--main);
  color: white;
  border-bottom: 2px solid black;
  font-size: 1.3rem;
  font-weight: 500;
  top: 94%;
  left: 0;
  visibility: hidden;
  opacity: 0;
  border-radius: 0 !important;
  z-index: 999999999;
  overflow: hidden;
  transition: opacity 0.2s linear, top 0.2s linear ,visibility .2s linear;
}

.cartBtn:hover {
  background-color: white;
  color: var(--main);
}
.tt-product .tt-image-box{
  /* overflow: hidden; */
}
.tt-product .tt-image-box:hover .cartBtn {
  visibility: visible;
  opacity: 1;
  top: 89%;
}

.cartBtn .fa-cart-arrow-down {
  font-size: 1rem;
}

@media (max-width:775px) {
  .tt-product .tt-image-box:hover .cartBtn{
    opacity: 0;
  }
  .tt-product-single-img .single-product .main-img {
  width: 100% !important;
  height: auto !important;
}

.single-product .small-Card {

  margin: auto ;
  
}
}
.close_slide {
    background: #ffffff;
    border: none;
    width: 36px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 10px;
    padding: 0;
    cursor: pointer;
}
.close_slide .fa-trash{
  font-size: 1.3rem;
}
.close_slide.gray{
	background:#f4f5f7 !important;
}